import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { Button, Divider, Card, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate} from "react-router-dom";
import { LoginAPI } from "services/LoginAPI";
import { toast } from "react-toastify";
import { userAction } from "store/user-slice";
import { obfuscatedNumber } from "util/UtilityFunctions";
import { obfuscatedEmail } from "util/UtilityFunctions";
import { useMediaQuery } from "react-responsive";
import logo from "assets/images/logos/BLIQ/longtaglines-01.webp";

const SecondFactorAuth = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.user.isLoggedIn);
    const role = useSelector((state) => state.user.role);
    const location  = useLocation();
    const loginData = location.state;
    const intervalRef = useRef();
    const otpFieldRef = useRef();

    const [time, setTime] = useState({
        start : 60,
        running : false
    });

    const [otpOptions, setOtpOption] = useState({...loginData,options : 'email'});

    const onGenerateClick = useCallback(() => {
        intervalRef.current = setInterval(() => {
            if(time.start > 0){
                setTime(prev => {
                    return{
                        ...prev,
                        start :  prev.start - 1,
                        running :  true
                    }
                });
            }
    },1000);
    // call the generate endpoint with payload
    LoginAPI.sendOTP({payload : otpOptions}).then(res => {
        setOtpOption(res.data);
    }).catch(e => console.log(e));

   },[otpOptions])

   const onOtpSubmit = () => {
    if(!(otpOptions.otp)){
        return;
    }
    LoginAPI.ValidateOTP({payload : otpOptions}).then(res => {
        const data = res.data;
        const user = {
            id: data.userId,
            name : data.userName,
            token : data.token,
            isLoggedIn : data.valid,
            role : data.roles[0],
            email : data.userEmail,
            credit: data.totalCredit,
            company: data?.company,
            acceptedTermsAndConditions : data.acceptedTermsAndConditions
          }
          dispatch(userAction.login(user));
          if(loginData.rememberMe) {
            const blipUserData = JSON.stringify(user);
            localStorage.setItem("blip_user_data",blipUserData);
          }
    }).catch(e => {
        toast.error(e.response.data.message);
    });
   }

   const handleSendOtpOptions = (e) => {
     const val = e.target.value;
     console.log(val)
     setOtpOption((prev) => {
       return {
         ...prev,
         options: val,
       };
     });
   };

   useEffect(() => {
    let otpFocus = undefined;
    if(otpFieldRef){
        otpFocus = setTimeout(() => {
            otpFieldRef?.current?.focus();
        },[500]);
    }
    return () => {
        clearTimeout(otpFocus);
    }
   },[otpFieldRef]);

   useEffect(() => {
    if(time.start <= 0){
        clearInterval(intervalRef.current);
        setTime({
            start : 60,
            running : false
        })
    }
   },[time])
   
   useEffect(() => {
    if(isAuthenticated){
      if(role === 'USER'){
        navigate('/conversations');
      }
      else if( isAuthenticated && role === 'ADMIN' || role === 'SUPER_ADMIN'){
        // Terms and conditions acceptance is required......
        navigate('/admin/dashboard');
      }
      else{
        navigate('/404');
      }
    }
  }, [isAuthenticated,role])

    return(
        <MDBox width="100%" height={"100vh"} display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="center" backgroundColor={"white!important"}>
            <MDBox width="50%" display="flex" justifyContent="center" alignItems="center">
            <MDBox maxHeight="400px" maxWidth="400px">
                <img height={"100%"} width={"100%"} src={logo}/>
            </MDBox>
            </MDBox>
            {!isMobile && <MDBox height={"100%"} sx={{ marginRight: "12px" }}>
            <Divider
                orientation="vertical"
                component="div"
                sx={{
                "&.MuiDivider-root": {
                    backgroundColor: "#e9d5e9",
                    height: "100%",
                },
                }}
            />
            </MDBox>}
            <MDBox m={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <MDTypography textAlign="center" variant="h4" fontWeight="medium" color="dark" mb={3}>
                    2FA login
                </MDTypography>
                <MDBox  display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <MDBox padding={"12px"} display="flex" flexDirection="column">
                        <Typography fontSize={"16px"}>{"Where do you want to receive your One Time Passcode?"}</Typography>
                        <FormGroup>
                            <RadioGroup onChange={handleSendOtpOptions} value={otpOptions?.options}>
                                <FormControlLabel
                                    key={"email"}
                                    value={"email"}
                                    control={
                                        <Radio />
                                    }
                                    label={`Email (${obfuscatedEmail(otpOptions.emailAddress)})`}
                                />
                                {otpOptions.phoneNumber && <><FormControlLabel
                                    key={"number"}
                                    value={"number"}   
                                    disabled={!(otpOptions?.phoneNumber)}   
                                    control={
                                        <Radio />
                                    }
                                    label={`SMS ${otpOptions?.phoneNumber ? `(${obfuscatedNumber(otpOptions?.phoneNumber)})`: ''}`}
                                />
                                <FormControlLabel
                                    key={"both"}
                                    value={"both"}  
                                    disabled={!(otpOptions?.phoneNumber)}   
                                    control={
                                        <Radio />
                                    }
                                    label={"Both (We'll text and email you the code)"}
                                /></>}                                                                                               

                            </RadioGroup>
                        </FormGroup>     
                        <Button
                            disabled={!(otpOptions?.options) || time.running}
                            variant="contained"
                            sx={{
                                color:"white!important",
                                marginTop:'8px'
                            }}
                            onClick={onGenerateClick}
                        
                        >
                            {"Send code now"}
                        </Button>    
                        {time.running && <Typography fontWeight={"600"} sx={{textAlign:'center'}} fontSize={"14px"}>{"Code sent. Resend in "}{time.start}s</Typography>}                   

                    </MDBox>
                    <MDBox width="100%" display="flex" flexDirection="row" padding="12px" alignItems="center" justifyContent="center">
                        <MDInput 
                            onKeyDown={(e) => {
                                if(e.keyCode == 13 && e.shiftKey == false){
                                    onOtpSubmit();
                                }
                            }}
                            type={'text'} 
                            label="Enter OTP" 
                            ref={otpFieldRef}
                            variant="standard"
                            onChange={(e) => {
                                setOtpOption(prev =>{
                                    return{
                                        ...prev,
                                        otp: e.target.value
                                    }
                                })
                            }}
                        />    
                        <MDBox display="flex" flexDirection="column" ml={2} width="50%">
                            <Button onClick={onOtpSubmit}  disabled={!(otpOptions?.otp)} variant="contained" sx={{color:'white!important',height:'32px',minWidth:'fit-content'}}>
                                {"Submit"}
                            </Button> 
                        </MDBox>
                    </MDBox>                        
                </MDBox>
            </MDBox>
      </MDBox>        
    )
}
export default SecondFactorAuth;     