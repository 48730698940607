import { useEffect, useState, useRef, useCallback } from "react";
import AppModal from "ui/AppModal";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useSelector } from "react-redux";
import { createFundRaiser } from "api/campaign";
import { toast } from "react-toastify";

const AddIndividualFundRaiser = ({onChange, campaign, onFundraiserCreated}) => {
  const currUser = useSelector((state) => state.user);
  /// Email and phone is optional so we will not put any validation for that.
  const [fundRaiserFormValidation, setFundRaiserFormValidation] = useState({
    nameValidation : '',
    goalAmtValidation : '',
    numberValidation : ''
  })
  const nameRef = useRef();
  const goalmAountRef = useRef();
  const emailRef = useRef();
  const numberRef = useRef();

  const {
    sendRequest : createFundraiserFn,
    data : createdData,
    status : createdStatus,
    error : createdError,
  } = useHttp(createFundRaiser, false);

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    const fullName = nameRef.current.value;
    const goalAmount = goalmAountRef.current.value;
    const email = emailRef.current.value;
    const number = numberRef.current.value;
    // const password = passwordRef.current.value;
    if(fullName === "") {
      setFundRaiserFormValidation(prev => {
        return{
            ...prev,
            nameValidation : 'Title can not be empty.'
        }
      });
      return;
    }
    if (!goalAmount || !(/^[0-9]*$/).test(goalAmount) || goalAmount > 10000000 || goalAmount <= 0) {
      setFundRaiserFormValidation(prev => {
        return{
          ...prev,
          goalAmtValidation : 'Set a valid goal amount.'
        }
      });
      return;
    }    
    if(!number || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(number)){
      setFundRaiserFormValidation(prev => {
        return{
            ...prev,
            numberValidation : 'Please enter a valid number.'
        }
      });
      return;
    }
    /// Seems fine call the API to save the Fundraiser....
    const fundRaiser = {
      payload : {
        fundraiser_email : email,
        fundraiser_number : number,
        goal_amount : goalAmount,
        company_id : currUser.company.companyId,
        campaign : campaign,
        fundraiser_name : fullName,
      },
      token : currUser.token
    }
    createFundraiserFn(fundRaiser);
    
  },[currUser]);

  useEffect(() => {
      if(createdStatus === 'completed'){
          if(createdData){
            onFundraiserCreated(createdData)
          }
          else{
            toast.error("Not able to create, Please try again..")
          }
      }
  },[createdData, createdStatus, createdError])
  return (
    <>
      <AppModal
        // customStyle={{width:'60%'}}
        onModalClose={onChange}
        rootStyle = {
          {
            "&.MuiModal-root":{
              width:'40%',
              left: '60%'
            }
          }
        }
        customStyle={{ 
            width: "100%",
            height: "100%",
        }}
        heading={"Create fundraiser"}
      >
        <>
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
              <MDBox component="form" role="form">
                <MDBox mb={1}>
                  <MDInput
                    type="text"
                    label="Name"
                    variant="standard"
                    fullWidth
                    inputRef={nameRef}
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.nameValidation}
                </MDTypography>
                <MDBox mb={1}>
                  <MDInput
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    inputRef={emailRef}
                  />
                </MDBox>
                <MDBox mb={1}>
                  <MDInput
                    type="text"
                    label="Contact Number"
                    variant="standard"
                    fullWidth
                    inputRef={numberRef}
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.numberValidation}
                </MDTypography>                
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Goal amount"
                    variant="standard"
                    fullWidth
                    inputRef={goalmAountRef}
                  />
                </MDBox>
                <MDTypography
                  variant="overline"
                  fontWeight="medium"
                  color="error"
                >
                  {fundRaiserFormValidation.goalAmtValidation}
                </MDTypography>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </>
      </AppModal>
    </>
  );
};
export default AddIndividualFundRaiser;
