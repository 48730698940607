/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { Avatar, Typography } from "@mui/material";

const User = ({ image, name, email, children }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <Avatar alt={name} src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDBox ml={2} lineHeight={1} sx={{display:'flex',margin:0}}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>{children}
      </MDBox>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>

  </MDBox>
);
export const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);
export const columns = [
  { Header: "user", accessor: "user", width: "30%", align: "left" },
  { Header: "role", accessor: "role", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "Bulk SMS Credits", accessor: "total_credit", align: "center" },
  { Header: "created at", accessor: "created_at", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
export const lockedColumns = [
  { Header: "sno", accessor: "sno", width: "30%", align: "left" },
  { Header: "email", accessor: "email", width: "30%" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "Action", accessor: "action", align: "center" },

];
export const phoneColumns = [
  { Header: "phone", accessor: "phone", width: "25%" },
  { Header: "user", accessor: "apiuser", width: "20%" },
  { Header: "company", accessor: "company", width: "20%" },
  { Header: "type", accessor: "type", width: "20%" },
  { Header: "api key", accessor: "apikey", maxSize: "150px" },
  { Header: "Auth", accessor: "auth", width: "30%" },
  { Header: "Assigned users", accessor: "assigned", align: "center" },
  { Header: "Actions", accessor: "action", width: "30%", height: '100px', align: "center" },
]
export const adminPhoneColumns = [
  { Header: "phone", accessor: "phone", width: "25%" },
  { Header: "type", accessor: "type", width: "20%" },
  { Header: "Assigned users", accessor: "assigned", align: "center" },
  { Header: "Actions", accessor: "action", width: "30%", align: "center" },
]
export const companyColumns = [
  { Header: "SNo.", accessor: "sno" },
  { Header: "Logo", accessor: "logo" },
  { Header: "company", accessor: "company", width: "25%" },
  { Header: "Total Credits", accessor: "total_credit", align: "center" },
  { Header: "Actions", accessor: "action", width: "30%", align: "center" },
]
export const myShortcutsColumns = [
  { Header: "SNo.", accessor: "sno" },
  { Header: "title", accessor: "key", align: "center" },
  { Header: "text", accessor: "value", align: "center" },
  { Header: "type", accessor: "type", align: "center" },
  { Header: "created by", accessor: "created_by", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
export const messageColumns = [
  { Header: <Typography variant="h6" fontSize={"12px"}>Title</Typography>, accessor: "title", align: "center" },
  { Header: <Typography variant="h6" fontSize={"12px"}>Percentage</Typography>, accessor: "percentage", align: "center" },
  { Header: <Typography variant="h6" fontSize={"12px"}>Message</Typography>, accessor: "message", align: "center" },
  { Header: <Typography variant="h6" fontSize={"12px"}>Action</Typography>, accessor: "action", align: "center" }
];
export const otherShorcutscolumns = [
  { Header: "SNo.", accessor: "sno" },
  { Header: "title", accessor: "key", align: "center" },
  { Header: "text", accessor: "value", align: "center" },
  { Header: "created by", accessor: "created_by", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
export const carrierColums = [
  { Header: "SNo.", accessor: "sno" },
  { Header: "Name", accessor: "name", align: "center" },
  { Header: "created at", accessor: "created_at", align: "center" },
  { Header: "action", accessor: "action", align: "center" }
]
export default User;