import { Key } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import CreateApiKey from "./CreateApiKey";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import { BapiAPI } from "services/Bapi/BapiService";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Swal from "sweetalert2";
/**
 * {
 *  username : 'waymore',
 *  email : '', email to use
 *  Api key : 'somekey',
 *  phone number : ''
 *  webhook url : '' // in case we have to call the endpoint if inbound is received
 *  last used : 'somewhere',
 * }
 * 
 */
const API_ACTION = {
    create : 'create',
    edit : 'edit',
    none : 'none'
}
const columns = [
    { id:1 , key : 'username', label : 'User Name'},
    { id:2 , key : 'email', label : 'Email'},
    { id:3 , key : 'api_key', label : 'Key'},
    { id:4 , key : 'phone_number', label : 'Phone number'},
    { id:5 , key : 'webhook', label : 'Webhook'},
    { id:6 , key : 'action', label : 'Action'},
];
const CompanyApi = ({company}) => {

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);

    const [currentAction, setCurrentAction] = useState(API_ACTION.none);
    const [rows,setRows] = useState([]);

    const revokeApiKey = useCallback((id) => {
        Swal.fire({
            title: 'Are you sure want to delete?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
              container : {
                zIndex : '10000 !important'
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {
                // call revoke endpoint
                BapiAPI.revokeApi({id,token}).then(res => {
                    // filter 
                    const newR = rows.filter(itm => itm.id !== res.id);
                    console.log(newR)
                    setRows(newR);
                }).catch(e => {
                    console.log(e);
                    toast.error(e?.response?.data?.message)
                })
            }
        })
    },[rows,setRows])

    const onNewKeyCreated = (itm) => {
        const p = {
            id : itm.id,
            username : itm.user?.fullName,
            email : itm.user?.email,
            api_key : <Box display={"flex"}>
                        <Tooltip title={itm.accessKey}>
                            <Box sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>
                                {itm.accessKey}
                            </Box>
                        </Tooltip>
                        <CopyToClipboard
                            onCopy={() => {
                                toast.info("Api Key copied to clipboard");
                            }}
                            text={itm.accessKey}
                        >
                            <ContentCopyIcon
                                sx={{
                                height: "1.5rem",
                                width: "1.5rem",
                                cursor: "pointer",
                                }}
                            />
                        </CopyToClipboard>
                    </Box>,
            webhook : <Box sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>
                        {itm.webhook ? itm.webhook : 'No webhook configured'}
                    </Box>,
            phone_number : itm?.phone,
            action : <Box display={"flex"}>
                <Button onClick={() => revokeApiKey(itm.id)} sx={{padding:'0!important'}}>{"Revoke access"}</Button>
            </Box>
        }
        const exists = rows.find(i => i.id === itm.id);
        if(!exists){
            setRows(prev => [p,...prev]);
        }
        setCurrentAction(API_ACTION.none)
    }

    //FIXME:: it is fetching for entire company, should fetch for user specific as not neccessary every admin has access to same phone number
    useEffect(() => {
        if(company){
            BapiAPI.getBapiListForCompany({companyId : company.companyId, token})
            .then(res => {
                const trows = res.data.map(itm => {
                    return{
                        id : itm.id,
                        username : itm.user?.fullName,
                        email : itm.user?.email,
                        api_key : <Box display={"flex"}>
                                    <Tooltip title={itm.accessKey}>
                                        <Box sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>
                                            {itm.accessKey}
                                        </Box>
                                    </Tooltip>
                                    <CopyToClipboard
                                        onCopy={() => {
                                            toast.info("Api Key copied to clipboard");
                                        }}
                                        text={itm.accessKey}
                                    >
                                        <ContentCopyIcon
                                            sx={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            cursor: "pointer",
                                            }}
                                        />
                                    </CopyToClipboard>
                                </Box> ,
                        webhook : <Box sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>
                                    {itm.webhook ? itm.webhook : 'No webhook configured'}
                                </Box>,
                        phone_number : itm?.phone,
                        action : <Box display={"flex"}>
                                <Button onClick={() => revokeApiKey(itm.id)} sx={{padding:'0!important'}}>{"Revoke access"}</Button>
                            </Box>
                    
                    }
                });
                setRows(trows);
            }).catch(e => console.log(e))
        }
    },[company,token]);

    return (
        <>
            <MDBox display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems="center">
                <MDBox display="flex" width="80%" justifyContent="space-between" mt={2}>
                    <MDTypography>API Keys</MDTypography>
                    <Button
                        onClick={() => setCurrentAction(API_ACTION.create)}
                        variant="contained"
                        sx={{
                            borderRadius: "0px",
                            color: "white!important",
                            border: "unset",
                            marginLeft: "12px",
                            height: "32px",
                            minHeight: "unset",
                        }}
                        endIcon={<Key/>}
                    >
                        Create new
                    </Button>
                </MDBox>
                <MDBox display="flex" flexDirection="column" width="100%" mt={2}>
                    <EnhancedTable showPagination={false} selectEnabled={false} showFilter={false} rows={rows} columns={columns}/>
                    {rows.length === 0 && 
                        <MDBox display="flex" justifyContent="center" alignItems="center" mt={2} width="100%" height="100%">
                            <Typography>
                                {"No data found"}
                            </Typography>
                        </MDBox>    
                    }
                </MDBox>
            </MDBox>
            {currentAction === API_ACTION.create &&  
                <AppModal
                    rootStyle = {
                        {
                            "&.MuiModal-root":{
                                width:'35%',
                                left: '65%'
                            }
                        }
                    }
                    customStyle={{ 
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        border: "unset",
                    }}
                    heading={"Create API Key"}
                    onModalClose={() => setCurrentAction(API_ACTION.none)}        
                    >
                    <CreateApiKey company={company} onCreated={onNewKeyCreated}/>
                </AppModal>}            
        </>
    )
}
export default CompanyApi;