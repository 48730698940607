import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const processPayment = async(payment) => {
    const response = await fetch(`${BASE_URL}/donation/payment`,{
        method : 'POST',
        body : JSON.stringify(payment.payload),
        headers: {
            'Content-Type': 'application/json',
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
} 