import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import Table from "ui/Table";
import { otherShorcutscolumns as columns } from "api/userTableData";
import { getCompanyShortcuts } from "api/shortcut";
import useHttp from "hooks/use-http";
import EditShorcut from "./EditShortcut";
import { Tooltip } from "@mui/material";
const guState = {
    TEXT:'text',
    INPUT:'input'
}
const AdminShortcuts = ({currentUser,companyShorcutCreated}) => {

    const [companyShortcuts, setCompanyShortcuts] = useState([]);

    const {sendRequest : getCompanyShortcutsFn, status : companyShortcutStatus , data : fetchedCompanyShortcuts} = useHttp(getCompanyShortcuts,false);

    const onShorcutEdited = (data) => {

        const newShorcuts = companyShortcuts.map(item => {
            if(item.id === data.id){
                return data;
            }
            else return item;
        })
        setCompanyShortcuts(newShorcuts);
    }
    
    const shortcutDeleted = (data) => {
        const newShorcuts = companyShortcuts.filter(item => item.id !== data.id)
        setCompanyShortcuts(newShorcuts);
    }
    const formatAdminShortcutRows = (shortcuts) => {
        const rows = shortcuts.map((item,index) => {
             const row = {
                 "sno": index + 1,
                 "key" : item.key,
                 "value" : <div 
                    style={{
                    maxWidth:'200px'}}> 
                    {item.value}
                </div>,
                 "created_by" : item.user?.email,
                 "action" : <Tooltip title="Edit">
                     <EditShorcut key={item?.id} onEdited={onShorcutEdited} onDeleted={shortcutDeleted} shortcut={item}  currentUser={currentUser}/>
                </Tooltip>
             }
             return row;
        });
        return rows;
    }

    useEffect(() => {
        getCompanyShortcutsFn({
            companyId : currentUser.company?.companyId,
            token : currentUser.token
        })
    },[companyShorcutCreated]);
    useEffect(() => {

        if(companyShortcutStatus === 'completed'){
            if(fetchedCompanyShortcuts){
                setCompanyShortcuts(fetchedCompanyShortcuts);
            }
        }
    },[companyShortcutStatus,fetchedCompanyShortcuts]);
    return(
        <>
            <MDBox>
                <Table heading={"Company shortcuts"} columns={columns? columns : []} rows={companyShortcuts ? formatAdminShortcutRows(companyShortcuts) : []} />
            </MDBox>        
        </>
    )
}
export default AdminShortcuts;