import AppSnackBar from "ui/AppSnackBar";
import ChatFooter from "../ChatFooter";
import { memo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import useTyping from "hooks/use-typing";
import { useDispatch, useSelector } from "react-redux";
import { amountOfCreditToBeUsed } from "util/ChatUtil";
import { isPromotionalGroup } from "util/GroupUtil";
import { useCallback } from "react";
import useHttp from "hooks/use-http";
import { sendPromotionalGroupConversation } from "api/group";
import PromotionalGpMsg from "components/GroupChat/PromotionalGpMsg";
import { sendGroupConversation } from "api/group";
import { userAction } from "store/user-slice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { groupAction } from "store/group-slice";
import { ROLE } from "constants/AppConstants";
import { uploadAsset } from "util/upload-media-helper";
import { timeoutAction } from "store/timeout-slice";
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";

const GFooterWrapper = ({messageState,setMessageState}) => {

    const dispatch = useDispatch();
    const time = useSelector(state => state.userDetails.details?.userSettings?.teleUserSettings?.messageDelayTime);
    const currentActiveGroup = useSelector(state => state.group.current_active_group);
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);
    const isMergeView = useSelector(state => state.app.mergeViewActive);
    const currentUser = useSelector(state => state.userDetails.details);
    //IF user has zero credit, Get from Company as full by default. 
    const creditAvailable = useSelector((state) => state.user.credit);
    const token = useSelector(state => state.user.token);
    const role = useSelector(state => state.user.role);
    const [promotionalModal,setPromotionalModal] = useState(false);
    const [snackBarState, setSnackBarState] = useState({
        show: false,
        heading: "",
        content: <></>,
    });

    const buildMessageObj = useCallback((params) => {
      const msgPayload = {
        tempId : uuidv4(),
        groupId : currentActiveGroup.groupId,
        sendByPhone: {
          phoneId: currentActivePhone?.phoneId,
          phone : currentActivePhone?.phone
        },
        sendByUser: {
          userId: currentUser.userId,
          fullName: currentUser.fullName,
          profilePic: currentUser.profilePic,
        },
        mediumUsed: "APP",
        tags: [],
        creditUsed: amountOfCreditToBeUsed(params.messageState.message),
        message: params.messageState.message,
        inbound: false,
        wMessageType : 'text',
        status: TELE_MESSAGE_STATUS.prepare,
        timeZone: "UTC",
        type : params.type,
        messageLogType : 'NORMAL',
        domain : currentUser?.company?.slug ? currentUser?.company?.slug : "bliq",
      };
      if(isMergeView) {
        msgPayload.sendByPhone = {
          phoneId : currentActiveGroup.appPhone.phoneId
        }
      }
      return msgPayload;
  },[currentUser,currentActiveGroup,currentActivePhone,isMergeView]);

    // For promotional Group Message ::
    const {sendRequest : sendToPromotionalGpFn, status: promotionalGpMsgStatus, data : promotionalGpData, error : promotionalGpMsgErr} = useHttp(sendPromotionalGroupConversation,false,false)
    const {sendRequest: sendGroupMessageFn,data: gmessageSent,error: gsendMsgErr,status: gsendMsgStatus,} = useHttp(sendGroupConversation, false, false);

    const {onTyping} = useTyping(setMessageState);

    const sendMessage = ({msgPayload,file=undefined}) => {
      (async () => {
        const newPayload = {
          ...msgPayload
        }
        //Don't do anything if credit is not available
        if(creditAvailable - newPayload.creditUsed < 0){
          setSnackBarState({});
          return;
        }
        /// Call the endpoints....
        // If attachment call sendMessageWithAttachement
        if(file && Boolean(file)) {
          // upload media first
          try{
            const uploaded = await uploadAsset({file,token,domain : newPayload.domain});
            const media = [{
              src : uploaded.asset.uri,
              uuid : uploaded.asset.uid
            }];
            newPayload["media"] = media;
            newPayload["message"] = Boolean(newPayload?.message) ? newPayload.message : ""
            newPayload["messageAttchement"] = undefined;
          }catch(e){
            // when successfully uploaded, send message..
            // mark it as failed 
            console.log(e);
            newPayload.status = TELE_MESSAGE_STATUS.failed;
            dispatch(groupAction.updateConversation({message : newPayload, groupId : newPayload.groupId}));
            return;
          }
        }
        if(isPromotionalGroup(currentActiveGroup)){
          sendToPromotionalGpFn({message : newPayload, token});
        }
        else{
          sendGroupMessageFn({message: newPayload, token});
        }
      })();
    }

    const sendHanlder = useCallback((params) => {
      const msgPayload = buildMessageObj(params);
      const file = params.messageState.attachement ? params.messageState.attachement :  undefined;
      if(params.messageState.attachement){
        msgPayload.messageAttchement = URL.createObjectURL(params.messageState.attachement);
      }
      if(params.scheduledAt) {
        msgPayload.scheduledAt = params.scheduledAt;
      }
      if(msgPayload?.scheduledAt){
        sendMessage({msgPayload,file});
      }
      else{
        if(time === 0){
          sendMessage({msgPayload,file})
        }
        else{
          const id = setTimeout(() => sendMessage({msgPayload,file}),(time ? time*1000 : 5000) );
          const payload = {
            messageId : msgPayload.tempId,
            groupId : msgPayload.groupId,
            timeoutObj : {
              id,
              trigger : () => (clearTimeout(id),sendMessage({msgPayload,file}))
            }
          }
          dispatch(timeoutAction.addBroadcastTimer(payload));
        }
      }
      //Append new message.....
      dispatch(groupAction.addGroupMessage({message: msgPayload, groupId : msgPayload.groupId}));
    },[currentActiveGroup,creditAvailable,dispatch,sendGroupMessageFn,sendToPromotionalGpFn,token,time])

    const onReplying = useCallback((e,messageInputRef) => {
      // For dlc regulation
      onTyping(e,currentActiveGroup);
      const creditToUse = amountOfCreditToBeUsed(messageInputRef?.current?.value);
      let newSnackBarState = {};
      if (creditAvailable - creditToUse < 0) {
      newSnackBarState = {
        show: true,
        heading: "Credit Warning",
        type: "error",
        content: <div>You don't have enough credit to send message.</div>,
      };
      } else {
        newSnackBarState = {
          show: true,
          heading: `Message length: ${messageInputRef?.current?.value.length}`,
          content: <div>{creditToUse} credit will be used.</div>,
        };
      }
      if (messageInputRef?.current.value !== "")
        setSnackBarState(newSnackBarState);
      else setSnackBarState({});
    },[currentActiveGroup,creditAvailable,onTyping]);

    const userStopReplying = (messageInputRef) => {
      const newSnackBarState = {
        show: false,
        heading: "",
        content: <></>,
      };
      if (messageInputRef?.current.value === "")
        setSnackBarState(newSnackBarState);
    }; 
       
    const promotionalGpMsgHandler = (data) => {
      setPromotionalModal(false);
      //// When message sent successfully
      if(data){
        dispatch(groupAction.updateConversation({message : data, groupId : data.groupId}));
        if (role !== ROLE.SUPER_ADMIN && data?.status === "sent") {
          dispatch(userAction.decrementCredit({ value: data?.creditUsed }));
        }
      }
      else{
        const chat = {...data,status : 'failed'};
        dispatch(groupAction.updateConversation({message: chat,groupId : data.groupId}));
      }
    }

    useEffect(() => {
      if (promotionalGpMsgStatus === "completed") {
        if(promotionalGpData && promotionalGpData.error === true){
          setPromotionalModal(true);
          setSnackBarState({});
        }
        else{
          if (promotionalGpData) {
            //// When message sent successfully
            dispatch(groupAction.updateConversation({message : promotionalGpData.message, groupId: promotionalGpData.message.groupId}));
            if (role !== ROLE.SUPER_ADMIN && promotionalGpData.message?.status === "sent") {
              dispatch(userAction.decrementCredit({ value: promotionalGpData.message?.creditUsed }));
            }
          } else {
            toast.error(promotionalGpMsgErr);
            // const newMessage = {
            //   ...promotionalGpData.message,
            //   status : 'failed'
            // }
            // dispatch(groupAction.updateConversation({message :newMessage, groupId: promotionalGpData.message.groupId}));
          }
          setSnackBarState({});
        }
      }
    }, [promotionalGpMsgStatus, promotionalGpData, promotionalGpMsgErr,dispatch,role]);
    
    useEffect(() => {
      if (gsendMsgStatus === "completed") {
        if (gmessageSent) {
          //// When message sent successfully
          dispatch(groupAction.updateConversation({message : gmessageSent, groupId : gmessageSent.groupId}));
          if (role !== ROLE.SUPER_ADMIN && gmessageSent?.status === "sent") {
            dispatch(userAction.decrementCredit({ value: gmessageSent?.creditUsed }));
          }
        } else {
          toast.error(gsendMsgErr);
        }
        setSnackBarState({});
      }
    }, [gsendMsgStatus, gmessageSent, gsendMsgErr,dispatch,role]);

      
    return(
        <>
            <AppSnackBar showSnackBar={snackBarState.show} heading={snackBarState.heading} type={snackBarState.type}>
                {snackBarState.content}
            </AppSnackBar>        
            <ChatFooter messageState={messageState} setMessageState={setMessageState} sendMessageHandler={sendHanlder} onReplying={onReplying}/>

            {promotionalModal && promotionalGpData && <PromotionalGpMsg onPromotionalActionPerformed={promotionalGpMsgHandler} gpData={promotionalGpData}/>}
        </>
    )
}
export default memo(GFooterWrapper);