import { phoneColumns } from "api/userTableData";
import Table from "ui/Table";
import AssignedDetails from "./AssignedDetails";
import PhoneActions from "components/Phone/PhoneActions";
import { memo, useEffect, useState } from "react";

const PhoneCredentialsList = ({data,onPhoneAssigned,onPhoneEdited,onDisaUpdated}) => {
    // const rows = [
    //     {
    //         phone: "Test",
    //         apikey : "zxtHJGIG@@@E@",
    //         assigned : "gulshan@gmail.com",
    //         action : <UserAssignment data={[]}/>
    //     }
    // ]
    const [rows,setRows] = useState([]);

    const formatRow = (phone) => {
        return {
            phone: phone?.phone,
            apiuser : phone?.apiUsername,
            company : phone?.company?phone.company?.companyName: 'Available',
            type : phone?.whatsappCred?.accessToken ? (phone.whatsappOnly ? 'WHATSAPP': 'WHATSAPP | SMS') : 'SMS', 
            apikey : <div style={{
                maxWidth:'200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>{phone?.apiKey ? phone.apiKey : 'XXXX-xxxx-XXX.'}</div>,
            auth: <div style={{
                maxWidth:'200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>{phone?.basicAuthHeader}</div>,
            assigned : <AssignedDetails phoneId={phone?.phoneId}/>,
            action : <PhoneActions  onDisaUpdated={onDisaUpdated} key={phone?.phoneId} onPhoneAssigned={(res) => {onPhoneAssigned(res)}} phone={phone} onPhoneEdited={(res) => onPhoneEdited(res)}/>
        }
    } 
    useEffect(() => {
        if(data){
            const newRow = data?.map(itm => formatRow(itm));
            setRows(newRow)
        }
    },[data])
    return(
        <Table canSearch={true} heading={"Numbers"} columns={phoneColumns?phoneColumns : []} rows={rows?rows : []} />
    )
}
export default memo(PhoneCredentialsList);