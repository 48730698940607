import { Paper, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import BackButton from "ui/BackButton";
import CarrierForm from "./CarrierForm";
import MDBadge from "lib/components/MDBadge";
import { saveCarrier } from "api/carrier";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useHttp from "hooks/use-http";

const AddCarrier = ({onBack,onCreated}) => {

    const token = useSelector(state => state.user.token);

    const {sendRequest : saveCarrierFn, data, status, error} = useHttp(saveCarrier,false);

    const onSubmit = (req) => {
        //Validate fields
        if(!req.carrier_name || req.carrier_name.length === 0){
            toast.error("Please fill all the required fields.");
            return;
        }
        saveCarrierFn({req, token});
    }

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                toast.success("Carrier data saved successfully.");
                onCreated(data);
            }
        }
    },[data, status, error]);
    
    return(
        <Paper sx={{
            padding:'12px'
        }}>
            <MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                    <BackButton onClick={onBack} text={"back"}/>                    
                </MDBox>
            </MDBox>
            <MDBox sx={{
                backgroundColor: '#fbfbfb',
                padding:'12px',
                marginTop : '8px'
            }}>
                <Typography>Connect External API </Typography>
                <Typography fontSize={'16px'}>{"You can connect an external carrier or messaging service to Blip using our external API connector. You can specify parameters to be substituted in the URL or body by using these variables:"}</Typography>
                <MDBox paddingLeft='12px' marginBottom="8px">
                    <MDBadge sx={{
                        "& .MuiBadge-badge" : {
                            color: "white!important",
                        }
                    }} badgeContent="text" color={"info"} border={true} variant="contained" container />
                    <Typography sx={{
                        display: 'inline-block',
                    }} fontSize={"15px"}> : The body of the text message being sent</Typography>
                </MDBox>
                <MDBox paddingLeft='12px' marginBottom="8px">
                    <MDBadge sx={{
                        "& .MuiBadge-badge" : {
                            color: "white!important",
                        }
                    }} badgeContent="from" color={"info"} border={true} variant="contained" container />
                    <Typography sx={{
                        display: 'inline-block',
                    }} fontSize={"15px"}> : The phone number used to send the message</Typography>
                </MDBox>
                <MDBox paddingLeft='12px' marginBottom="8px">
                    <MDBadge sx={{
                        "& .MuiBadge-badge" : {
                            color: "white!important",
                        }
                    }} badgeContent="to" color={"info"} border={true} variant="contained" container />
                    <Typography sx={{
                        display: 'inline-block',
                    }} fontSize={"15px"}> : The phone number to which the message should be delivered</Typography>
                </MDBox>
                <MDBox paddingLeft='12px' marginBottom="8px">
                    <MDBadge sx={{
                        "& .MuiBadge-badge" : {
                            color: "white!important",
                        }
                    }} badgeContent="media" color={"info"} border={true} variant="contained" container />
                    <Typography sx={{
                        display: 'inline-block',
                    }} fontSize={"15px"}> : Media to be sent in the message</Typography>
                </MDBox>                                                
            </MDBox>
            <CarrierForm onSubmit={onSubmit}/>
        </Paper>
    )
}
export default AddCarrier;