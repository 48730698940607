import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from "@mui/material";
import { getUserCompany } from "api/company";
import { createPhone } from "api/phoneCredentials";
import ShowCarrier from "components/Carrier/ShowCarrier";
import { NUMBER_TYPE } from "constants/phoneConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


let carrier_id = undefined;

const AddCredentialsForm = ({token,onCredentialAdded}) => {

  const [apiKeyValidation ,setApiKeyValidation] = useState('');
  const [numberType,setNumberType] = useState(NUMBER_TYPE.SMS);
  const [phoneValidation, setPhoneValidation] = useState('');
  const [apiUserNameValidation, setapiUserNameValidation] = useState('');
  const [accessTokenValidation, setAccessTokenValidation] = useState('');
  const [wabaIdValidation, setWabaIdValidation] = useState('');
  const [wabaPhoneIdValidation, setWabaPhoneIdValidation] = useState('');
  const [apiBasicAuthHeader, setapiBasicAuthHeader] = useState('');
  const currentUserId = useSelector(state => state.user.id);
  const phoneRef = useRef();

  /// API key is API token 
  const apikeyRef = useRef();
  const apiUsernameRef = useRef();
  const basicAuthHeaderRef = useRef();
  const accessTokenRef = useRef();
  const wabaIdRef = useRef();
  const wabaPhoneIdRef = useRef();
  const throughputRef = useRef();

  const {sendRequest , data , status, error} = useHttp(createPhone,false);
  const [companyValidation,setCompanyValidation] = useState('');
  const [company,setCompany] = useState('');
  const {sendRequest : getUserComanyFn, status : userCompanyStatus, data:userCompanyList , error:userCompanyError} 
  = useHttp(getUserCompany, false);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  }

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    let phone = phoneRef?.current?.value;
    phone = phone.replace(/\D/g, "");
    const apikey = apikeyRef?.current?.value;
    const apiuser = apiUsernameRef?.current?.value;
    const apiHeader = basicAuthHeaderRef?.current?.value;
    const accessToken = accessTokenRef?.current?.value ? accessTokenRef.current.value : undefined;
    const wabaId = wabaIdRef?.current?.value;
    const wabaPhoneId = wabaPhoneIdRef?.current?.value;
    const throughputVal = throughputRef?.current?.value;
    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(phone)){
      setPhoneValidation('This does not seems a valid number.');
      return;
    }
    if(apiuser === '' && numberType !== NUMBER_TYPE.WHATSAPP){
      setapiUserNameValidation('This cannot be empty. Please enter the user  value.');
      return;
    }
    if(apikey === '' && numberType !== NUMBER_TYPE.WHATSAPP){
      setApiKeyValidation('This cannot be empty. Please enter the key value.');
      return;
    }
    if(apiHeader === '' && numberType !== NUMBER_TYPE.WHATSAPP){
      setapiBasicAuthHeader('This cannot be empty. Please enter the header value.');
      return;
    }   
    if(!company || company === ''){
      setCompanyValidation('Please select the company.');
    }     
    if(!carrier_id && numberType !== NUMBER_TYPE.WHATSAPP ){
      toast.error("Please select the respective carrier");
      return;
    }
    if((phone === '' || apikey === '') && numberType != NUMBER_TYPE.WHATSAPP){
      return;
    } 
    else{
      let newPhone = {
        phone,
        apiKey : apikey,
        apiUsername : apiuser, 
        basicAuthHeader : apiHeader,
        throughput : throughputVal ? throughputVal : undefined,
        token,
        carrierConf : {
          id : carrier_id
       },        
        company : {
          companyId : company.companyId,
          companyName : company.companyName
        }
      }
      if(wabaPhoneId !== ''){
        newPhone.whatsappCred = {
          phoneNumberId : wabaPhoneId,
          accessToken : accessToken,
          waBusinessId : wabaId
        }
      }
      if(numberType === NUMBER_TYPE.WHATSAPP){
        newPhone.whatsappOnly = true;
        newPhone.carrierConf = undefined;
      }
      sendRequest(newPhone);
    }
  }

  const onCarrierSelected = (res) => {
    console.log(res);
    carrier_id = res;
  }

  useEffect(() => {
    getUserComanyFn({token,userId:currentUserId});
  },[]);
  
  useEffect(() => {
    if(status === 'completed'){
      if(error == null){
        toast.success("New phone created successfully.");
        onCredentialAdded(data);
      }
      else{
        toast.error(error);
        onCredentialAdded();  
      }
    }
  } , [status,error])
  return (
    <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox pt={4} pb={3} px={3 } onSubmit={onFormSubmitHandler}>
          
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput error={phoneValidation !== ''} type="tel" label="NUMBER" variant="standard" inputRef={phoneRef} fullWidth />
            </MDBox>
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
              {phoneValidation}
            </MDTypography> 
            <MDBox ml={1} width="100%" display="flex" justifyContent="start">
              <FormGroup>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => {
                          setNumberType(e.target.value)
                        }}
                        value={numberType}
                        >
                        <FormControlLabel value={"SMS"} control={<Radio />} label="SMS"/>
                        <FormControlLabel value={"WHATSAPP"} control={<Radio />} label="WHATSAPP"/>
                        <FormControlLabel value={"BOTH"} control={<Radio />} label="BOTH"/>                        
                    </RadioGroup>
                </FormControl>                
              </FormGroup>
            </MDBox>
            { numberType != NUMBER_TYPE.WHATSAPP && <MDBox>
              <MDBox mb={2}>
                <MDInput error={apiUserNameValidation !== ''} type="text" label="API USERNAME" variant="standard" inputRef={apiUsernameRef} fullWidth />
              </MDBox>                   
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                {apiUserNameValidation}
              </MDTypography> 
              
              <MDBox mb={2}>
                <MDInput error={apiKeyValidation !== ''} type="text" label="API PASSWORD/TOKEN" variant="standard" inputRef={apikeyRef} fullWidth />
              </MDBox>
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                {apiKeyValidation}
              </MDTypography>  
            
              <MDBox mb={2}>
                <MDInput error={apiBasicAuthHeader !== ''} type="text" label="BASIC AUTH HEADER" variant="standard" inputRef={basicAuthHeaderRef} fullWidth />
              </MDBox> 
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                {apiBasicAuthHeader}
              </MDTypography>

              <MDBox mb={2}>
                <MDInput type="number" label="Messages allowed per minute" variant="standard" inputRef={throughputRef} fullWidth />
                <MDTypography fontSize={"12px"} color={"info"}>{"If the carrier does not limit the throughput on this number, then leave this field blank. If you're unsure, you can enter 25. This amount can be updated later."}</MDTypography>
              </MDBox>   
                                            
            </MDBox>}      
            <MDBox mt={3}>
              <FormControl fullWidth sx={{marginRight:'8px'}}>
                <InputLabel id="select-company">SELECT COMPANY FOR NUMBER</InputLabel>
                  <Select
                    labelId="select-company"
                    sx={{height: "40px" }}
                    id="select-company"
                    value={company}
                    label="SELECT COMPANY FOR NUMBER"
                    onChange={handleCompanyChange}
                  >
                  {userCompanyList?.map(item => <MenuItem key={item.companyId} value={item}>{item.companyName}</MenuItem>)}
                  </Select>
              </FormControl>
                <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                {companyValidation}
                </MDTypography>
              </MDBox>          
              {numberType !== NUMBER_TYPE.WHATSAPP && <MDBox mt={2} width="100%">
                <ShowCarrier labelText="CHOOSE CARRIER" onCarrierSelected={onCarrierSelected}/> 
              </MDBox>  }
              {(numberType === NUMBER_TYPE.WHATSAPP || numberType === NUMBER_TYPE.BOTH) && 
              <MDBox display="flex" flexDirection="column">
                <MDBox mb={2}>
                  <MDInput error={accessTokenValidation !== ''} type="text" label="WA ACCESS TOKEN" variant="standard" inputRef={accessTokenRef} fullWidth />
                </MDBox> 
                <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
                      {accessTokenValidation}
                </MDTypography>
                <MDBox mb={2}>
                  <MDInput error={wabaIdValidation !== ''} type="text" label="WA BUSINESS ID" variant="standard" inputRef={wabaIdRef} fullWidth />
                </MDBox> 
                <MDBox mb={2}>
                  <MDInput error={wabaPhoneIdValidation !== ''} type="text" label="WA PHONE NUMBER ID" variant="standard" inputRef={wabaPhoneIdRef} fullWidth />
                </MDBox>                                 
                  <Typography fontWeight={"600"} width={"100%"} fontSize={"12px"}>{"Find this value in your FB dashboard under whatsapp API section."}
                    <Tooltip title="Learn more">
                    <a href='https://developers.facebook.com/apps' target='_blank'><OpenInNewIcon sx={{height:'18px', width:'18px'}}/></a>
                    </Tooltip>
                  </Typography>                
              </MDBox>
            }              
            <MDBox mt={4} mb={1}>
              <MDButton  type="submit" variant="gradient" color="info" fullWidth>
                Create
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
    </MDBox>
  );
};
export default memo(AddCredentialsForm);
