import { createSlice } from "@reduxjs/toolkit";
/***
 * {
    "userId": 5,
    "userName": "user@waymore.com",
    "userEmail": "",
    "token": "",
    "roles": [
        "USER"
    ],
    "valid": true
}
 */
const initialUserStates = {
    id : null,
    name: null,
    email : null,
    token : null,
    role : null,
    isLoggedIn: false,
    credit : 0,
    acceptedTermsAndConditions: null,
    company : null
};

const userSlice = createSlice({
    name : 'user',
    initialState : initialUserStates,
    reducers : {
        login: (state,action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.isLoggedIn = action.payload.isLoggedIn;
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.email = action.payload.email;
            state.credit = action.payload.credit;
            state.company = action.payload.company;
            state.acceptedTermsAndConditions = action.payload.acceptedTermsAndConditions
        },
        logout: (state) => {
            state.id = null;
            state.name = null;
            state.isLoggedIn = false;
            state.token = null;
            state.role = null;
            state.credit = null;
            state.company = null;
            state.acceptedTermsAndConditions = null;
        },
        updateTermsAndConditions : (state) => {
            state.acceptedTermsAndConditions = true;
        },
        decrementCredit : (state,action) => {
            state.credit = state.credit - (action.payload.value)
        },
        setCredit : (state,action) => {
            state.credit = action.payload.credit;
        },
        reset : () => initialUserStates
    }
})

export const userAction = userSlice.actions;
export default userSlice;