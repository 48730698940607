import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "lib/components/MDButton";
import CustomeDateRangeSchedule from "./CustomeDateRangeSchedule";
import UserPhoneList from "../Phone/UserPhoneList";
import { useCallback } from "react";
import { memo } from "react";
import { CompanyAPI } from "services/CompanyAPI";
import { useDispatch, useSelector } from "react-redux";
import { loaderAction } from "store/loader-slice";
import { toast } from "react-toastify";
import AutoReplyRuleSettings from "./AutoReplyRuleSettings";
import { DEFAULT_DAYS_SETTINGS } from "constants/AutoReplyConstants";
import { DEFAULT_CUSTOM_DATE } from "constants/AutoReplyConstants";

const autoReplyTimings = {
  companyWide : true,
  zoneId : Intl.DateTimeFormat().resolvedOptions().timeZone,
  phoneSettings : [],
  companyWideSettings : {
    scheduleType : 'weekly',
    days : [...DEFAULT_DAYS_SETTINGS],
    customDate : DEFAULT_CUSTOM_DATE
  }
}

const AutoReplyRule = ({phones}) => {

  const dispatch = useDispatch();
  const [scheduledOptions,setScheduledOptions] = useState();
  const [selectedPhone,setSelectedPhone] = useState();
  const token = useSelector(state => state.user.token);
  const companyId = useSelector(state => state.user.company.companyId);

  const saveSchedule  =  useCallback(() => {
    dispatch(loaderAction.show());
    CompanyAPI.updatAutoReplyTiming({token,payload : scheduledOptions, companyId : scheduledOptions.companyId})
      .then(res => {
        setScheduledOptions(res.data);
        dispatch(loaderAction.hide());
        toast.success("Settings saved successfully.");
      }).catch(e => {
        console.log(e);
        toast.error("Something went wrong, please try again");
        dispatch(loaderAction.hide());
      })
  },[scheduledOptions]);

  const getScheduleType = useCallback(() => {
    let returnVal = 'weekly';
    if(scheduledOptions) {
      if(scheduledOptions.companyWide) {
        const t = scheduledOptions?.companyWideSettings?.scheduleType;
        returnVal = t;
      }
      else{
        let phoneSettings = scheduledOptions?.phoneSettings[0];
        returnVal = phoneSettings?.options?.scheduleType;
      }
    }
    return Boolean(returnVal) ? returnVal : 'weekly';
  },[scheduledOptions,selectedPhone])

  const handleScheduleTypeChange = useCallback((e) => {
    if(scheduledOptions.companyWide) {
      setScheduledOptions(prev => {
        return{
          ...prev,
          companyWideSettings : {...prev.companyWideSettings,scheduleType : e.target.value}
        }
      })
    }
    else{
      if(selectedPhone){
        const newPhoneSettings = scheduledOptions.phoneSettings.map(item => {
          if(item?.phoneId === selectedPhone?.phoneId) {
            return{
              ...item,
              options : {
                ...item.options,
                scheduleType : e.target.value
              }
            }
          }
          else{
            return item;
          }
        });
        setScheduledOptions(prev => {
          return {
            ...prev,
            phoneSettings : newPhoneSettings
          }
        })
      }
    }
  },[scheduledOptions,selectedPhone])

  const renderScheduleType = useCallback(() => {
    let type = undefined;
    if(!scheduledOptions) {
      return undefined;
    }
    if(scheduledOptions.companyWide) {
      type = scheduledOptions?.companyWideSettings?.scheduleType;
      if(type === 'weekly'){
        return  <AutoReplyRuleSettings key={"company"} companyWide={true} scheduleData={scheduledOptions?.companyWideSettings?.days} setScheduledOptions={setScheduledOptions} />;
      }
      else if(type === 'custom'){
          return <MDBox display="flex" justifyContent="flex-start" width="100%" padding="14px"> 
            <CustomeDateRangeSchedule companyWide={true} setScheduledOptions={setScheduledOptions} initialDate={scheduledOptions?.companyWideSettings?.customDate}/>
          </MDBox>
      }
    }
    else {
      const phoneSettings = scheduledOptions?.phoneSettings?.find(item => item?.phoneId === selectedPhone?.phoneId);
      if(!phoneSettings && selectedPhone?.phoneId) {
        const newData =  {
          phoneId : selectedPhone?.phoneId,
          options : {
            scheduleType : 'weekly',
            days : [...DEFAULT_DAYS_SETTINGS],
            customDate: DEFAULT_CUSTOM_DATE
          }
        }
        setScheduledOptions(prev => {
          if(prev.phoneSettings){
            return{
              ...prev,
              phoneSettings : [...prev.phoneSettings,newData]
            }
          }
          else{
            return{
              ...prev,
              phoneSettings : [newData]
            }
          }
        });
        return;
      }
      if(phoneSettings){
        type = phoneSettings?.options?.scheduleType;
          if(type === 'weekly'){
            return <AutoReplyRuleSettings key={"phone_" + selectedPhone?.phoneId} scheduledOptions={scheduledOptions} phoneId={selectedPhone?.phoneId} companyWide={false} scheduleData={phoneSettings?.options?.days} setScheduledOptions={setScheduledOptions} />;
          } 
          else if(type === 'custom') {
            return <MDBox display="flex" justifyContent="flex-start" width="100%" padding="14px"> 
              <CustomeDateRangeSchedule scheduledOptions={scheduledOptions} phoneId={selectedPhone?.phoneId} setScheduledOptions={setScheduledOptions} initialDate={phoneSettings?.options?.customDate}/>
            </MDBox>
          }
      } 
    }
  },[scheduledOptions,selectedPhone])

  useEffect(() => {
    CompanyAPI.getAutoReplyTimings({token,companyId}).then(res => {
      if(res.data){
        setScheduledOptions(res.data);
      }else{
        const newTiming = {
          ...autoReplyTimings,
          companyId : companyId
        }
        setScheduledOptions(newTiming);
      }
    }).catch(e => {});
  },[token,companyId])

  return (
    <Box sx={{ width: "100%", height: "100%",padding:'8px'}}>     
      <MDBox p={1}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Auto Reply Schedule
        </MDTypography>
      </MDBox>
      <MDBox sx={{width:'400px'}} p={1}>
        <Typography sx={{marginBottom:'8px'}} fontSize={"14px"}>{"Select phone to apply"}</Typography>
        {<UserPhoneList phones={phones} nativeFetch={false} disabled={scheduledOptions?.companyWide} onPhoneSelected={(phone) => setSelectedPhone(phone)}/>}
        <FormControlLabel onChange={(e) => {
          setScheduledOptions(prev => {
            return {
              ...prev,
              companyWide : e.target.checked
            }
          })
        }} checked={scheduledOptions ? scheduledOptions.companyWide : false}  control={<Checkbox />} label="Apply schedule options to all company numbers" />
      </MDBox>
      <Grid container alignItems="center" justifyContent="flex-start">
        <MDBox marginLeft="6px">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={getScheduleType()}
            onChange={handleScheduleTypeChange}
          >
            <FormControlLabel value="weekly" control={<Radio />} label="Weekly Schedule" />
            <FormControlLabel value="custom" control={<Radio />} label="Specific Date Range" />
          </RadioGroup>
        </MDBox>
        {renderScheduleType()}
      </Grid>
      <MDBox display="flex" flexDirection="row" justifyContent="flex-end" mb={2}>
          <MDButton onClick={saveSchedule} variant="gradient" color="info" style={{marginLeft:'10px'}}>Update</MDButton>
      </MDBox>       
    </Box>
  );
};
export default memo(AutoReplyRule);

