import { companyColumns } from "api/userTableData";
import MDAvatar from "lib/components/MDAvatar";
import Table from "ui/Table";
import CompanyActions from "./CompanyActions";
import userPng from "assets/images/user.png";
import { useSelector } from "react-redux";
import CompanyCredit from "./CompanyCredit";
import { captializeFirstLetter } from "util/UtilityFunctions";
import { memo, useState } from "react";
import { useCallback } from "react";
import { ROLE } from "constants/AppConstants";

const CompanyList = ({data,onCompanyEdited}) => {
    
    const role = useSelector(state => state.user.role);
    
    const [companies,setCompanies] = useState(data);

    const onHipaaSubscribed = (companyId,subscribed) => {
        const newCompanies = companies.map(itm => {
            if(itm.companyId === companyId) {
                return {...itm,hipaaCompliance: subscribed}
            }
            else return itm;
        }) 
        setCompanies(newCompanies);
    }

    const onProductSubscribed = (res) => {
        const {companyId,subscription} = res;
        const newCompanies = companies.map(itm => {
            if(itm.companyId === companyId) {
                return {
                    ...itm,
                    products_subscribed : [...itm.products_subscribed,subscription]
                }
            }
            else return itm;
        }) 
        setCompanies(newCompanies);
    }
    const onProductUnsubscribed = (res) => {
        const {companyId,subscription} = res;
        const newCompanies = companies.map(itm => {
            if(itm.companyId === companyId) {
                const filterdProd = itm.products_subscribed?.filter(pr => pr.id !== subscription.id);
                return {
                    ...itm,
                    products_subscribed : filterdProd
                }
            }
            else return itm;
        }) 
        setCompanies(newCompanies);
    }
    const companyEdited = useCallback((res) => {
        const newCompanies = companies.map(itm => {
            if(itm.companyId === res.companyId) {
                return res
            }
            else return itm;
        }) 
        setCompanies(newCompanies);
        onCompanyEdited(res);
    },[companies]);


    if(companies?.length === 0){
        return  <Table heading={role === ROLE.SUPER_ADMIN ? "Companies" : 'Company'} columns={[]} rows={[]} />;        
    }
    const rows = companies?.map((c,index) => {
        const currentCompany = {
            "sno" : index + 1,
            "logo" : <MDAvatar src={c?.companyLogo ? c?.companyLogo : userPng} size="sm"/>,
            "company" : c.companyName ? captializeFirstLetter(c.companyName): '',
            "total_credit" : <CompanyCredit credit={c?.totalCredit ? c?.totalCredit : 0} companyId={c?.companyId}/>,
            action : <CompanyActions onProductUnsubscribed={onProductUnsubscribed} onProductSubscribed={onProductSubscribed} onHipaaSubscribed={onHipaaSubscribed} key={"company_action" + index} onCompanyEdited={companyEdited} company={c}/>
        }
        return currentCompany;
    });
    return (
        <Table canSearch={true} heading={role === ROLE.SUPER_ADMIN ? "Companies" : 'Company'} columns={companyColumns?companyColumns : []} rows={rows?rows : []} />
    )
}
export default memo(CompanyList);