import { Download } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { initialPreview } from "api/pfdFeatures";
import { getPdfFooterPreview } from "api/pfdFeatures";
import Accordian from "components/util/Accordian";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReceiptPDF from "./ReceiptPDF";

const CrowdfeatureSetting = ({company}) => {

    const [pdfBytes, setPdfBytes] = useState(null);

    const token = useSelector(state => state.user.token);
    const [footer, setFooter] = useState(); 
    const {sendRequest : pdfPreviewFn, data , status ,error} = useHttp(getPdfFooterPreview,false);
    const {sendRequest : initialPreviewFn, data : initialData , status:initialStatus , error : initialError} = useHttp(initialPreview,false);
    const onPreviewClick = () => {
        pdfPreviewFn({token,footer});
    }
    
    const downloadPDFTemplate = () => {
      const link = document.createElement("a");
      link.download = "receipt_template.pdf";
      link.href = URL.createObjectURL(pdfBytes);
      link.click();
    }

    useEffect(() => {
      let tmp = undefined;
      if(company){
        if(company?.footer){
           tmp = {
            id : company.footer.id,
            footer_content : company.footer.footer_content,
            company : {
              companyId : company.companyId,
              companyLogo : company.companyLogo
            }
          }
        }
        else{
           tmp = {
            footer_content : '',
            company : {
              companyId : company.companyId,
              companyLogo : company.companyLogo
            }
          }
        }
        setFooter(tmp);  
        initialPreviewFn({token,company});
      }
    },[company]);

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          setPdfBytes(data);
        }
      }
    },[data,status, error]);

    useEffect(() => {
      if(initialStatus === 'completed'){
        if(initialData){
          setPdfBytes(initialData)
        }
      }
    },[initialStatus,initialData])

    return (
      <>
        <MDBox height="100vh">
          <Accordian heading={"Donation receipt settings "}>
            <MDBox display="flex" width="100%" flexDirection="row" height="100%" justifyContent="space-between">
                <MDBox width="50%" margin={"8px"}>
                  <MDBox>
                    <MDInput
                        multiline
                        minRows={2}
                        sx={{ marginRight: "8px" }}
                        fullWidth
                        onChange={(e) => {
                          setFooter(prev => {
                            return{
                              ...prev,
                              footer_content : e.target.value
                            }
                          })
                        }}
                        type="text"
                        label="Footer"
                        value={footer?.footer_content}
                    />
                    <Typography margin={"8px"} fontSize={"12px"}>
                        The content that will be shown as footer in receipt.
                    </Typography>
                  </MDBox>
                  <MDBox>
                  <MDButton onClick={onPreviewClick} variant="gradient" color="info">Save</MDButton>
                  </MDBox>
                </MDBox>
                <MDBox width="40%" sx={{marginRight:'24px'}}>
                  <MDBox sx={{marginLeft : '18px'}}>
                    <MDBox sx={{marginBottom : '8px'}} width="100%" display="flex" justifyContent="flex-end" flexDirection="row">
                      <Tooltip title="Download template">
                        <Download sx={{cursor:'pointer',height:'1.2rem',width:'1.2rem'}} onClick={downloadPDFTemplate}/>
                      </Tooltip>
                    </MDBox>
                    <MDBox sx={{border:'1px solid #ccc', borderRadius:'2%', height:'600px'}}>
                      <ReceiptPDF url={pdfBytes}/>
                    </MDBox>
                  </MDBox>
                </MDBox>
            </MDBox>
          </Accordian>
        </MDBox>
      </>
    );
}
export default CrowdfeatureSetting;