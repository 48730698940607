import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getPdfFooterPreview = async({token,footer}) => {
    const response = await fetch(`${BASE_URL}/donation/pdf`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        },
        body: JSON.stringify(footer)
    });
    const data1 = await response.arrayBuffer();
    const data = new Blob([data1], { type: 'application/pdf' });

    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const initialPreview = async({token,company}) => {
    const response = await fetch(`${BASE_URL}/donation/pdf/initial/preview`,{
        method : 'POST',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        },
        body: JSON.stringify(company)
    });
    const data1 = await response.arrayBuffer();
    const data = new Blob([data1], { type: 'application/pdf' });

    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}