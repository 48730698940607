import { Typography } from "@mui/material";
import UserPhoneList from "components/Phone/UserPhoneList";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BapiAPI } from "services/Bapi/BapiService";
import { loaderAction } from "store/loader-slice";

/**
 * {
 *  username : 'waymore',
 *  email : '', email to use
 *  Api key : 'somekey',
 *  phone number : ''
 *  webhook url : '' // in case we have to call the endpoint if inbound is received
 *  last used : 'somewhere',
 * }
 * 
 */

const CreateApiKey = ({company,onCreated = () => {}}) => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);

    const [apiData,setApiData] = useState({
        userName : '',
        companyId : company.companyId,
        companyName : company.companyName
    })

    const onGenerateHandler = useCallback(() => {
        if(apiData?.userName.trim().length === 0){
            setApiData(prev => {
                return{
                    ...prev,
                    userName : ''
                }
            })  
            return;    
        }
        else if(apiData?.phone?.trim().length === 0) {
            setApiData(prev => {
                return{
                    ...prev,
                    phone : ''
                }
            })     
            return;             
        }        
        dispatch(loaderAction.show());
        BapiAPI.createPhoneBapi({payload : apiData,token}).then(res => {
            dispatch(loaderAction.hide());
            onCreated(res.data)
        }).catch(e => {
            dispatch(loaderAction.hide());
            console.log(e);
            toast.error(e?.response?.data?.message);
        })
    },[apiData,setApiData,token])

    return(
        <>
            <MDBox display="flex" flexDirection="column">
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox display="flex" flexDirection="column">
                        <MDBox mb={2}>
                            <MDInput
                                error={apiData.userName.length === 0}
                                type="text"
                                label="User name"
                                variant="standard"
                                fullWidth
                                onChange={(e) => setApiData(
                                    prev => {
                                        return{
                                            ...prev,
                                            userName : e.target.value?.trim()
                                        }
                                    }
                                )}
                            />
                        </MDBox>

                        <MDBox mb={2} display="flex" flexDirection="column">
                            <MDInput
                                type="text"
                                label="Webhook(Optional)"
                                variant="standard"
                                fullWidth
                                onChange={(e) => setApiData(
                                    prev => {
                                        return{
                                            ...prev,
                                            webhook : e.target.value?.trim()
                                        }
                                    }
                                )}
                            />
                            <Typography margin={'8px'} fontSize={'12px'} fontWeight={600}>The webhook should accept post request. For every inbound message we will post data to this url. </Typography>
                        </MDBox>

                        <MDBox display="flex" width="100%">
                            <UserPhoneList customStyle={{width:'100%',marginTop:'12px'}} onPhoneSelected={(phone) => {
                                setApiData(prev => {
                                    return{
                                        ...prev,
                                        phone : phone.phone,
                                        phoneId : phone.phoneId
                                    }
                                })
                            }}/>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                            <MDButton onClick={onGenerateHandler} variant="gradient" color="info" fullWidth>
                                Generate key
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>        
        </>
    )
}
export default CreateApiKey;