import { API_V1_SMS } from "constants/Endpoints";
import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getGroups = async({phoneId,token}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/${phoneId}/groups`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const getMessagePendingsRecipient = async({messageId,token}) => {
    const response = await fetch(`${BASE_URL}/sms/${messageId}/delivery/pending`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getPendingConsents = async({groupId,token}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/group/${groupId}/consent/pending`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const getGroupsConversation = async({token,group_id}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/group/conversations`,{
        method : 'POST',
        body : JSON.stringify({"group_id":group_id}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getConsentStatusForRecipient = async({token,payload}) => {
    const response = await fetch(`${BASE_URL}/sms/phone/group/consent/status`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const processRecipientAdditionWithConsentOpt = async({token,payload}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/consent/process`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const sendGroupConversation = async({message,token}) => {
    const response = await fetch(`${BASE_URL}/sms/phone/group/recipient`,{
        method : 'POST',
        body : JSON.stringify(message),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}
export const sendPromotionalGroupConversation = async({message,token}) => {
    const response = await fetch(`${BASE_URL}/sms/phone/group/recipient/restricted`,{
        method : 'POST',
        body : JSON.stringify(message),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const processPromotionalGroupConversation = async({payload,token}) => {
    const response = await fetch(`${BASE_URL}/sms/phone/group/recipient/restricted/process`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const getGroupRecipients = async({groupId,token}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/${groupId}/recipients`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const getGroup = async({groupId,token}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/group/${groupId}`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const createGroup = async(group) => {
    const payload = {
        ...group,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/create/group`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + group.token,
          },
        body : JSON.stringify(payload),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const updateGroup = async({group,token}) => {
    const response = await fetch(`${BASE_URL}${API_V1_SMS}/update/group`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
        body : JSON.stringify(group),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}