import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import { useEffect, useState } from "react";
import AddButton from "ui/AddButton";
import AppModal from "ui/AppModal";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import UserList from "../../components/User/UserList";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import UserPhoneAssignFlowWrapper from "components/Phone/UserPhoneAssignFlowWrapper";
import { UserAPI } from "services/UserAPI";
import { toast } from "react-toastify";

const Users = () => {

  const token = useSelector(state => state.user.token);
  const company = useSelector(state => state.user.company);
  const userId = useSelector(state => state.user.id);
  const [isRegister, setIsRegister] = useState(false);
  const [users, setUsers] = useState([]);
  const onCreateUserHandler = (res) => {
    if(res){
      setUsers(prev => [res,...prev]);
    }
  }

  const getUsersFn = (payload) => {
    UserAPI.getUsers(payload).then(res => {
      setUsers(res.data);
    }).catch(e => {
      toast.error(e?.response?.data?.message);
    })
  }
  const refreshUser = () => {
    getUsersFn({token,companyId : company?.companyId,userId});
  }
  //// retrieve users
  useEffect(() => {
    refreshUser();
  },[]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddButton
        onClick={() => {
          setIsRegister(true);
        }}
        text={"create new user"}
      />
      {users && <UserList data={users} refetch={refreshUser}/>}
      {isRegister && (
        <AppModal
          heading={"Create User"}
          onModalClose={() => {
            setIsRegister(false);
          }}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle={{ 
              width: "100%",
              height: "100%",
          }}          
        >
          <UserPhoneAssignFlowWrapper setIsRegister={setIsRegister} onCreateUserHandler={onCreateUserHandler  }/>
        </AppModal>
      )}
    </DashboardLayout>
  );
};
export default Users;
