import EnhancedTable from "components/NumberPool/EnhancedTable";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const ShowData = ({data,setDisableNext = () => {}}) => {

    const [columns,setColumns] = useState([]);
    const [rows,setRows] = useState([]);
    
    useEffect(() => {
        if(data){
            // { id: 1, key :  "number", label: "Number",},
            const tmpCol = [];
            for(const itm in data){
                tmpCol.push(itm);
            }
            if(tmpCol.length < 3) {
                toast.error("The uploaded file does not have enough number of columns to map the data.");
                setDisableNext(true);
                return;
            }
            const newTmpCol = tmpCol.map((itm,indx) => {
                return{
                    id : indx + 1,
                    key : itm,
                    label : itm
                }
            })
            setColumns(newTmpCol);

            // console.log(data[tmpCol[0]])
            // Format ROWS
            const tmpRows = [];
            for(let root = 0; root < data[tmpCol[0]].length; root++){
                const t = {}
                for(const itm of tmpCol){
                    t[itm] = data[itm][root]
                }
                tmpRows.push(t);
            }
            setRows(tmpRows);
        }
    },[data]);

    return(
        <>
            <MDBox display="flex" flexDirection="column" width="100%">
                <MDTypography sx={{textAlign:'center'}}>{"Data preview"}</MDTypography>
                <MDBox m={2} sx={{overflow:'auto',height:'calc(100vh - 100px)',width:'100%!important'}}>
                    <EnhancedTable
                    rows={rows}
                    heading={"Data"}
                    columns={columns}
                    showFilter={false}
                    selectEnabled={false}
                    />
                </MDBox>            
            </MDBox>
        </>
    )
}
export default ShowData;