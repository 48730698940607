import * as React from "react";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import useHttp from "hooks/use-http";
import MDTypography from "lib/components/MDTypography";
import { useSelector } from "react-redux";
import ScheduleMessage from "./ScheduleMessage";
import { sendBlastToIndividual } from "api/bulkBlast";
import EnhancedTable from "components/NumberPool/EnhancedTable";

const columns = [
  { id: 1, key :  "number", label: "Number",},
  { id: 2 , key : "message", label: "Message",},
];

export default function BulkBlastTable({list,onSend,currentPhone}) {
  
  const [type, setType] = React.useState("INDIVIDUAL");
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [rows,setRows] = React.useState([]);

  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id)

  const {
    sendRequest: sendBlastIndFn,
    status: bulkStatusi,
    error: bulkErrori,
    data: bulkDatai,
  } = useHttp(sendBlastToIndividual, false);

  const buildMessageObj = () => {
    return {
      smsList: list,
      sendByPhone: {
        phone: currentPhone.phone,
        phoneId : currentPhone.phoneId
      },
      token: token,
      sendByUser: {
        userId,
      },
    };
  }
  
  const onSendHandler = React.useCallback((scheduled = undefined) => {
    const payload = buildMessageObj();
    if (scheduled) {
      payload.scheduled = true;
      payload.scheduledAt = scheduled;
      payload.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if (type === "INDIVIDUAL") {
      payload.sendType = "INDIVIDUAL";
      sendBlastIndFn({payload,token});
    }
  },[token,list,currentPhone,userId]);

  const onDoneEvent = (input) => {
    setScheduleModal(false);
    onSendHandler(input);
  };

  const onScheduleSendHandler = () => {
    setScheduleModal(true);
  };

  React.useEffect(() => {
    if(list){
      setRows(list);
    }
  }, [list]);

  React.useEffect(() => {
    if (bulkStatusi === "completed") {
      onSend("INDIVIDUAL");
    }
  }, [bulkStatusi, bulkErrori]);

  return (
    <MDBox padding="16px">
      <MDBox m={2} sx={{overflow:'auto',height:'calc(100vh - 332px)'}}>
        <EnhancedTable
          rows={rows}
          heading={"Logs"}
          columns={columns}
          showFilter={false}
          selectEnabled={false}
        />
      </MDBox>
      <MDBox
        style={{ marginTop: "12px" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox display="flex" justifyContent="flex-start" alignItems="center">
          <MDTypography>{"Message will be sent individually."}</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        style={{
          marginTop: "12px",
          width: "100%",
          justifyContent: "center",
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <MDButton
          onClick={() => onSendHandler()}
          size={"medium"}
          color="info"
          variant="outlined"
          style={{ marginRight: "12px" }}
        >
          Send
        </MDButton>
        <MDButton
          onClick={onScheduleSendHandler}
          size={"medium"}
          color="info"
          variant="outlined"
        >
          Schedule
        </MDButton>
      </MDBox>
      {scheduleModal && (
        <ScheduleMessage
          onDone={onDoneEvent}
          onClose={() => {
            setScheduleModal(false);
          }}
        />
      )}
    </MDBox>
  );
}
