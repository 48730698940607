
import { createPhone } from "api/phoneCredentials";
import ShowCarrier from "components/Carrier/ShowCarrier";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

let carrier_id = undefined;

const CreatePhone = ({token,onCreate}) => {

  const [apiKeyValidation ,setApiKeyValidation] = useState('');
  const [phoneValidation, setPhoneValidation] = useState('');
  const [apiUserNameValidation, setapiUserNameValidation] = useState('');
  const [apiBasicAuthHeader, setapiBasicAuthHeader] = useState('');
  const currentUserId = useSelector(state => state.user.id);
  const phoneRef = useRef();
  /// API key is API token 
  const apikeyRef = useRef();
  const apiUsernameRef = useRef();
  const basicAuthHeaderRef = useRef();

  const {sendRequest , data , status, error} = useHttp(createPhone,false);

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    const phone = phoneRef.current.value;
    const apikey = apikeyRef.current.value;
    const apiuser = apiUsernameRef.current.value;
    const apiHeader = basicAuthHeaderRef.current.value;

    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(phone)){
      setPhoneValidation('This does not seems a valid number.');
      return;
    }
    if(apiuser === ''){
      setapiUserNameValidation('This cannot be empty. Please enter the user  value.');
      return;
    }
    if(apikey === ''){
      setApiKeyValidation('This cannot be empty. Please enter the key value.');
      return;
    }
    if(apiHeader === ''){
      setapiBasicAuthHeader('This cannot be empty. Please enter the header value.');
      return;
    }   
    if(!carrier_id){
      toast.error("Please select the respective carrier");
      return;
    }  
    if(phone === '' || apikey === ''){
      return;
    } 
    else{
      let newPhone = {
        phone,
        apiKey : apikey,
        apiUsername : apiuser, 
        basicAuthHeader : apiHeader,
        carrierConf : {
           id : carrier_id
        },
        token
      }
      sendRequest(newPhone);
    }
  }
  
  const onCarrierSelected = (res) => {
    carrier_id = res;
  }

  useEffect(() => {
    if(status === 'completed'){
      if(error == null){
        toast.success("New phone created successfully.");
        onCreate(data);
      }
      else{
        toast.error(error);
        onCreate();  
      }
    }
  } , [status,error])
  return (
    <MDBox display="flex" flexDirection="column" width="100%">
      <MDBox pt={4} pb={3} px={3 } onSubmit={onFormSubmitHandler}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput error={phoneValidation !== ''} type="tel" label="NUMBER" variant="standard" inputRef={phoneRef} fullWidth />
          </MDBox>
            <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {phoneValidation}
          </MDTypography> 
          
          <MDBox mb={2}>
            <MDInput error={apiUserNameValidation !== ''} type="text" label="API USERNAME" variant="standard" inputRef={apiUsernameRef} fullWidth />
          </MDBox>                   
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {apiUserNameValidation}
          </MDTypography> 

          <MDBox mb={2}>
            <MDInput error={apiKeyValidation !== ''} type="text" label="API PASSWORD/TOKEN" variant="standard" inputRef={apikeyRef} fullWidth />
          </MDBox>
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {apiKeyValidation}
          </MDTypography>  

          <MDBox mb={2}>
            <MDInput error={apiBasicAuthHeader !== ''} type="text" label="BASIC AUTH HEADER" variant="standard" inputRef={basicAuthHeaderRef} fullWidth />
          </MDBox> 
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {apiBasicAuthHeader}
          </MDTypography> 
          <ShowCarrier onCarrierSelected={onCarrierSelected}/>                                       
          <MDBox mt={4} mb={1}>
            <MDButton  type="submit" variant="gradient" color="info" fullWidth>
              Create
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default memo(CreatePhone);
