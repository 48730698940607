import { adminPhoneColumns } from "api/userTableData";
import Table from "ui/Table";
import AssignedDetails from "./AssignedDetails";
import PhoneActions from "./PhoneActions";
import { useEffect, useState } from "react";

const AdminPhoneCredentialsList = ({data,onPhoneAssigned,onDisaUpdated}) => {
    // const rows = [
    //     {
    //         phone: "Test",
    //         apikey : "zxtHJGIG@@@E@",
    //         assigned : "gulshan@gmail.com",
    //         action : <UserAssignment data={[]}/>
    //     }
    // ]
    const [rows,setRows] = useState([]);

    const formatRow = (phone) => {
        return {
            phone: phone?.phone,
            type : phone?.whatsappCred?.accessToken ? (phone.whatsappOnly ? 'WHATSAPP': 'SMS | WHATSAPP') : 'SMS', 
            assigned : <AssignedDetails phoneId={phone?.phoneId}/>,
            action : <PhoneActions key={phone?.phoneId} onDisaUpdated={onDisaUpdated} onPhoneAssigned={(res) => {onPhoneAssigned(res)}} phone={phone}/>
        }
    }

    useEffect(() => {
        if(data){
            const newRow = data?.map(itm => formatRow(itm));
            setRows(newRow)
        }
    },[data])

    return(
        <Table canSearch={true} heading={"Numbers"} columns={adminPhoneColumns?adminPhoneColumns : []} rows={rows?rows : []} />
    )
}
export default AdminPhoneCredentialsList;