import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState} from "react";
import AppModal from "ui/AppModal";
import { Tooltip} from "@mui/material";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editCampaign } from "api/campaign";

const EditCampaign = ({onCampaignEdited, campaign}) => {
    
    const [action, setAction] = useState();
    const [formData, setFormData] = useState();
    const handleClick = (event) => {
        setAction(true);
  };
 
  const {
    sendRequest: editCampaignFn,
    status,
    data,
    error,
  } = useHttp(editCampaign, false);

  const currUser = useSelector((state) => state.user);
  const [campaignFormValidation, setCampaignFormValidation] = useState({
    nameValidation : '',
    goalAmtValidation : '',
  })

  const onFormSubmit = (event) => {
    event.preventDefault();

    if(formData.campaign_name === "") {
      setCampaignFormValidation(prev => {
        return{
            ...prev,
            nameValidation : 'Title can not be empty.'
        }
      })
    }
    else if (!(/^[0-9]*$/).test(formData.goal_amount)) {
      setCampaignFormValidation(prev => {
        return{
            nameValidation : '',
            goalAmtValidation : 'Set a valid goal amount.'
        }
      })
    }
    else{
      let campaignd = {
        payload: {
          campaign_name: formData.campaign_name,
          campaign_id : formData.campaign_id,
          goal_amount: formData.goal_amount,
          completion_date: formData.completion_date,
          companyId: currUser.company.companyId
        },
        token: currUser.token,
      };
      editCampaignFn(campaignd);
    }
  };

  useEffect(() => setFormData(campaign),[campaign])

  useEffect(() => {
    if(status === 'completed'){
      if(error == null){
        toast.success("Campaign updated successfully.");
        setAction('')
        setCampaignFormValidation({
          nameValidation : '',
          goalAmtValidation : '',
        })
        onCampaignEdited(data);
      }
      else{
        toast.error(error);
        // onCampaignEdited();  
      }
    }
  },[status,data,error])
  
     
  return (
    <div>
        <MDBox
        sx={{}}
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Edit campaign data">
          <EditIcon
            onClick={handleClick}
            sx={{ width: "1.2rem", height: "1.2rem"}}
            style={{ cursor: "pointer", fontSize: "40px!important" }}
          />
        </Tooltip>

      </MDBox>

      {action === true && (
        <AppModal
          heading={"Edit campaign"}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle={{ 
              width: "100%",
              height: "100%",
          }}          
          onModalClose={() => {
            setAction(-1);
          }}
        >
      <>
        <MDBox display="flex" width="100%" flexDirection="column">
          <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Title"
                  variant="standard"
                  fullWidth
                  value={formData.campaign_name}
                  onChange = { (e) => {
                    setFormData(prev => {
                      return {
                        ...prev, campaign_name : e.target.value
                      }
                    })
                  }}
                />
              </MDBox>
              <MDTypography
                variant="overline"
                fontWeight="medium"
                color="error"
                mt={1}
              >
                {campaignFormValidation.nameValidation}
              </MDTypography>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Goal amount"
                  variant="standard"
                  fullWidth
                  value={formData.goal_amount}
                  onChange = { (e) => {
                    setFormData(prev => {
                      return {
                        ...prev, goal_amount : e.target.value
                      }
                    })
                  }}
                />
              </MDBox>
              <MDTypography
                variant="overline"
                fontWeight="medium"
                color="error"
              >
                {campaignFormValidation.goalAmtValidation}
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">Select completion date: </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="date"
                  label=""
                  variant="standard"
                  fullWidth
                  // value={new FormatTime(new Date(formData.completionDate)).getFormatedDate()}
                  onChange = { (e) => {
                    setFormData(prev => {
                      return {
                        ...prev, completion_date : e.target.value
                      }
                    })
                  }}              
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  UPDATE
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
          </>
        </AppModal>
      )}
        
    </div>
  )
}

export default EditCampaign