import { Avatar, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PhoneAPI } from "services/PhoneAPI";
import AppModal from "ui/AppModal";
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from "ui/Select";
import { ROLE } from "constants/AppConstants";
import { CompanyAPI } from "services/CompanyAPI";

const User = ({ image, name, email, children }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1} height="32px">
        {children}
        <Avatar alt={name} src={image} name={name} size="sm" sx={{ width: '24px', height: '24px' }} />
        <MDBox lineHeight={1} heigh="32px">
            <MDTypography sx={{ fontSize: '14px' }} display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
const isAssigned = (arr, phone) => {
    return Boolean(arr?.find(itm => itm.phone === phone));
}

const formatUser = (user, tmpMap, phone, onAssignCheckHandler, unikey) => {
    return (
        <MDBox key={'wrapper_' + unikey} display="flex" flexDirection="row" width="100%" justifyContent="start" alignItems="center">
            <FormGroup sx={{ margin: 0, padding: 0, height: 32, width: 24 }}>
                <FormControlLabel control={<Checkbox key={'checkbox' + user.userId} checked={isAssigned(tmpMap[user.userId], phone)} onChange={(e) => {
                    onAssignCheckHandler(user, tmpMap, phone, e.target.checked);
                }} sx={{ color: '#1A73E8', margin: 0, padding: 0, height: '40px', width: '40px' }} />} label="" />
            </FormGroup>
            <User
                key={unikey}
                image={user.profilePic}
                name={user.fullName}
                // email={user.roles[0].name}
                email={user.email}
            />
        </MDBox>
    )
}
const AssignPhoneV2 = ({ onClose }) => {

    
    const loggedInUser = useSelector(state => state.user);
    const [usersPhoneMapped, setUsersPhoneMapped] = useState({});
    
    const [companies,setCompanies] = useState([]);
    
    const [currentCompany,setCurrentCompany] = useState(loggedInUser?.company?.companyId);

    const [showSaveBtn, setShowSaveBtn] = useState(false);

    const [phones, setPhones] = useState();
    const [users, setUsers] = useState();
    const [cols, setCols] = useState();
    const [oldCols, setOldCols] = useState([]);
    const [rows, setRows] = useState();
    const [oldUsers, setOldUsers] = useState();

    const saveAssignment = useCallback(() => {
        PhoneAPI.saveAssignedPhone({ payload: usersPhoneMapped, token: loggedInUser.token }).then(
            res => {
                toast.success("Updated successfully");
                setShowSaveBtn(false);
            }
        ).catch(e => {
            const msg = e?.response?.data?.message;
            toast.error(msg);
        })
    }, [usersPhoneMapped]);

    const onAssignCheckHandler = (user, tmpMap, phone, checked) => {

        if (!showSaveBtn) {
            setShowSaveBtn(true);
        }

        const oldMap = tmpMap[user.userId];
        const filteredMap = oldMap.filter(itm => !(itm.phone === phone));
        if (checked) {
            const p = phones.find(pitm => pitm.phone === phone);
            filteredMap.push({
                phone: p.phone,
                phoneId: p.phoneId
            })
        }
        const ky = user.userId;
        const newMap = {
            ...tmpMap,
            [ky]: filteredMap
        }
        setUsersPhoneMapped(newMap);
    }

    const handleSearch = useCallback((e) => {
        if (!e.target.value || e.target.value == "") {
            setUsers(oldUsers);
            setCols(oldCols);
            return;
        }
        if (!isNaN(e.target.value)) {
            const newCols = oldCols.filter(itm => itm.key.includes(e.target.value));
            setCols(newCols);
        } else {
            const newUsers = oldUsers.filter(itm => itm.email.toLowerCase().includes(e.target.value.toLowerCase()) || itm.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
            setUsers(newUsers)
        }
    }, [oldCols, oldUsers])

    useEffect(() => {
        if (users && cols) {
            const tr = [];
            let rowIdx = 0;
            for (const u of users) {
                let colIdx = 0;
                const obj = {};
                for (const ky of cols) {
                    obj[ky.key] = formatUser(u, usersPhoneMapped, ky.key, onAssignCheckHandler, 'user_' + rowIdx + colIdx);
                    colIdx++;
                }
                tr.push(obj);
                rowIdx++;
            }
            setRows(tr);
        }
    }, [cols, users, usersPhoneMapped]);

    useEffect(() => {
        if (loggedInUser) {
            PhoneAPI.getPhoneMapped({ companyId: currentCompany, token: loggedInUser.token, userId: loggedInUser.id }).then(res => {
                const tcols = res?.data?.phones?.map(itm => {
                    return {
                        label: itm.phone,
                        id: itm.phoneId,
                        key: itm.phone,
                    }
                });
                setPhones(res?.data?.phones);
                // Phone and user MAPPING....
                const tmpMap = {};
                for (const m of res.data.users) {
                    const tmpPhoneArr = [];
                    for (const p of m.phones) {
                        tmpPhoneArr.push({ phone: p.phone, phoneId: p.phoneId });
                    }
                    tmpMap[m.userId] = tmpPhoneArr;
                }
                setUsersPhoneMapped(tmpMap);
                setUsers(res?.data?.users);
                setOldUsers(res?.data?.users);
                setCols(tcols);
                setOldCols(tcols);
            }).catch(e => console.log(e));
        }
    }, [loggedInUser,currentCompany]);

    useEffect(() => {
        if(loggedInUser){
            //get companies as well 
            CompanyAPI.getUserCompany({token : loggedInUser.token,userId : loggedInUser.id}).then(res => {
                setCompanies(res.data);
            }).catch(e => {});
        }
    },[loggedInUser])
    
    return (
        <>
            <AppModal
                heading={"Assign users to phone number"}
                onModalClose={onClose}
                rootStyle={
                    {
                        overflow: 'hidden',
                        "&.MuiModal-root": {
                            width: '98%',
                            left: '2%'
                        }
                    }
                }
                customStyle={{
                    width: "100%",
                    height: "100%",
                }}
            >
                {!cols || !rows ?
                    <MDBox display="flex" sx={{ overflow: 'hidden', height: "100%", width: "100%" }} justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </MDBox>
                    :
                    <>
                        <MDBox width="100%" height="48px" justifyContent="flex-end" display="flex">
                            {loggedInUser.role === ROLE.SUPER_ADMIN && currentCompany && <CustomSelect sx={{
                                width: '215px',
                                height: '36px'
                            }}
                            value={currentCompany}
                            onChange={(e) => setCurrentCompany(e.target.value)}
                            >
                                {console.log({currentCompany})}
                                {companies.map(itm => <MenuItem key={itm.companyId} value={itm.companyId}>{itm.companyName}</MenuItem>)}
                            </CustomSelect>}
                            <TextField
                                placeholder="Search"
                                sx={{ width: '260px', padding: '8px' }}
                                onChange={handleSearch}
                                variant="standard"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search"
                                                edge="end"
                                            >
                                                {<SearchIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                }}

                            />
                        </MDBox>
                        <MDBox display="flex" maxHeight="100%" sx={{ overflow: 'auto', height: 'calc(100vh - 160px)' }} id="phone_map_container">
                            <EnhancedTable showPagination={false} showFilter={false} selectEnabled={false} rows={rows} columns={cols} />
                        </MDBox>
                        <MDBox display="flex" justifyContent="flex-end" height="48px">
                            {showSaveBtn && <Button
                                onClick={saveAssignment}
                                variant="contained"
                                sx={{
                                    borderRadius: "0px",
                                    color: "white!important",
                                    border: "unset",
                                    marginLeft: "12px",
                                    height: "32px",
                                    minHeight: "unset",
                                }}
                            >{'save'}</Button>}
                        </MDBox>
                    </>}
            </AppModal>
        </>
    )
}
export default AssignPhoneV2;