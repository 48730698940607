import { Card, Grid } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import DataTable from "lib/layouts/Tables/DataTable";

const Table = ({heading,columns,rows,canSearch,showBanner = true,children}) => {
    return (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {showBanner && <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    {heading}
                  </MDTypography>
                </MDBox>}
                <MDBox pt={showBanner ? 3 : 0}>
                  {children}
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
                    showTotalEntries={true}
                    noEndBorder
                    canSearch={canSearch}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
    )
}
export default Table;