import React from "react";
import { useEffect, useState, useRef } from "react";

import MDBox from "lib/components/MDBox";

import {Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip, Typography} from "@mui/material";
import CampaignMessage from "./CampaignMessage";
import MessageTable from "./MessageTable";
import { toast } from "react-toastify";
import { giveUIAccessBulk } from "api/campaign";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import AddButton from "ui/AddButton";
import { Info } from "@mui/icons-material";
import { NOTIFICATION_MEDIUM } from "constants/AppConstants";
import AppConstants from "constants/AppConstants";

const CREATE_TYPE = {
  PARTICULAR_AMOUNT: "particular amount",
};

const CreateCampaignMessage = ({campaignId,onMessageCreated}) => {
  const [action, setAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAction(CREATE_TYPE.PARTICULAR_AMOUNT)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MDBox
        sx={{}}
        mt={1}
        ml={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <AddButton onClick={handleClick} text={"Add message"}/>
      </MDBox>
     
      {action === CREATE_TYPE.PARTICULAR_AMOUNT && (
        <CampaignMessage  campaignId={campaignId} onChange={() => setAction("")} onMessageCreated={onMessageCreated} />
      )}
    </>
  );
};


export default function SettingTable({campaign}) {
  const [settings, setSettings] = useState();
  const [messages, setMessages] = useState([]);
  const token = useSelector(state => state.user.token);

  const {sendRequest : giveUIAccessFn, status : uiAccessStatus, data : uiAccessData,error : uiAccessErr} = useHttp(giveUIAccessBulk,false);

  const onNoticationOptionChange = (e) => {
      if(e.target.checked){
        setSettings(prev => {
          return {
            ...prev,
            notification_medium : NOTIFICATION_MEDIUM.SMS
          }
        })
      }
      else{
        setSettings(prev => {
          return {
            ...prev,
            notification_medium : NOTIFICATION_MEDIUM.NONE
          }
        })
      }
  }

  const onMediumSelected = (e) => {
    setSettings(prev => {
      return {
        ...prev,
        notification_medium : e.target.value
      }
    })
  }

  const onUIAccessCheck = (e) => {
    const isBulkUIAccessPossible = campaign?.fundraisers?.find(item => item?.fundraiser_email === null || item?.fundraiser_email.trim().length === 0);
    if(isBulkUIAccessPossible){
        toast.error("Bulk Access is not possible as all fundraisers does not have valid email address.");
        return;
    }
    else{
        setSettings(prev => {
          return {
            ...prev,
            ui_access : e.target.checked
          }
        });
      giveUIAccessFn({token, uiAccess: e.target.checked,campaign_id : campaign?.campaign_id});
    }
  }
  
  useEffect(() => {
    if(campaign?.settings){
      const s = campaign?.settings ? campaign.settings: {};
      setSettings(s);
    }
    if(campaign?.messages){
      setMessages(campaign.messages);
    }
  },[campaign]);

  useEffect(() => {
    if(uiAccessStatus === 'completed'){
      if(uiAccessData){
        if(uiAccessData.status === 'success'){
          toast.success(uiAccessData.message);
          setSettings((prev) => {
            return {
              ...prev,
              ui_access: true,
            };
          });
        }
        else{
          toast.error("UI access not given.");
          setSettings((prev) => {
            return {
              ...prev,
              ui_access: false,
            };
          });
        }
      }
      else if(uiAccessErr){
        setSettings((prev) => {
          return {
            ...prev,
            ui_access: false,
          };
        });
        toast.error(AppConstants.REQUEST_FAIL);
      }
    }
  },[uiAccessStatus,uiAccessData,uiAccessErr])

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          value={Boolean(settings?.ui_access)}
          checked={Boolean(settings?.ui_access)}
          onChange={onUIAccessCheck}
          style={{
            height: "24px",
            width: "24px",
            marginRight: "20px",
            borderRadius: "15px",
            marginBottom: "25px",
          }}
          type="checkbox"
          id="UI"
        />
        <label
          style={{
            fontSize: "20px",
          }}
          htmlFor="UI"
        >
          <Typography variant="subtitle1"> UI Access{" "}
            <Tooltip title={"UI access will enable the fundraiser to login"}>
              <Info sx={{cursor:'pointer'}}/>
            </Tooltip>
          </Typography>
        </label>
        <br></br>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          defaultChecked={
            settings?.notification_medium === NOTIFICATION_MEDIUM.NONE
          }
          value={NOTIFICATION_MEDIUM.ALL}
          onChange={onNoticationOptionChange}
          style={{
            height: "24px",
            width: "24px",
            marginRight: "20px",
            borderRadius: "15px",
            marginBottom: "25px",
          }}
          type="checkbox"
          id="fund"
        />
        <label style={{ fontSize: "20px" }} htmlFor="fund">
        <Typography variant="subtitle1">{" "}
          Set the default notification type here. You can customize each fundraiser’s notifications under his settings
        </Typography>
        </label>
        <br></br>
      </div>
      {settings?.notification_medium !== NOTIFICATION_MEDIUM.NONE && (
        <MDBox display="flex">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Notification Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={settings?.notification_medium}
              onChange={onMediumSelected}
            >
              <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
              <FormControlLabel
                value="EMAIL"
                control={<Radio />}
                label="EMAIL"
              />
              <FormControlLabel value="ALL" control={<Radio />} label="BOTH" />
            </RadioGroup>
          </FormControl>
        </MDBox>
      )}
      <Divider
        component="div"
        sx={{
          "&.MuiDivider-root": {
            backgroundColor: "#e9d5e9",
          },
        }}
      />
      <div
        style={{
          marginTop: "20px",
          marginLeft: "16px",
        }}
      >
        <MDBox display="flex" justifyContent="flex-end" alignItems="center">
          <CreateCampaignMessage
            campaignId={campaign?.campaign_id}
            onMessageCreated={(res) => {
              setMessages((prev) => [res, ...prev]);
            }}
          />
        </MDBox>
      </div>
      <MessageTable messages={messages} campaignId={campaign?.campaign_id} />
    </>
  );
}
