import { AddPhoneontainer } from "pages/Chat/ChatStyles";
import { SearchInput } from "pages/Chat/ChatStyles";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { chatVariablesAction } from "store/chat-variables-slice";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { VIEWS } from "constants/ChatConstants";
import { chatAction } from "store/chat-slice";
import { memo, useCallback, useEffect, useRef } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";

const RecipientSearch = () => {
    
    const searchRef = useRef();

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const dispatch = useDispatch();
    const currenActivePhone = useSelector(state => state.chat.current_active_phone);
    const currentActiveView = useSelector(state => state.chat.current_active_view);
    const isMergeView = useSelector(state => state.app.mergeViewActive);
    const companyId = useSelector(state => state.user.company.companyId);
    const searchActive = useSelector(state => state.chatVariables.recipientSearchState);
    const onInputChangeHandler = (e) => {
        const value = e.target.value;
        const sanitizedVal = value.replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,""); 
        const phoneNumber = sanitizedVal.substring(sanitizedVal.length - 10);
        let countryCode = "1"; 
        if(phoneNumber?.length > 10){
            countryCode = sanitizedVal.substring(0,sanitizedVal.length - 10);
        }
        const searchState = {
            search: value?.trim().length > 0,
            pattern: phoneNumber,
        };
        if(searchState.pattern.trim().length === 0 ){
            const searchState = {
                search: false,
                pattern: "",
            };
            dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
            dispatch(chatVariablesAction.recipientSearch(searchState));
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
        }
        else{
            dispatch(chatVariablesAction.recipientSearch(searchState));
            dispatch(chatAction.setCurrentActiveView(VIEWS.SEARCH_RECIPIENT_VIEW));
            dispatch(chatAction.setCurrentActiveRecipient(undefined));
            dispatch(chatVariablesAction.setRecipientContainerLoader(true));
        }
    }
    useDebouncedWatch(() => {
        if(searchActive.search){
            const pattern = searchActive.pattern.replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\s\{\}\[\]\\\/]/gi,"");
            searchContact(pattern);
        }
        else{
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            if(isMergeView){
                dispatch(chatAction.setCurrentActiveView(VIEWS.MERGED_VIEW));
            }
            else{
                if(!currentActiveView)
                    dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
            }
        }
    },[searchActive,isMergeView,currentActiveView]);
    
    const searchContact = useCallback((pattern) => {
        RecipientAPI.searchContactedRecipient({
            phoneId : currenActivePhone.phoneId,
            searchPattern : pattern,
            token,
            userId,
            companyId
        }).then(res => {
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
            if(res.data){
                const payload = {
                    view : currentActiveView,
                    recipients : res.data.recipients,
                    currentPage : res.data.currentPage,
                    totalPages : res.data.totalPages,
                    phoneId : res.data.phoneId
                }
                if(res.data.currentPage === 0){
                    dispatch(chatAction.setPhoneRecipients(payload));
                }
                else{
                    /// Add more recipient.....
                    dispatch(chatAction.concatRecipients(payload));
                }
            }
        }).catch(e => {
            console.log(e);
            dispatch(chatVariablesAction.setRecipientContainerLoader(false));
        })
    },[currentActiveView,currenActivePhone,token,companyId]);

    useEffect(() => {
        setTimeout(() => {
            if(window.innerWidth > 768){
                searchRef?.current?.focus();
            }     
        },[500])
    },[])
    return (
        <>
            <AddPhoneontainer>
                <SearchInput ref={searchRef} value={searchActive.pattern} onChange={onInputChangeHandler} placeholder="Search or start a new chat"/>
            </AddPhoneontainer>
            <SearchIcon
                fontSize="medium"
                className="navbar-icon icon-inside-search"
            />
            {(searchActive.search || currentActiveView === VIEWS.SEARCH_RECIPIENT_VIEW) && <CloseIcon sx={{cursor:'pointer'}}
                onClick={() => {
                    const searchState = {
                        search: false,
                        pattern: "",
                    };
                    dispatch(chatVariablesAction.recipientSearch(searchState));
                    if(!isMergeView) {
                        dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
                    }
                }}
                fontSize="medium"
                className="navbar-icon cross-inside-search"
            />}
        </>
    )
}
export default memo(RecipientSearch);