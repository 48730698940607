import { Avatar, Button, Chip, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import IUser from "../IUser";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { ChatAPI } from "services/IChat/ChatAPI";
import { toast } from "react-toastify";
import { filterUser } from "util/IChat/IChatUtil";
import { useEffect } from "react";
import { useCallback } from "react";
import { formatGroupChat } from "util/IChat/IChatUtil";
import { IGroupAction } from "store/IChat/igroup-slice";

const IAddParticipant = ({group,onParticipantsAdded}) => {

    const dispatch = useDispatch();

    const [allUsers,setAllUsers] = useState([]);

    const [users,setUsers] = useState([]);
    const currUser = useSelector(state => state.user);
    const [selectedUsers,setSelectedUsers] = useState([]);

    const handleSearch = useCallback((e) => {
        const filteredUsers = filterUser(allUsers,e.target.value);
        setUsers(filteredUsers);
    },[allUsers])

    const addParticipants = useCallback(() => {
        const ids = selectedUsers.map(itm => itm.userId);
        const payload = {
            chatId : group.chatId,
            participants : ids
        }
        ChatAPI.addParticipants({payload,token : currUser.token}).then(res => {
            const formatChat = formatGroupChat(res.data,currUser.id);
            dispatch(IGroupAction.updateGroup({chatId : res.data.chatId, currGroup : formatChat}));
            onParticipantsAdded(formatChat);
        }).catch(e => {
            toast.error(e);
            onParticipantsAdded();
        });
    },[selectedUsers,currUser]);

    const onSelected = (res) => {
        if(res.checked) {
            setSelectedUsers(prev => [...prev,res.user]);
        }
        else{
            const newSelection = selectedUsers.filter(item => item.userId !== res.user.userId);
            setSelectedUsers(newSelection);
        }
    }

    useEffect(() => {
        if(group) {
            const ids = group?.participants?.map(itm => itm.userId);
            ids.push(currUser.id);
            const payload = {
                ids : ids
            }
            ChatAPI.getUsersExceptGivenIds({token : currUser.token, companyId : currUser.company.companyId,payload})
                .then(res => {
                    setAllUsers(res.data);
                    setUsers(res.data);
                }).catch(e => {
                    toast.error(e);
                });
        }
    },[group,currUser]);

    return(
        <>
            <MDBox display="flex" flexDirection="column"> 
                {selectedUsers?.length > 0 && <Typography fontSize={"14px"} sx={{marginBottom:'4px'}} color={"blue"}>{"selected Participants"}</Typography>}
                <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                    {selectedUsers && 
                        selectedUsers.map(chip => {
                            return(
                                <Chip
                                    key={"chip_" + chip?.userId}
                                    avatar={<Avatar alt={chip.fullName} src={chip.profilePic} />}
                                    label={chip.fullName}
                                    variant="outlined"
                                    // onDelete={() => removeUser(chip)}
                                    sx={{margin:'4px'}}
                                />
                            )
                        })
                    }
                    <MDBox width="100%">
                        <TextField
                            placeholder="Search"
                            sx={{width:'100%',padding:'8px'}}
                            onChange={handleSearch}
                            variant="standard"
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                    >
                                        {<SearchIcon/>}
                                    </IconButton>
                              </InputAdornment>
                            }}
                            
                        />
                    </MDBox>        
                </Stack>
            </MDBox>        
            <MDBox minHeight='300px' maxHeight='432px' sx={{overflowY:'auto',margin:'12px'}}>
                {users.map(u => <IUser key={u.userId} onSelected={onSelected} user={u}/>)}  
            </MDBox>
            <MDBox display="flex" width="100%" alignItems="center" justifyContent="flex-end">
                <Button
                    onClick={addParticipants}
                    disabled={selectedUsers.length === 0}
                    sx={{
                        // borderRadius: "20px",
                        color: "white!important",
                        border: "unset",
                        width: "96px",
                        padding: "10px",
                        height: "35px",
                        backgroundColor: "#1876F2!important",
                    }}
                    variant="contained"
                    size="medium"
                    endIcon={<Add/>}
                >
                    Add
                </Button>
            </MDBox>        
        </>
    )
}
export default IAddParticipant;