import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import { useCallback, useEffect, useRef, useState } from "react";
import AddButton from "ui/AddButton";
import AppModal from "ui/AppModal";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import CampaignList from "../../components/Campaign/CampaignList";

import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import CreateCampaignForm from "components/Campaign/CreateCampaignForm";
import { getCampaignList } from "api/campaign";
import { Paper, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { Print } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import AppConstants from "constants/AppConstants";
import MDTypography from "lib/components/MDTypography";
const Campaign = () => {

  const campaignRef = useRef();
  const token = useSelector(state => state.user.token);
  const company = useSelector(state => state.user.company);
  const userId = useSelector(state => state.user.id);
  const [isNewCampaign, setIsNewCampaign] = useState(false);
  const {sendRequest : getCampaignListFn, status : campaignDataStatus, data : campaignListData} = useHttp(getCampaignList, true);
  const [campaignList, setCampaignList] = useState([]);
  const [isPrinting,setIsPrinting] = useState(false);

  const onNewCampaignCreated = useCallback((res) => {
    
    setIsNewCampaign(false);
    if(res){
      setCampaignList(prev => [res, ...prev]);
    }
  },[campaignList]);

  const printCampaignList = useReactToPrint({
    content: () => campaignRef.current,
    onBeforeGetContent : () => setIsPrinting(true),
    onAfterPrint: () => setIsPrinting(false),
  });

  //// retrieve users
  useEffect(() => {
    if(company != null && company){
      getCampaignListFn({token,companyId : company?.companyId,userId});
    }
  },[company])

  useEffect(() => {
    if(campaignDataStatus === 'completed'){
      if(campaignListData){
        setCampaignList(campaignListData);
      }
    }
  },[campaignDataStatus,campaignListData])

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      {
        company != null && company ? 
          <>
            <AddButton
              onClick={() => {
                setIsNewCampaign(true);
              }}
              text={"Create campaign"}
              customStyle={
                {
                  marginBottom:'12px!important'
                }
              }
            />
            <Paper>
              <MDBox width="100%" display="flex" flexDirection="row" sx={{marginBottom : '8px',padding:'12px'}} justifyContent="flex-end">
                <Tooltip title="Print the progress">
                  <Print sx={{cursor:'pointer'}} onClick={printCampaignList}/>
                </Tooltip>
              </MDBox>
              {campaignDataStatus === 'completed' && <MDBox ref={campaignRef}>
                <CampaignList isPrinting={isPrinting}  campaignList={campaignList} setCampaignList={setCampaignList} />
              </MDBox>}
            </Paper>  
          </>
        :
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography color={"info"}>
            {AppConstants.ACTION_NOT_ALLOWED}
          </MDTypography>
        </MDBox>
      }
      {isNewCampaign && (
        <AppModal
          heading={"Create campaign "}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle={{ 
              width: "100%",
              height: "100%",
          }}          
          onModalClose={() => {
            setIsNewCampaign(false);
          }}
        >
          <CreateCampaignForm onCreate={onNewCampaignCreated} />
        </AppModal>
      )}
    </DashboardLayout>
  );
};
export default Campaign;
