import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { PhoneAPI } from "services/PhoneAPI";
import CustomSelect from "ui/Select";

const UserPhoneList = ({customStyle={},onPhoneSelected,disabled = false,nativeFetch = true,phones = undefined, selectPhone = {}}) => {

    const [currentSelectedPhone,setCurrentSelectedPhone] = useState({});
    const [phoneList,setPhoneList] = useState([]);

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);
    const companyId = useSelector(state => state.user.company.companyId);

    const onPhoneChange = (e) => {
        if(e.target.value) {
            setCurrentSelectedPhone(e.target.value);
            onPhoneSelected(e.target.value)
        }
    }

    useEffect(() => {
        if(!nativeFetch){
            setPhoneList(phones);
        }
    },[nativeFetch,phones])

    useEffect(() => {
        if(token && userId && companyId && nativeFetch){
            const payload = {
                companyId : companyId,
                userId : userId
            }
            PhoneAPI.getPhoneList({token,payload}).then(res => {
                setPhoneList(res.data);
            }).catch(e => {});
        }
    },[token,userId,companyId,nativeFetch]);

    return(
        <MDBox sx={{...customStyle}}>
          <FormControl fullWidth sx={{marginRight:'8px'}}>
            <InputLabel id="phone-label">{disabled ? "Currently applied to all company numbers" : "Choose number"}</InputLabel>
            <CustomSelect
                sx={{height:'42px'}}
                disabled={disabled}
                labelId="phone-label"
                id="choose-phone"
                value={currentSelectedPhone}
                label={disabled ? "Currently applied to all company numbers" : "Choose number"}
                onChange={onPhoneChange}
            >
                { phoneList ? phoneList?.map(item => <MenuItem key={item.phoneId} value={item}>{item.phone}</MenuItem>) : <MenuItem>No phone available</MenuItem>}
            </CustomSelect>                    
          </FormControl>
        </MDBox>        
    )
}
export default React.memo(UserPhoneList);