import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";
import { API_V1_SMS } from "constants/Endpoints";

export const PhoneAPI = {

    getPhoneList : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/phone/company`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
    },

    uploadWhatsAppBridgeNumbers : async({formData,token}) => {
        return await axios.post(`${BASE_URL}/phone/pool/whatsapp`,formData,{
            headers : {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + token
            }
        });
    },
    getBridgeNumbers : async({token}) => {
        return await axios.get(`${BASE_URL}/phone/bridge`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    getPhoneMapped : async({userId,companyId,token}) => {
        return await axios.get(`${BASE_URL}/phone/assign/user/${userId}/${companyId}`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    saveAssignedPhone : async({payload,token}) => {
        return await axios.post(`${BASE_URL}/phone/assign/user`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    deletePhone : ({phoneId,token}) => {
        return axios.delete(`${BASE_URL}/phone/${phoneId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    deleteGroup : async({groupId,token,phoneId}) => {
        return await axios.get(`${BASE_URL}${API_V1_SMS}/delete/group/${phoneId}/${groupId}`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    getUserPhones : async({companyId,userId,token}) => {
        return await axios.get(`${BASE_URL}/phone/company/${companyId}/${userId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
    updateCellConfig : async({phoneId,payload,token}) => {
        return await axios.post(`${BASE_URL}/phone/${phoneId}/cell-config`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    }, 
}