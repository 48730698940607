import { Button, InputBase, Stack } from "@mui/material";
import { ChatIcons } from "pages/Chat/ChatStyles";
import { ChatEditorWrapper } from "pages/Chat/ChatStyles";
import { CustomChatEditor } from "pages/Chat/ChatStyles";
import { ChatFooter } from "pages/Chat/ChatStyles";
import { ChatBottomPanel } from "pages/Chat/ChatStyles";
import { Send } from "@mui/icons-material";
import { FlexContainer } from "pages/Chat/ChatStyles";
import { MESSAGE_STATUS } from "constants/ChatConstants";
import IMessageIconsFunctionality from "./IMessageIconsFunctionality";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState, useRef } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";
import InputImage from "./InputImage";
import { v4 as uuidv4 } from 'uuid';
import React from "react";
import Audio from "components/Audio/Audio";
import { draftsMessageAction } from "store/draft-message-slice";
import { getBlobFromUrl } from "util/MediaUtil";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import MDTypography from "lib/components/MDTypography";

const IChatFooter = ({ onMessageSend, activeChat, onTyping, messageState = {}, setMessageState = () => { } }) => {
    const dispatch = useDispatch();
    const messageInputRef = useRef();
    const userId = useSelector(state => state.user.id);
    const companyId = useSelector(state => state.user.company.companyId);
    const [audioMessage, setAudioMessage] = useState(false);
    const draftsMessage = useSelector(
        (state) => state.draftsMessage?.draftsMessage
    );
    const onEnterPressToSendMessage = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            send();
        }
    };

    const getParticipants = useCallback(() => {
        const participants = activeChat.participants.map(item => {
            return {
                userId: item.userId
            }
        });
        participants.push({ userId: userId });
        return participants;
    }, [activeChat, userId]);

    useEffect(() => {
        const cFocus = async() => {
            if (activeChat) {
                setMessageState({
                    message: draftsMessage[activeChat?.chatId]?.message
                        ? draftsMessage[activeChat?.chatId]?.message
                        : "",
                    attachement: draftsMessage[activeChat?.chatId]?.attachement
                        ? await getBlobFromUrl(draftsMessage[activeChat?.chatId]?.attachement)
                        : undefined,
                });
                if (window.innerWidth > 768) {
                    messageInputRef.current?.focus()
                }
            }
        }
        cFocus();
    }, [activeChat]);

    const send = useCallback(() => {
        if (!messageState?.message || messageState?.message?.trim().length === 0) return;
        const id = uuidv4();
        const participants = getParticipants();
        const payload = {
            text: messageState.message,
            type: 'text',
            tempMessageId: id,
            status: MESSAGE_STATUS.PENDING,
            newChat: activeChat.newChat,
            chatId: activeChat.chatId,
            tempChatId: activeChat.chatId,
            senderId: userId,
            companyId: companyId,
            groupChat: activeChat.groupChat,
            createdAt: new Date(),
            participants: participants
        }
        onMessageSend(payload);
        setMessageState({ message: '' })
    }, [messageState, activeChat, onMessageSend])

    useDebouncedWatch(() => {
        if (messageState?.message?.length > 0) {
            onTyping('START_TYPING');
        }
        else {
            onTyping()
        }
    }, [messageState?.message, onTyping]);

    return (
        <ChatBottomPanel>
            <InputImage messageState={messageState} setMessageState={setMessageState} />
            <ChatFooter>
                {!audioMessage ?
                    <ChatEditorWrapper>
                        <CustomChatEditor>
                            {activeChat?.iEvent && activeChat?.iEvent.type === WEBSOCKET_EVENT_TYPE.USER_TYPING &&
                                <MDTypography sx={{ position: 'absolute', top: 0, width: "100%", textAlign: 'center', zIndex: 99999 }} fontSize={"14px"} color={"info"}>
                                    {`Typing...`}
                                </MDTypography>}
                            <InputBase
                                multiline
                                onKeyDown={onEnterPressToSendMessage}
                                onPaste={(e) => {
                                    const clipboardItems = e.clipboardData.items;
                                    const items = [].slice
                                        .call(clipboardItems)
                                        .filter(function (item) {
                                            return /^image\//.test(item.type);
                                        });
                                    if (items.length === 0) {
                                        return;
                                    }

                                    const item = items[0];
                                    const blob = item.getAsFile();
                                    setMessageState((prev) => {
                                        return {
                                            ...prev,
                                            attachement: blob,
                                        };
                                    });
                                    e.preventDefault();
                                }}
                                onChange={(e) => {
                                    setMessageState(prev => {
                                        return {
                                            ...prev, message: e.target.value
                                        }
                                    });
                                    if (draftsMessage[`${activeChat?.chatId}`]?.message?.length > 0 ||
                                        draftsMessage[`${activeChat?.chatId}`]
                                            ?.attachement) {
                                        dispatch(
                                            draftsMessageAction.setDraftsMessage({
                                                ...draftsMessage,
                                                [`${activeChat?.chatId}`]: {
                                                    message: "",
                                                    attachement: undefined,
                                                },
                                            })
                                        );
                                    }
                                }}
                                inputRef={messageInputRef}
                                style={{
                                    fontSize: "16px",
                                    color: "#555",
                                    lineHeight: "18px",
                                    width: "100%",
                                }}
                                autoFocus
                                variant="standard"
                                value={messageState?.message}
                            />
                        </CustomChatEditor>
                        <ChatIcons>
                            <IMessageIconsFunctionality messageState={messageState} setMessageState={setMessageState} />
                        </ChatIcons>
                    </ChatEditorWrapper>
                    : <Audio setAudioMessage={setAudioMessage} />
                }
                <FlexContainer alignItems="center" justifyContent="flex-start" className="chat-footer">
                    <Stack direction="row" spacing={0.1}>
                        <Button
                            onClick={send}
                            sx={{
                                borderRadius: "20px",
                                color: "white!important",
                                border: "unset",
                                width: "96px",
                                padding: "10px",
                                height: "35px",
                                backgroundColor: "#1876F2!important",
                            }}
                            variant="contained"
                            size="medium"
                            endIcon={<Send />}
                            disabled={messageState.message?.trim().length === 0 && !messageState.attachement}
                        >
                            Send
                        </Button>
                    </Stack>
                </FlexContainer>
            </ChatFooter>
        </ChatBottomPanel>
    )
}
export default IChatFooter;