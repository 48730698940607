import { carrierColums } from "api/userTableData";
import useHttp from "hooks/use-http";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import Table from "ui/Table";
import { FormatTime } from "util/FormatTime";
import CarrierAction from "./CarrierAction";
import { getProperDate } from "util/UtilityFunctions";
const CarrierList = ({data}) => {

    const [carriers, setCarriers] = useState([]);

    const onEdited = (editData) => {
        const updatedCarrier = carriers.map(c => {
            if(c.id === editData.id){
                return editData;
            }
            else return c;
        })
        setCarriers(updatedCarrier);
    }

    const [rows,setRows] = useState([]) 
    
    const formatRows = (res) => {
        const newRows = res?.map((c,i) => {
            const r = {
                sno : i + 1,
                name : c?.carrier_name,
                created_at : (
                    <MDTypography
                      component="a"
                      href="#"
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                    >
                        {getProperDate(c.createdAt,'L')}
                      {/* {new FormatTime(new Date()).getFormatedDate()} */}
                    </MDTypography>
                  ),
                  action : <CarrierAction carrier={c} onEdited = {onEdited}/>
            }
            return r;
        })
        return newRows;
    }

    useEffect(() => {
        if(carriers){
            const nr = formatRows(carriers);
            setRows(nr);
        }
    },[carriers]);

    useEffect(() => {
        if(data){
            setCarriers(data);
        }
    },[data])

    return (
        <>
            <Table
            heading={"Custom carriers"}
            columns={carrierColums}
            rows={rows}
            />
        </>
    );
}
export default CarrierList;