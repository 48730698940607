import { Button } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import {  useGoogleLogin } from "@react-oauth/google";
import { CompanyAPI } from "services/CompanyAPI";
import { useDispatch, useSelector } from "react-redux";
import AppConstants from "constants/AppConstants";
import { loaderAction } from "store/loader-slice";
import { toast } from "react-toastify";
import { memo, useCallback, useEffect, useState } from "react";
import { PhoneAPI } from "services/PhoneAPI";
import ContactSyncInfo from "./ContactSyncInfo";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import {ReactComponent as GoogleSvg} from "assets/svg/google_icon.svg";

/**
 * implicit flow
 * {
    "access_token": "ya29.a0AfB_byCuf0zUuwxSKhZRUppiAelolINKNervheZnAeDVb8A9cQS16dXKnc2Xcm2iWf_Hc9pjSZ7n1aqoSnJKudTu_UYJTWl-HqQxBT7ppFVDOF6HRVZ9BLzl8tQPE5qhQ8-bfPJuP40b46DbKUKatF6MkosiQgosTq0aCgYKAccSARESFQHGX2MidFpwG-msSzhpNi1HJRKV0g0170",
    "token_type": "Bearer",
    "expires_in": 3599,
    "scope": "email profile openid https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.profile",
    "authuser": "0",
    "prompt": "none"
}
Authorization flow
{
    "code": "4/0AeaYSHCagZQBHBl5uV7l659JWp6d7touHnUgy0Q0Rbpq85HqR_49ulhSu6LN4640BAaEZw",
    "scope": "email profile https://www.googleapis.com/auth/contacts openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    "authuser": "0",
    "prompt": "consent"
}

{
  "access_token": "ya29.a0Ad52N3_GyLtcS8ZtCRk25Pkhud2W8JfhVpJX94qP_wyl4U_dqxODG21vbbj2LKhmN691r0q09JIMYGpScYUjB4dYN4uzg4QhXSUco3Q7LTpc-pX09FwNK4JiMcmJCpgTmqD8nAQ2AUgtcGZuXMSJfn7xrB8aqwoZEi9WaCgYKAc0SARESFQHGX2Mim79JmVjgpp2GN43On4MfSQ0171",
  "expires_in": 3599,
  "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjZTExYWVjZjllYjE0MDI0YTQ0YmJmZDFiY2Y4YjMyYTEyMjg3ZmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI4MzIxMTM5MDk3MjUtaTNqdW85dGQyY2NydHRucWIwdmpqNWo2ZG4wcjFsczguYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI4MzIxMTM5MDk3MjUtaTNqdW85dGQyY2NydHRucWIwdmpqNWo2ZG4wcjFsczguYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDc2NzI2MDI5Mjk5NDk5MTA4MDEiLCJlbWFpbCI6ImsyY2FoZXJAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJiTTNhTWlNYXZUajRNZ2NUa0tMV2J3IiwibmFtZSI6IkdlbmMgVGVjaG5vbG9naWVzIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0lBelV1d1JBeE1JN1Zsd211aUtCWVVXdXZXclMtSTJaSHptOGpVQzkzQVVRcXdmZEdVPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkdlbmMiLCJmYW1pbHlfbmFtZSI6IlRlY2hub2xvZ2llcyIsImlhdCI6MTcxMzc4MjQzMSwiZXhwIjoxNzEzNzg2MDMxfQ.lqwPSLGVCdYPwYjj7j92c0RbMRDT1SoHNB1Nn4Ub6a_HwaRW8KkKiVy1h4D4l_u5A11JR2Hx2ZlzIwjpqKw6_rG9jRsCkDteu052TZPsou-bqVkL-PN00UOBAB_qfkboCgMO4QXGaUyvh0Fzrb4oGLeuZHwu260QrrA8WDAkGNu0a7dl1VShySvYLT-Ns4kz4DcCLme9StBSWaCaVoOxMbZfSOgLYpgF43sYTuxkg6R8LbKI3fBlXQ_JCgi_RI2RtZ2LhQ8-bgc5hX9X9ilHBEwyjUghgCUIOoZ-JJOift3XKX7AgWkVpwEOH1tMvPvom3u07HpjiHQL2WWbsLv7XQ",
  "refresh_token": "1//0g4TswjM4PUhBCgYIARAAGBASNwF-L9Ir4u3c14EZWy2pMxhFocBtugn34DAfzeKlOCOlS5IAyOYbIV81QFYclqJuKk8AorQFIgo",
  "scope": "https://www.googleapis.com/auth/contacts openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  "token_type": "Bearer"
}
 * 
 */
const SyncGoogleContacts = ({company}) => {

    const token = useSelector(state => state.user.token);
    const currentUser = useSelector(state => state.user); 
    const dispatch = useDispatch();
    const [userPhones,setUserPhones] = useState([]);

    const syncContacts = useCallback((phone) => {
        dispatch(loaderAction.show());
        RecipientAPI.syncContacts({token:currentUser.token,phoneId : phone.phoneId, companyId : currentUser.company.companyId})
        .then((res) => {
            const phoneSynced = res.data;
            toast.success(phoneSynced.phone + " is synced successfully.");
            console.log({phoneSynced})
            const newPhones = userPhones.map(itm => {
                if(itm.phoneId === phoneSynced.phoneId){
                    return {
                        ...itm,
                        syncedInfo : phoneSynced.syncedInfo
                    }
                }
                else return itm;
            })
            setUserPhones(newPhones);
            dispatch(loaderAction.hide());
        }).catch(e => {
            dispatch(loaderAction.hide());
            toast.error(e.response?.data?.message)
        });
    },[token,userPhones]);

    const syncWithGoogleContact = useGoogleLogin({
        flow: 'auth-code',
        scope : AppConstants.GOOGLE_CONTACTS_SCOPE,
        onSuccess: credentialResponse => {
            console.log(credentialResponse);
            const payload = {
                code :credentialResponse.code,
                company_id :company.companyId
            }
            dispatch(loaderAction.show());
            CompanyAPI.syncContacts({token,payload }).then(res => {
                console.log(res.data);
                toast.success("Successfully synced contacts from google.");
                dispatch(loaderAction.hide());
            }).catch(e => {
                console.log(e);
                toast.error(AppConstants.REQUEST_FAIL);
                dispatch(loaderAction.hide());
            });
        },
        onError : e => console.log(e)
    });
    
    useEffect(() => {
        if(currentUser && company){
            PhoneAPI.getUserPhones({token : currentUser.token, companyId : company?.companyId, userId : currentUser.id}).then(res => {
                setUserPhones(res.data);
            }).catch(e => {
            }) 
        }
    },[company,currentUser.id])

    return(
        <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox display="flex" width="100%" justifyContent="center" mt={2}>
                <MDTypography>Other settings</MDTypography>
            </MDBox>
            <MDBox width="100%" mt={2} display="flex" justifyContent="flex-start" flexDirection="column">
                <MDBox display="flex">
                    <Button 
                        onClick={() => syncWithGoogleContact()}
                        variant="outlined"
                        sx={{
                            borderRadius: "0px",
                            color: "black!important",
                            backgroundColor:'white!important',
                            height: "32px",
                            // minHeight: "unset",
                        }} 
                    >
                        {"Sync with Google Contacts"}
                        <GoogleSvg style={{width:'32px',height:'32px'}}/>
                    </Button>
                </MDBox>
            </MDBox>
            <MDBox display="flex">
                <MDBox display="flex" width="100%" justifyContent="center" flexDirection="column" mt={2}>
                    <MDTypography>Numbers to sync with Google Contacts</MDTypography>
                    {userPhones?.map((itm,indx) => <ContactSyncInfo key={indx} onSync={syncContacts} syncInfo={itm.syncedInfo} phone={itm}/>)}
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
export default memo(SyncGoogleContacts);