import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import { useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import ArrowAddBtn from "ui/ArrowAddBtn";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import UploadBulkNumber from "components/NumberPool/UploadBulkNumber";
import CreatePhone from "components/NumberPool/CreatePhone";
import MDBox from "lib/components/MDBox";
import {
  Button,
  Divider,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import PoolSettings from "components/NumberPool/PoolSettings";
import { getActivePhoneList } from "api/numberpool";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import PoolActions from "components/NumberPool/PoolActions";
import { deleteNumbers } from "api/numberpool";
import Swal from "sweetalert2";
import { unassignPhoneBulk } from "api/fundraiser";
import { toast } from "react-toastify";
import { getCompaniesList } from "api/company";
let CREATE_TYPE = {
  INDIVIDUAL: "individual",
  BULK: "bulk",
  NONE: "none",
  SETTINGS: "settings",
};
let columns = [
  {
    id: "1",
    key: "phone",
    label: "Phone",
  },
  {
    id: "2",
    key: "apiUsername",
    label: "User Name",
  },
  {
    id: "3",
    key: "apiKey",
    label: "Api Key",
  },
  {
    id: "4",
    key: "basicAuthHeader",
    label: "Auth",
  },
  {
    id: "5",
    key: "assigned_to",
    label: "Company",
  },
  {
    id: "6",
    key: "carrier_name",
    label: "Carrier",
  },
  {
    id: "7",
    key: "action",
    label: "Action",
  },
];
const Pool = () => {
  const token = useSelector((state) => state.user.token);
  const [activePhones, setActivePhones] = useState([]);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState({
    status: "ACTIVE",
    company : '',
  });
  const [action, setAction] = useState(CREATE_TYPE.NONE);

  const {
    sendRequest: getActivePhonesFn,
    data,
    status,
    error,
  } = useHttp(getActivePhoneList, false);
  const {
    sendRequest: deletePoolNumbersFn,
    data: deletedData,
    status: deletedStatus,
  } = useHttp(deleteNumbers, false);
  const {
    sendRequest: unassignBulkPhoneFn,
    data: unassignData,
    status: unassignStatus,
  } = useHttp(unassignPhoneBulk, false);
  const {
    sendRequest: getCompanyFn,
    data: fetchedCompany,
    status: fetchedCompanyStatus,
  } = useHttp(getCompaniesList, false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onDeleteClick = (res) => {
    const deletedData = {
      payload: res,
      token,
    };
    deletePoolNumbersFn(deletedData);
  };

  const onFilterApplied = () => {
    let filterString = `status=${currentFilter.status}`;
    if(currentFilter.company !== ''){
      filterString = `status=${currentFilter.status}&company=${currentFilter.company}`
    }
    getActivePhonesFn({ token, payload: filterString });
    handleClose();
  };
  const onUploaded = (res) => {
    if (res) {
      setActivePhones((prev) => [...res, ...prev]);
      setAction(CREATE_TYPE.NONE);
    }
  };

  const unassignNumber = (phones) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unassign it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const p = {
          token,
          payload: {
            numbers: phones,
          },
        };
        unassignBulkPhoneFn(p);
      }
    });
  };

  const onIndUnassign = (res) => {
    console.log({activePhones,res})
    const na = activePhones?.filter(p => !(res === p.phone));
    setActivePhones(na);
  }
  const onUploadCancel = () => {};

  useEffect(() => {
    let filterString = `status=${currentFilter.status}`;
    getActivePhonesFn({ token, payload : filterString });
    getCompanyFn(token);
  }, []);

  useEffect(() => {
    if (unassignStatus === "completed") {
      if (unassignData) {
        toast.success(unassignData.message);
        // update in use view
        const na = activePhones?.filter(p => !unassignData?.numbers?.includes(p.phone));
        setActivePhones(na);
      }
    }
  }, [unassignStatus, unassignData]);

  useEffect(() => {
    if (status === "completed") {
      if (data) {
        setAction("");
        setActivePhones(data);
      }
    }
  }, [data, error, status]);

  useEffect(() => {
    if (deletedStatus === "completed") {
      if (deletedData){
        let filterString = `status=${currentFilter.status}`;
        getActivePhonesFn({ token, payload: filterString });

      }
    }
  }, [deletedStatus, deletedData]);

  useEffect(() => {
    if (activePhones) {
      const newRows = activePhones?.map((item) => {
        return {
          ...item,
          id: item.phoneId,
          assigned_to: item?.company ? item.company.companyName : "Available",
          carrier_name: item?.carrierConf
            ? item.carrierConf.carrier_name
            : "Unknown",
          action: <PoolActions phone={item} onPhoneEdited={() => {}} onIndUnassign={onIndUnassign}/>,
        };
      });
      setRows(newRows);
    }
  }, [activePhones]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        sx={{ marginRight: "12px" }}
        display="flex"
        justifyContent="space-between"
      >
        <ArrowAddBtn
          text={"Add Number"}
          customStyle={{ marginBottom: "12px!important" }}
          menuItems={
            <>
              <NotificationItem
                onClick={() => setAction(CREATE_TYPE.INDIVIDUAL)}
                title={"Create"}
              ></NotificationItem>
              <NotificationItem
                onClick={() => setAction(CREATE_TYPE.BULK)}
                title={"Upload numbers"}
              ></NotificationItem>
            </>
          }
          onPopoverClose={() => setAction("")}
        />
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Paper
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "34px",
              width: "100px",
            }}
          >
            <Settings
              onClick={() => setAction(CREATE_TYPE.SETTINGS)}
              sx={{ cursor: "pointer" }}
            />
          </Paper>
        </MDBox>
      </MDBox>
      {/* { <NumberPool activePhones={activePhones}/>} */}
      <EnhancedTable
        onFilterClick={onFilterClick}
        rows={rows}
        heading={"Numbers"}
        columns={columns}
        selectEnabled={true}
        removeNumber={unassignNumber}
        onDeleteClick={onDeleteClick}
      />
      {action === CREATE_TYPE.INDIVIDUAL && (
        <AppModal 
          heading={"Create number"}
          onModalClose={() => setAction("")}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle={{ 
              width: "100%",
              height: "100%",
          }}                    
        >
          <CreatePhone
            onCreate={(res) => {
              if (res) {
                setAction("");
                setActivePhones((prev) => [res, ...prev]);
              }
            }}
            token={token}
          />
        </AppModal>
      )}
      {action === CREATE_TYPE.BULK && (
        <UploadBulkNumber onUploaded={onUploaded} onCancel={onUploadCancel} />
      )}
      {action === CREATE_TYPE.SETTINGS && (
        <PoolSettings onClose={() => setAction(CREATE_TYPE.NONE)} />
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "white!important",
            width: "400px",
          },
        }}
      >
        <MDBox display="flex" flexDirection="column" p={2}>
          <MDBox>
            <Typography>Status : </Typography>
            <Select
              value={currentFilter.status}
              label=""
              style={{ width: "300px", height: "40px", marginLeft: "12px" }}
              onChange={(e) => {
                if(currentFilter.company) return;
                setCurrentFilter((prev) => {
                  return { ...prev, status: e.target.value };
                });
              }}
              displayEmpty
              sx={{
                "& .MuiSvgIcon-root": {
                  display: "inline-block",
                  fontSize: "24px!important",
                  right: "0",
                },
                "& .MuiSelect-iconOpen": {
                  fontSize: "24px!important",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="ACTIVE">AVAILABLE</MenuItem>
              <MenuItem value="INUSE">IN USE</MenuItem>
            </Select>
          </MDBox>
          <MDBox sx={{marginTop:'8px'}}>
            <Typography>Companies : </Typography>
            <Select
              value={currentFilter.company}
              label=""
              style={{ width: "300px", height: "40px", marginLeft: "12px" }}
              onChange={(e) => {
                console.log(e.target.value)
                setCurrentFilter(prev => {
                  return {...prev, status : "INUSE",company:e.target.value}
                })
              }}
              displayEmpty
              sx={{
                "& .MuiSvgIcon-root": {
                  display: "inline-block",
                  fontSize: "24px!important",
                  right: "0",
                },
                "& .MuiSelect-iconOpen": {
                  fontSize: "24px!important",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value=''>NONE</MenuItem>
              {fetchedCompany?.map((item) => (
                <MenuItem key={item.companyId} value={item.companyId}>
                  {item.companyName}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
          <MDBox mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                borderRadius: "0px",
                color: "black!important",
                marginRight: "12px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
              }}
              onClick={onFilterApplied}
            >
              Apply
            </Button>
          </MDBox>
        </MDBox>
      </Popover>
    </DashboardLayout>
  );
};
export default Pool;
