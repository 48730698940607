import { Divider } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PhoneAPI } from "services/PhoneAPI";
import AutoReplyRule from "./AutoReplyRule";
import AutoReplyMessage from "./AutoReplyMessage";

const AutoReplyWrapper = () => {

  const [phones,setPhones] = useState([]);

  const token = useSelector(state => state.user.token);
  const companyId = useSelector(state => state.user.company.companyId);
  const userId = useSelector(state => state.user.id);

  useEffect(() => {
      if(token && userId && companyId){
          const payload = {
              companyId : companyId,
              userId : userId
          }
          PhoneAPI.getPhoneList({token,payload}).then(res => {
              setPhones(res.data);
          }).catch(e => {});
      }
  },[token,userId,companyId]);    

  return(
      <>
        <AutoReplyRule key={"auto_reply_rule"} phones={phones}/>
        <AutoReplyMessage key={"auto_reply_msg"} phones={phones}/>
        <Divider sx={{opacity:'1'}}/>
      </>
  )
}
export default memo(AutoReplyWrapper);