import { Navbar, StyledLabel, FlexContainer } from "pages/Chat/ChatStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormatPhoneNumber } from "util/UtilityFunctions";
import { Checkbox,Popover,Tooltip, Typography} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, { useEffect, useState } from "react";
import {Search } from "@mui/icons-material";
import Swal from "sweetalert2";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CustomDateRangePicker from "components/util/CustomDateRangePicker";
import MDBox from "lib/components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import MDTypography from "lib/components/MDTypography";
import { chatVariablesAction } from "store/chat-variables-slice";
import { VIEWS } from "constants/ChatConstants";
import useHttp from "hooks/use-http";
import { individualChatExport } from "api/export";
import PhonesDropDown from "./PhonesDropDown";
import { individualChatDelete } from "api/export";
import { individualGroupChatDelete } from "api/export";
import { removeDeletedConversations } from "util/ChatUtil";
import { chatAction } from "store/chat-slice";
import { toast } from "react-toastify";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import TeleGlobalSearch from "../Search/TeleGlobalSearch";
import MediaQuery, { useMediaQuery } from "react-responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { groupAction } from "store/group-slice";
import { useNavigate } from "react-router-dom";
import UserSettings from "../UserSettings";
import { messageAction } from "store/message-slice";

const RightChatPanelNavBar = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  
  // STATES
  const [exportDateRange, setExportDateRange] = useState();
  const [chatExport, setChatExport] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const isCheckboxSelected = useSelector(state => state.chatVariables.isCheckboxSelected);
  const [anchorEl, setAnchorEl] = useState(null);

  // SELECTORS 
  const currentActiveView = useSelector(state => state.chat.current_active_view);
  const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
  const currentActiveGroup = useSelector(state => state.group.current_active_group);
  const activeCheckbox = useSelector(state => state.chatVariables.activeCheckbox);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const rConversationState = useSelector(state => state.chat.conversation);
  const token = useSelector(state => state.user.token);
  const role = useSelector(state => state.user.role);
  const recipients = useSelector(state => state.chat[VIEWS.NORMAL_VIEW]?.recipients);
  //VARIABLES
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //CUSTOM HOOKS
  const { sendRequest: individualChatExportFn } = useHttp(individualChatExport,false);
  const {sendRequest: individualChatDeleteFn,data: deleteIndData,status: deleteIndStatus,error: deleteIndErr,} = useHttp(individualChatDelete, false);
  const {sendRequest: individualGroupChatDeleteFn,data: deleteIndGroupData,status: deleteIndGroupStatus,error: deleteIndGroupErr,} = useHttp(individualGroupChatDelete, false);

  // DISPATCHERs
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRangeApply = (range) => {
    setAnchorEl(null);
    setExportDateRange(range);
    onExport(range);
  };
  const onDeleteHandler = useCallback(() => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setChatExport(false);
        setCheckValue(false);
        dispatch(chatVariablesAction.setRightNavChecked(false));
        const deleteData = {
          recipient_id: currentActiveRecipient?.id,
          export: [activeCheckbox.get(currentActiveRecipient?.id)],
          phone_id: currentActivePhone?.phoneId,
        };
        if (currentActiveView === VIEWS.NORMAL_VIEW) individualChatDeleteFn({payload : deleteData,token});
        else {
          deleteData.group_id = currentActiveGroup?.groupId;
          individualGroupChatDeleteFn({payload:deleteData,token});
        }        
      }
    });
  },[currentActiveView]);

  const onCheckedEvent = (e) => {
    setCheckValue(e.target.checked);
    dispatch(chatVariablesAction.setRightNavChecked(e.target.checked))
    e.target.checked ? setChatExport(true) : setChatExport(false);
    // toast.info("Here you can select the active conversations and export. This feature is currently disabled.");
  };
  const onExport = useCallback((range=undefined) => {
    // toast.info("The export feature is currently disabled.");
    setChatExport(false);
    setCheckValue(false);
    const exportData = {
      recipient_id: currentActiveRecipient?.id,
      export: [activeCheckbox[currentActiveRecipient?.id]],
      date_range: { start: range?.startDate, end: range?.endDate },
      phone_id: currentActivePhone?.phoneId,
    };
    individualChatExportFn(exportData);
    dispatch(chatVariablesAction.setRightNavChecked(false));
    dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    setExportDateRange(undefined);
  },[currentActivePhone,currentActiveRecipient,recipients]);

  useEffect(() => {
    if (deleteIndStatus === "completed") {
      if (deleteIndData) {
        const newConversation = removeDeletedConversations(rConversationState,deleteIndData);
        dispatch(messageAction.setConversation(newConversation));
      } else {
        toast.error("something went wrong, please try again..");
      }
    }
  }, [deleteIndStatus, deleteIndErr, deleteIndData]);

  useEffect(() => {
    if (deleteIndGroupStatus === "completed") {
      if (deleteIndGroupData) {
        const newConversation = removeDeletedConversations(rConversationState,deleteIndGroupData);
        dispatch(messageAction.setConversation(newConversation));
        // window.location.reload();
      } else {
        toast.error("something went wrong, please try again..");
      }
    }
  }, [deleteIndGroupStatus, deleteIndGroupErr, deleteIndGroupData]);

  return (
    <Navbar borderLeft="none">
      {isMobile && <>
        <MDBox sx={{width:'20%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {!currentActiveGroup && !currentActiveRecipient  &&
              <MediaQuery maxWidth={768}>
                {/* <Settings onClick={() => navigate("/admin/dashboard")}/> */}
                <UserSettings/>
              </MediaQuery>
            }
          {(currentActiveGroup || currentActiveRecipient) && <ArrowBackIosIcon onClick={() => {
            if(currentActiveView === VIEWS.GROUP_VIEW){
              dispatch(groupAction.setCurrentActiveGroup(undefined));
            }
            else {
              dispatch(chatAction.setCurrentActiveRecipient(undefined));
            }
          }}/>}
        </MDBox>
      </>
      }
      <FlexContainer style={{flexFlow: isMobile ? 'column-reverse' : null}} justifyContent="space-between" fxLayout={isMobile ? 'column' : ''}  paddingLeftRight="15px" paddingTopBottom="8px">
        <StyledLabel fontSize="20px" fontWeight="500" color="#555" style={{display:'flex', flexDirection: "row"}}>
         {currentActiveView === VIEWS.NORMAL_VIEW && isCheckboxSelected && currentActiveRecipient && <Checkbox
            onChange={onCheckedEvent}
            checked={checkValue}
            className="navbar-checkbox"
          />}
          {currentActiveView === VIEWS.GROUP_VIEW ? 
            <MDTypography variant="button" fontWeight="medium">
              {currentActiveGroup?.name}
            </MDTypography> 
            :currentActiveRecipient && 
              <>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography variant="button" fontWeight="medium">
                    {currentActiveRecipient.displayName ? currentActiveRecipient.displayName : FormatPhoneNumber(currentActiveRecipient.phone,currentActiveRecipient?.countryCode)}
                  </MDTypography>
                  {currentActiveRecipient?.displayName && 
                    <Typography fontSize={'14px'}>
                      {FormatPhoneNumber(currentActiveRecipient.phone,currentActiveRecipient?.countryCode)}
                    </Typography>
                  }
                </MDBox>
              </>
          }
        
        </StyledLabel>
        {chatExport && (
          <>
            <div className="export-chat-icons">
              <FlexContainer alignItems="center" justifyContent="space-between">
                <div>
                  <Tooltip title="Export the selected conversations.">
                    <FileDownloadIcon
                      onClick={onExport}
                      fontSize="medium"
                      className="navbar-icon"
                    />
                  </Tooltip>
                </div>

                <div>
                  <Tooltip title="Delete the selected conversations">
                    <DeleteIcon
                      sx={{ marginLeft: "-18px" }}
                      onClick={onDeleteHandler}
                      className="navbar-icon"
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Select date range for export.">
                    <DateRangeIcon
                      sx={{ marginLeft: "-18px" }}
                      onClick={handleClick}
                      fontSize="medium"
                      className="navbar-icon"
                    />
                  </Tooltip>
                </div>
              </FlexContainer>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  backgroundColor: "white!important",
                },
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CustomDateRangePicker
                  onCancel={() => setAnchorEl(null)}
                  onRangeApply={onRangeApply}
                ></CustomDateRangePicker>
              </div>
            </Popover>
          </>
        )}
        <MDBox display="flex">
          <PhonesDropDown/>
          <MDBox mr={1} pl={1} pb={1} pt={.5} alignItems="center" height="100%">
              <MDBox display="flex" justifyContent="flex-start">
                  <Tooltip title="Search chat">
                    <Search onClick={() => {dispatch(ichatVariablesAction.updateRightSideBar({open:true,content:<><TeleGlobalSearch/></>}));}} className="navbar-icon" sx={{height: "24px",width:"24px",marginRight:"8px",cursor:"pointer"}}/>
                  </Tooltip>
              </MDBox>
          </MDBox>        
        </MDBox>
      </FlexContainer>
    </Navbar>
  );
};
export default React.memo(RightChatPanelNavBar);
