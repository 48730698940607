import AppModal from "ui/AppModal";
import { useSelector } from "react-redux";
import TelecommunicationSettings from "./Telecommunication/TelecommunicationSettings";
import MDBox from "lib/components/MDBox";
import { Divider, Icon, List } from "@mui/material";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { Edit, Forum, Language, Settings } from "@mui/icons-material";
import { useState } from "react";
import Ichatsettings from "./Ichat/IchatSettings";
import style from "./Notification.module.css";
import LocaleSettings from "./LocaleSettings";
import MySignature from "./MySignature";
import ProfileSettings from "./ProfileSettings";
import MediaQuery from "react-responsive";

const SETTING_OPTIONS = {
    PROFILE: 'profile',
    TELECOMMUNICATION: 'telecommunication',
    INTERNAL_CHAT: 'internal_chat',
    LANGUAGE_REGION: 'language_region',
    SIGNATURE: 'signature'
}

const Preferences = ({ onClose }) => {

    const user = useSelector(state => state.user);
    const [action, setAction] = useState(SETTING_OPTIONS.PROFILE);

    return (<>
        <MediaQuery minWidth={768}>
            <AppModal
                customStyle={{ width: '100%', height: '100%', overflowY: 'scroll', border: 'unset' }}
                rootStyle={
                    {
                        "&.MuiModal-root": {
                            width: '60%',
                            left: '40%'
                        }
                    }
                }
                onModalClose={() => onClose()}
                closeOnClick={true}
                heading={'My Preferences'}
            >

                <MDBox display="flex" alignItems="flex-start" mt={2} height="100%">
                    <MDBox height="100%" marginRight="24px">
                        <List sx={{ minWidth: '12rem' }}>
                            <NotificationItem
                                className={action === SETTING_OPTIONS.PROFILE ? style['selectedStyle'] : ''}
                                onClick={() => setAction(SETTING_OPTIONS.PROFILE)}
                                icon={
                                    <Icon>
                                        <Settings />
                                    </Icon>
                                }
                                title="Profile"
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.TELECOMMUNICATION ? style['selectedStyle'] : ''}
                                onClick={() => setAction(SETTING_OPTIONS.TELECOMMUNICATION)}
                                icon={
                                    <Icon>
                                        <PermPhoneMsgIcon />
                                    </Icon>
                                }
                                title="Telecommunication"
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.INTERNAL_CHAT ? style['selectedStyle'] : ''}
                                onClick={() => setAction(SETTING_OPTIONS.INTERNAL_CHAT)}
                                icon={
                                    <Icon>
                                        <Forum />
                                    </Icon>
                                }
                                title="Internal chat"
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.LANGUAGE_REGION ? style['selectedStyle'] : ''}
                                onClick={() => setAction(SETTING_OPTIONS.LANGUAGE_REGION)}
                                icon={
                                    <Icon>
                                        <Language />
                                    </Icon>
                                }
                                title="Langauge and region"
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.SIGNATURE ? style['selectedStyle'] : ''}
                                onClick={() => setAction(SETTING_OPTIONS.SIGNATURE)}
                                icon={
                                    <Icon>
                                        <Edit />
                                    </Icon>
                                }
                                title="My Signatures"
                            />
                        </List>
                    </MDBox>
                    <Divider flexItem={true} orientation="vertical" sx={{ height: 'auto', borderWidth: '2px' }} />
                    <MDBox width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
                        {action === SETTING_OPTIONS.TELECOMMUNICATION && <TelecommunicationSettings key={"telecommunication_settings"} />}
                        {action === SETTING_OPTIONS.INTERNAL_CHAT && <Ichatsettings key={"ichat_settings"} />}
                        {action === SETTING_OPTIONS.LANGUAGE_REGION && <LocaleSettings key={"local_settings"} />}
                        {action === SETTING_OPTIONS.SIGNATURE && <MySignature key={"signature_settings"} />}
                        {action === SETTING_OPTIONS.PROFILE && <ProfileSettings key={'user_profile'} />}
                    </MDBox>
                </MDBox>


            </AppModal>
        </MediaQuery>
        {/**For smaller devicess....*/}
        <MediaQuery maxWidth={768}>
            <AppModal
                customStyle={{ width: '100%', height: '100%', overflowY: 'scroll', border: 'unset' }}
                rootStyle={
                    {
                        "&.MuiModal-root": {
                            width: '100%',

                        }
                    }
                }
                onModalClose={() => onClose()}
                closeOnClick={true}
                heading={'My Preferences'}
            >

                <MDBox display="flex" alignItems="flex-start" mt={2} height="100%" sx={{ flexDirection: "column" }}>
                    <MDBox height="100%" marginRight="24px" sx={{ width: "100%" }}>
                        <MDBox sx={{ width: "100%", display: "flex" }} >
                            <NotificationItem
                                className={action === SETTING_OPTIONS.PROFILE ? style['selectedStyle'] : style['prefernce_m_list_item']}
                                onClick={() => setAction(SETTING_OPTIONS.PROFILE)}
                                icon={
                                    <Icon>
                                        <Settings />
                                    </Icon>
                                }
                                title={action === SETTING_OPTIONS.PROFILE ? "Profile" : ""}
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.TELECOMMUNICATION ? style['selectedStyle'] : style['prefernce_m_list_item']}
                                onClick={() => setAction(SETTING_OPTIONS.TELECOMMUNICATION)}
                                icon={
                                    <Icon>
                                        <PermPhoneMsgIcon />
                                    </Icon>
                                }
                                title={action === SETTING_OPTIONS.TELECOMMUNICATION ? "Telecommunication" : ""}
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.INTERNAL_CHAT ? style['selectedStyle'] : style['prefernce_m_list_item']}
                                onClick={() => setAction(SETTING_OPTIONS.INTERNAL_CHAT)}
                                icon={
                                    <Icon>
                                        <Forum />
                                    </Icon>
                                }
                                title={action === SETTING_OPTIONS.INTERNAL_CHAT ? "Internal chat" : ""}
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.LANGUAGE_REGION ? style['selectedStyle'] : style['prefernce_m_list_item']}
                                onClick={() => setAction(SETTING_OPTIONS.LANGUAGE_REGION)}
                                icon={
                                    <Icon>
                                        <Language />
                                    </Icon>
                                }
                                title={action === SETTING_OPTIONS.LANGUAGE_REGION ? "Langauge and region" : ""}
                            />
                            <NotificationItem
                                className={action === SETTING_OPTIONS.SIGNATURE ? style['selectedStyle'] : style['prefernce_m_list_item']}
                                onClick={() => setAction(SETTING_OPTIONS.SIGNATURE)}
                                icon={
                                    <Icon>
                                        <Edit />
                                    </Icon>
                                }
                                title={action === SETTING_OPTIONS.SIGNATURE ? "My Signatures" : ""}
                            />
                        </MDBox>
                    </MDBox>
                    <Divider flexItem={true} orientation="horizontal" sx={{ height: 'auto', borderWidth: '2px' }} />
                    <MDBox width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
                        {action === SETTING_OPTIONS.TELECOMMUNICATION && <TelecommunicationSettings key={"telecommunication_settings"} />}
                        {action === SETTING_OPTIONS.INTERNAL_CHAT && <Ichatsettings key={"ichat_settings"} />}
                        {action === SETTING_OPTIONS.LANGUAGE_REGION && <LocaleSettings key={"local_settings"} />}
                        {action === SETTING_OPTIONS.SIGNATURE && <MySignature key={"signature_settings"} />}
                        {action === SETTING_OPTIONS.PROFILE && <ProfileSettings key={'user_profile'} />}
                    </MDBox>
                </MDBox>

            </AppModal>
        </MediaQuery>
    </>
    )
}
export default Preferences;