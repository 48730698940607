import * as React from "react";
import useHttp from "hooks/use-http";
import { useDispatch, useSelector } from "react-redux";
import { Info, RemoveCircle} from "@mui/icons-material";
import { Button, Divider, TextField, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import MDBox from "lib/components/MDBox";
import { useRef } from "react";
import MDTypography from "lib/components/MDTypography";
import Accordian from "components/util/Accordian";
import GDetails from "./GDetails";
import { getGroup } from "api/group";
import { updateGroup } from "api/group";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import { isPromotionalGroup } from "util/GroupUtil";
import AppModal from "ui/AppModal";
import PendingGroupConsent from "components/GroupChat/PendingGroupConsent";
import ConsentOptions from "components/GroupChat/ConsentOptions";
import { processRecipientAdditionWithConsentOpt } from "api/group";
import { getPendingConsents } from "api/group";
import Swal from "sweetalert2";
import { PhoneAPI } from "services/PhoneAPI";
import { groupAction } from "store/group-slice";
import AppChip from "ui/AppChip";
import { TeleGroupServiceAPI } from "services/Chat/TeleGroupServiceAPI";
import { loaderAction } from "store/loader-slice";
import AppConstants from "constants/AppConstants";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import UploadGroupRecipients from "./UploadGroupRecipients";

/**
 * [{label : '8989988787',key : '8989988787',delete : () => {}}]
 */
//TODO: Slowly trying moving all fetch apis, to axios

const columns = [
  {id: 1, key : "sno", label: "S NO",},
  { id: 2, key : "recipient", label: "Recipient"},
  { id: 3, key : "action", label: "Action" },
];

const GroupDetails = ({groupId,onEventPerform,currentActivePhone}) => {

    const numberRef = useRef('');
    const dispatch = useDispatch();

    const [consents,setConsents] = React.useState(undefined);

    const [numbersToAdd,setNumbersToAdd] = React.useState([]);

    const [rows,setRows] = React.useState([]);
    const [formattedRows,setFormattedRows] = useState([]);
    const [currGroup,setCurrGroup] = React.useState();
    const token = useSelector(state => state.user.token);
    const [numerr, setNumErr] = React.useState('');
    const {sendRequest : getGroupFn, status, data, error} = useHttp(getGroup,false);
    const {sendRequest : updateGroupFn,data : updatedGroup, status: groupUpdateStatus,error : groupUpdateErr} = useHttp(updateGroup,false)
    const [consentRequired, setConsentRequired] = React.useState(undefined);
    const {sendRequest : processRecipientAdditionWithConsentOptFn, status : ras, data : rad} = useHttp(processRecipientAdditionWithConsentOpt,false)
    const {sendRequest : getPendingConsentsFn, status:pendingConsentStaus, data : consentList} = useHttp(getPendingConsents,false);

    const newRecipientsAdded = (newRecipients) => {
      const addedRecipients = newRecipients?.map(itm => {
        return{
          contact : {
            displayName : itm?.displayName,
            phone : itm?.phone,
            id : itm.id
          }
        }
      })
      const newRows = [ ...addedRecipients,...rows];  
      setRows(newRows);  
      dispatch(groupAction.addRecipients({recipients: addedRecipients,groupId})); 
    }

    const getNumberForChip = (currNumber) => {
      currNumber = currNumber.replace(/\D/g, "");
      if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(currNumber)){
        setNumErr('This does not seems a valid phone number.');
        return -1;
      }
      const newNum = {
        key : currNumber,
        delete : true,
        label : currNumber,
        recipient : currNumber
      }
      return newNum;
    }
    const onUpdateInput = (updatedData) => {
      updateGroupFn({token,group:{...updatedData,phone:currentActivePhone.phone}});
    }

    const numberTempRemoved = useCallback((res) => {
      const newNumbers = numbersToAdd.filter(itm => itm.key !== res.key);
      setNumbersToAdd(newNumbers);
    },[numbersToAdd])

    const addRecipientToGroupFn = useCallback((recipients) => {
      TeleGroupServiceAPI.addRecipientToGroup({payload : recipients,token}).then(res => {
        dispatch(loaderAction.hide());
        toast.success(`Recipients have been added to the group successfully.`);
        //Dispatch to add new recipients...
        newRecipientsAdded(res.data); 
      }).catch(e => {
        dispatch(loaderAction.hide());
        console.log(e)
      });
    },[rows,dispatch,newRecipientsAdded,token]);

    const deleteGroup = useCallback(() => {
      Swal.fire({
        title: 'Are you sure want to delete this group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        customClass: {
          container : {
            zIndex : '10000 !important'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          //Delete group
          PhoneAPI.deleteGroup({token,groupId,phoneId : currentActivePhone.phoneId}).then(res => {
            // Group delete remove it from local......
            dispatch(groupAction.deleteGroup(groupId));
            onEventPerform("GROUP_DELETED");
          }).catch(e => console.log(e));
        }
      })    
    },[currentActivePhone,dispatch,groupId,token,onEventPerform]);

    const OnConsentOption = React.useCallback((option) => {
      const payload = {
        phone_id : currentActivePhone.phoneId,
        phone : currentActivePhone.phone,
        group_id : groupId,
        recipients : consentRequired,
        consent_option : option.value
      }
      processRecipientAdditionWithConsentOptFn({payload,token});
    },[consentRequired,groupId,currentActivePhone,token,processRecipientAdditionWithConsentOptFn]);
    
    const onRemoveHandler = React.useCallback((id,recipient) => {
      const payload = {
        group_id:groupId,recipient_id:id
      }
      TeleGroupServiceAPI.removeRecipientFromGroup({payload,token}).then(res => {
        toast.success(`${recipient} has been removed from the group.`);
        const newRows = rows.filter(item => item.contact.id !== res.data);
        setRows(newRows);         
          //dispatch to remove from group...
          dispatch(groupAction.removeRecipient({recipientId : res.data,groupId}));
      }).catch(e => {

      })
    },[rows,groupId,dispatch,token]);

    const handleInput = React.useCallback((e) => {
      let val = e.target.value;
      val = val.trim();
      let valArr = val.split(",");
      valArr = valArr.filter(itm => itm?.trim().length >= 10).map(itm => getNumberForChip(itm)).filter(itm => itm !== -1);
      console.log({len:valArr.length,valArr})
      if(valArr.length >= 1){
        const newNumbers = [...numbersToAdd,...valArr];
        setNumbersToAdd(newNumbers);
        numberRef.current.value = '';
      }
    },[setNumbersToAdd,numbersToAdd])

    const onEnterPress = (e) => {
      if(e.keyCode === 13 && e.shiftKey === false) {
        onNumberSubmitHandler();
      }
    }
    
    const onRecipientsUploaded = (res) => {
      newRecipientsAdded(res);
    }

    const onNumberSubmitHandler = React.useCallback(() => {
      if(!currGroup) return;
      // If promo or controlled group.. Need consent from recipient before adding them
      let allRecipients = [];
      allRecipients = numbersToAdd.map(itm => itm.label);
      if(allRecipients.length === 0){
        return;
      }
      dispatch(loaderAction.show());
      if(isPromotionalGroup(currGroup)){
        const payload = {
          phone : currentActivePhone.phone,
          recipients : allRecipients
        }  
        TeleGroupServiceAPI.getConsentStatusForRecipient({token,payload}).then(res => {
          dispatch(loaderAction.hide());
          // Find all whose consent is required and for rest just hit the API to add...
          const requiredConsent = res.data.filter(itm => !itm.consent_given).map(itm => itm.recipient);
          const consentGiven = res.data.filter(itm => itm.consent_given);
          if(requiredConsent.length > 0){
            setConsentRequired(requiredConsent);
          }
          // And for others proceed with addition...
          if(consentGiven > 0){
            //Format consent given to create payload;
            const addConsentGiven = consentGiven.map(c => {
              return{
                ...c,
                groupId : groupId,
                phoneId : currentActivePhone.phoneId
              }
            })
            addRecipientToGroupFn(addConsentGiven);
          }
        }).catch(e => {
          dispatch(loaderAction.hide());
        });
        setNumbersToAdd([]);
      }
      else{
        const addRecipients = allRecipients.map(itm => {
          const tmp = {
            recipient : itm,
            phone : currentActivePhone.phone,
            phoneId : currentActivePhone.phoneId,
            groupId : groupId
          }
          return tmp;
        })
        addRecipientToGroupFn(addRecipients);
        setNumbersToAdd([]);
      }
    },[numbersToAdd,currGroup,groupId,currentActivePhone,token,dispatch,addRecipientToGroupFn]);
    
    useEffect(() => {
      if(groupId && token){
        getGroupFn({groupId,token})
        getPendingConsentsFn({groupId,token});
      }
    },[groupId,token,getGroupFn,getPendingConsentsFn]);
    
    useEffect(() => {
      const newRows = rows?.map((item,index) => {
        //Data is not consistence from backend,
        const id = item.contact ? item.contact.id : item.id; 
        const recipient = item.contact ? item.contact.phone : item.phone;
        return {
          id : id,
          sno : index + 1,
          recipient : recipient,
          action : <Tooltip key={"tooltip_" + id} title="Remove the contact from this group.">
            <RemoveCircle key={id} onClick={() => onRemoveHandler(id,recipient)} style={{cursor:'pointer',width:'1.5rem',height:'1.5rem'}}/>
            </Tooltip>
        }
      });
      setFormattedRows(newRows);
    },[rows,setFormattedRows,onRemoveHandler]);

    useEffect(() => {
      if(pendingConsentStaus === 'completed'){
          if(consentList){
             setConsents(consentList);
          }
      }
    },[pendingConsentStaus,consentList])

    useEffect(() => {
      if(ras === 'completed'){
        if(rad){
          if(rad?.consent_option === 'right_away'){
            toast.success("10DLC consent has sent to the recipient. Once consent is confirmed, it will automatically added to the group.");
          }
          else{
            toast.success("10DLC consent is required. Once consent is confirmed, it will automatically added to the group.");
          }
          if(rad?.consent_created){
            const newConsents = [...rad?.consent_created,...consents];
            setConsents(newConsents);
          }
        }
        else{
          toast.error("something went wrong.");
        }
        setConsentRequired(undefined);
        numberRef.current.value = '';
      }
    },[ras,rad])

    useEffect(() => {
      if(groupUpdateStatus === 'completed'){
        if(updatedGroup){
          toast.success("Updated sucessfully.");
          setCurrGroup(updatedGroup);
          onEventPerform('GROUP_UPDATED',updatedGroup);
        }
        else if(groupUpdateErr){
          toast.error(groupUpdateErr);
        }
      }
    },[groupUpdateStatus,updatedGroup]);

    useEffect(() => {
      if(status === 'completed') {
        if(data) {
          setRows(data?.recipientPhone);
          setCurrGroup(data);
        }
      }
    },[status,error,data]);

    return (
      <>
        <MDBox display="flex" flexDirection="column" width="100%" justifyContent="center">
          <MDBox>
            <MDTypography variant="h5">Broadcast Details</MDTypography>
          </MDBox>
          <Accordian heading={"Details"} style={{ margin: "16px 16px 16px 0px" }}>
            {currGroup && <GDetails groupData={currGroup} onUpdateInput={onUpdateInput}/>}
          </Accordian>
          <Accordian
            defaultExpanded
            heading={"Recipients"}
            style={{ margin: "16px 16px 16px 0px" }}
            
          >
            <MDBox display="flex" flexDirection="column">
              <MDBox display="flex" alignItems="center" width="100%" justifyContent="center" flexDirection="column">
                <MDBox display="flex" mb={2} width="100%" flexWrap="wrap" justifyContent="center" alignItems="center">
                  {numbersToAdd.length > 0 && <AppChip data={numbersToAdd} onRemoved={numberTempRemoved}/>}
                </MDBox>
                <MDBox width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <MDBox width="100%" display="flex" flexDirection="row" justifyContent="center">
                    <MDBox display="flex" flexDirection="column">
                      <MDBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <Tooltip title={AppConstants.ADD_MULTIPLE_NUMBER_WITH_COMMA_SEPATED}>
                          <Info sx={{marginRight:'12px',cursor:'pointer',color:'#1A73E8'}}/>
                        </Tooltip>
                        <TextField
                          inputRef={numberRef}
                          type="text"
                          variant="standard"
                          onChange={handleInput}
                          onKeyDown={onEnterPress}
                          label="Enter number here"
                        />
                      </MDBox>
                      <MDBox width="100%" display="flex" justifyContent="center">
                        <MDTypography variant="overline" fontWeight="medium" color="error">
                          {numerr}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {numbersToAdd?.length > 0 && <Button
                          onClick={onNumberSubmitHandler}
                          size={"medium"}
                          variant="contained"
                          sx={{marginTop:'16px',borderRadius: "0px",color: "white!important",maxWidth:'200px!important'}}
                        >
                          Add {numbersToAdd?.length}&nbsp;{numbersToAdd?.length > 1 ? "participants": "participant"} 
                        </Button>}
                      </MDBox>
                    </MDBox>
                      <Typography alignItems={"center"} margin={"4px"} alignContent={"center"}>{"OR"}</Typography>
                      <MDBox display="flex" flexDirection="column">
                        <UploadGroupRecipients OnConsentOption={OnConsentOption} group={currGroup} onRecipientsUploaded={onRecipientsUploaded} currentActivePhone={currentActivePhone} groupId={groupId}/>                        
                      </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>

              {formattedRows.length > 0 && (
                <MDBox mt={2} sx={{maxHeight:'400px!important',overflow:'auto'}}>
                  <EnhancedTable
                    rows={formattedRows}
                    heading={"Recipients"}
                    columns={columns}
                    showFilter={false}
                    selectEnabled={false}
                  />
                </MDBox>
              )}
              {formattedRows.length === 0 && (
                <MDBox padding="28px" height="200px" display="flex" justifyContent="center" alignItems="center">
                  <Typography>No Recipients are added to this broadcast.</Typography>
                </MDBox>
              )}
            </MDBox>
          </Accordian>
          {currGroup && isPromotionalGroup(currGroup) && <Accordian heading={"Pending consents"} style={{ margin: "16px 16px 16px 0px" }}>
                {currGroup && consents && <PendingGroupConsent consents={consents} group={currGroup}/>}
          </Accordian>}
          <MDBox display="flex" justifyContent="flex-end" sx={{margin: "16px 16px 16px 0px" }}>
            <Button onClick={deleteGroup} variant="outlined" color="error" sx={{color:'red!important'}}>Delete broadcast</Button>                
          </MDBox>
        </MDBox>
        {Boolean(consentRequired) && 
          <AppModal
            closeOnClick={false}
            onModalClose={() => setConsentRequired(undefined)}
            heading={"Consent options"}
          >
            <ConsentOptions OnConsentOption={OnConsentOption}/>
          </AppModal>
        }
      </>
    );
}
export default React.memo(GroupDetails);