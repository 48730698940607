import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import style from "./CampaignList.module.css";
import CampaignRow from "./CampaignRow";

const CampaignList = ({campaignList,isPrinting,setCampaignList}) => {

  const [tableData, setTableData] = React.useState([]);
  
  const onCampaignEdited = React.useCallback((res) => {
    const filterCamp = campaignList?.map(item => {
      if(item.campaign_id === res.campaign_id){
        const newItm = {...item, campaign_name : res.campaign_name, goal_amount: res.goal_amount, completion_date : res.completion_date};
        return newItm;        
      }
      else return item;
    });
    setTableData(filterCamp);
  },[campaignList]);

  const onFundraiserCreated = React.useCallback((res) => {
    const filterCamp = campaignList?.map(item => {
      if(item.campaign_id === res.campaign.campaign_id){
          const newItem = {...item};
          newItem.fundraisers?.unshift(res);
          return newItem;
      }
      else return item;
    });
    setTableData(filterCamp);
  },[campaignList]);

  const onCampaignDeleted = React.useCallback((campaign) => {
    const filteredList = campaignList?.filter(item => item.campaign_id !== campaign.campaign_id);
    setCampaignList(filteredList)
    setTableData(filteredList);
  },[campaignList]);
   
  const onFundraiserCreatedBulk = React.useCallback((res) => {
    const filterCamp = campaignList?.map(item => {
      if(item.campaign_id === res.campaign_id){
        const newItem = {...item};
        newItem.fundraisers?.unshift(...res?.fundraisers);
        return newItem;
      }
      else return item;
    });
    setTableData(filterCamp);
  },[campaignList]);  

  React.useEffect(() => {
    setTableData(campaignList);
  },[campaignList]);

  return (
    <TableContainer component={Paper} mb={5}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group!important" }}>
          <TableRow>
            <TableCell className={style["print-only"]} />
            <TableCell align="center">Title</TableCell>
            <TableCell align="center">Goal Amount</TableCell>
            <TableCell align="center">Raised Amount</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Completion Date</TableCell>
            <TableCell align="center">Progress</TableCell>
            <TableCell className={style["print-only"]}  align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{width:'100%'}}>
          {tableData?.map((row) => (
            <CampaignRow isPrinting={isPrinting} onCampaignDeleted={onCampaignDeleted} onCampaignEdited={onCampaignEdited} key={row.campaign_id} row={row} onFundraiserCreated={onFundraiserCreated} onFundraiserCreatedBulk={onFundraiserCreatedBulk}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default React.memo(CampaignList);