import { useEffect, useState } from "react";
import UserCellCallConfig from "./UserCellCallConfig";
import CustomSelect from "ui/Select";
import MDBox from "lib/components/MDBox";
import { MenuItem } from "@mui/material";

const UserCellCallConfigWrapper = ({user,onDisaUpdated}) => {

    const [phoneId,setPhoneId] = useState(user?.phones?.[0].phoneId);
    const [allConfig,setAllConfig] = useState({});

    useEffect(() => {
        if(user?.customCellConfig){
            const allKys = {};
            for(const ky of user?.customCellConfig){
                allKys[ky.phoneId] = ky.cellConfig 
            }
            setAllConfig(allKys);
            console.log(allKys)
        }
    },[user?.customCellConfig])

    return(
        <MDBox display="flex" flexDirection="column" width="100%">
            <CustomSelect value={phoneId} onChange={e => setPhoneId(e.target.value)} sx={{ width: "100%", height: "32px",marginBottom:'12px'}}>
                {user.phones?.map(itm => <MenuItem key={itm.phoneId} value={itm.phoneId}>{itm.phone}</MenuItem>)}
            </CustomSelect>
            <UserCellCallConfig key={phoneId} phoneId={phoneId} userId={user.userId} config={allConfig[phoneId]} onDisaUpdated={onDisaUpdated}/>
        </MDBox>
    )
}
export default UserCellCallConfigWrapper;