import ScheduleIcon from "@mui/icons-material/Schedule";
import { FlexContainer } from "pages/Chat/ChatStyles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ScheduleMessage from "./ScheduleMessage";
import { Button, Popover, Stack, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import SendIcon from '@mui/icons-material/Send';
import MDBox from "lib/components/MDBox";
import { useSelector } from 'react-redux';
import { KeyboardArrowUp, Send, WhatsApp } from "@mui/icons-material";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import usePopup from "hooks/use-popup";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";

const SendMessage = React.forwardRef(({ onSend, openMessagePopup, setOpenMessagePopUp, onCancel }, ref) => {
  const [selectedItem, setSelectedItem] = useState('SMS');
  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduleModal, setScheduledModal] = useState(false);
  const { popoverId, anchorEl: scheduleAnchor, openPopover, handleOpenPopup, handleClosePopup, setAnchorEl: setScheduleAnchor } = usePopup();
  const [quickSchedule, setQuickSchedule] = useState();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  // Selector
  const currentMsgType = useSelector(state => state.chat.current_last_msg_type)
  const currentMsgView = useSelector(state => state.chat.current_message_view);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone);

  const scheduleRef = useRef();

  const sendMessage = useCallback((e) => {
    if (currentMsgView === 'ALL') {
      if(isMobile){
        setTimeout(() => {
        setOpenMessagePopUp(ref?.current);
        },500)
      }
      else
        handleClick(e)
    }
    else {
      onSend({ type: currentMsgView });
    }
  }, [currentMsgView]);

  const onSheduleClickHandlder = () => {
    setScheduledModal(true);
  };
  const onDoneEvent = (input) => {
    setScheduledModal(false);
    onSend(input);
    handleClose()
  };

  const showIcon = () => {
    if (currentMsgView === 'SMS') {
      return <SendIcon />;
    }
    else if (currentMsgView === 'WHATSAPP')
      return <WhatsApp sx={{
        fontSize: '20px!important'
      }} />
    else
      return <KeyboardArrowUp />;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleClosePopup();
    onCancel();
  };

  const onTNineAmSchedule = useCallback((e) => {
    const tomorrow9AM = moment().add(1, 'day').set({ hours: 9, minutes: 0, seconds: 0, milliseconds: 0 });

    if (currentMsgView === 'ALL') {
      handleClick(e);
      setQuickSchedule(tomorrow9AM);
    }
    else {
      onSend({ scheduledAt: tomorrow9AM, type: currentMsgView });
    }
  }, [currentMsgView]);

  const onTOnePmSchedule = useCallback((e) => {
    const tomorrow1PM = moment().add(1, 'day').set({ hours: 13, minutes: 0, seconds: 0, milliseconds: 0 });
    if(currentMsgView === 'ALL'){
      handleClick(e);
      setQuickSchedule(tomorrow1PM);
    }
    else {
      onSend({ scheduledAt: tomorrow1PM, type: currentMsgView });
    }
  }, [currentMsgView])

  const onMondayNineAmSchedule = useCallback((e) => {
    const today = moment();
    const dayOfWeek = today.day();
    const daysUntilMonday = dayOfWeek === 1 ? 7 : (1 - dayOfWeek + 7) % 7; // Calculate days until Monday
    const monday9AM = moment(today).add(daysUntilMonday, 'days').set({ hours: 9, minutes: 0, seconds: 0, milliseconds: 0 });
    if(currentMsgView === 'ALL'){
      handleClick(e);
      setQuickSchedule(monday9AM);
    }
    else {
      onSend({ scheduledAt: monday9AM, type: currentMsgView });
    }
  }, [currentMsgView])

  useEffect(() => {
    setSelectedItem(currentMsgType)
  }, [currentMsgType])
  useEffect(() => {
    if (openMessagePopup) {
      setAnchorEl(openMessagePopup);
    }
  }, [openMessagePopup]);

  return (
    <FlexContainer alignItems="center" justifyContent="flex-start" className="chat-footer" style={{ paddingRight: '0px' }}>
      <Popover
        onKeyDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (event.key === 'Escape') {
            handleClose(); // Close the popover on Esc key
          }
          if (event.key === 'Enter') {
            onSend({ type: selectedItem });
            handleClose();
            setQuickSchedule(undefined);
          }
          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (selectedItem === 'WHATSAPP') {
              setSelectedItem('SMS')
            } else {
              setSelectedItem('WHATSAPP')
            }
          }
        }}
        open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "white!important",
            width: "200px",
            justifyContent: 'center',
            border: 'solid 1px #ddd',
            boxShadow: '-4px 2px 6px 0px #979797'
          },
        }}
      >
        <NotificationItem className="chat_send_popup_item" selected={selectedItem === 'SMS'} tabIndex={1} id="sms" icon={<Send />} onClick={() => {
          onSend({type : "SMS",scheduledAt : quickSchedule});
          handleClose();
          setQuickSchedule(undefined);
        }} title={"SMS"} />
        <NotificationItem className="chat_send_popup_item" selected={selectedItem === 'WHATSAPP'} tabIndex={2} id="wa" icon={<WhatsApp />} onClick={() => {
          if (currentActivePhone.whatsappCred) {
            onSend({type : "WHATSAPP",scheduledAt : quickSchedule});
            handleClose();
            setQuickSchedule(undefined);
          }
          else {
            toast.error("This number does not have any whatsapp account. Please contact the administrator.");
            setQuickSchedule(undefined);
          }
        }} title={"WHATSAPP"} />
      </Popover>
      <Stack direction="row" spacing={0.1}>
        <Button
          ref={ref}
          onClick={sendMessage}
          sx={{
            borderRadius: "20px 0px 0px 20px",
            color: "white!important",
            border: "unset",
            width: "80px",
            padding: "10px",
            height: "35px",
            backgroundColor: "#1876F2 !important",
          }}
          endIcon={showIcon()}
          variant="contained"
          size="medium"
        >
          Send
        </Button>
        <Button
          ref={scheduleRef}
          onClick={(e) => {
            if (isMobile) {
              setScheduleAnchor(scheduleRef.current)
            }
            else
              handleOpenPopup(e);
          }}
          variant="contained"
          size="medium"
          sx={{
            borderRadius: "0px 20px 20px 0px",
            color: "white!important",
            border: "unset",
            width: "28px !important",
            minWidth: "unset",
            backgroundColor: "#1876F2!important",
            marginLeft: "0px",
            overflowX: "hidden",
          }}
        >
          <MDBox display="flex" sx={{ color: "white!important" }}>
            <Tooltip title="Schedule send">
              <ScheduleIcon sx={{ fontSize: "20px!important" }} />
            </Tooltip>
          </MDBox>
        </Button>
        <Popover
          id={popoverId}
          open={openPopover}
          anchorEl={scheduleAnchor}
          onClose={handleClosePopup}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: "white!important",
              width: "200px",
              justifyContent: 'center',
              border: 'solid 1px #ddd',
              boxShadow: '-4px 2px 6px 0px #979797'
            },
          }}
        >
          <NotificationItem key={'t_9'} tabIndex={1} title={"Tomorrow 9 AM"} onClick={onTNineAmSchedule} />
          <NotificationItem key={'t_1'} tabIndex={2} title={"Tomorrow 1 PM"} onClick={onTOnePmSchedule} />
          <NotificationItem key={'t_m_9'} tabIndex={2} title={"Monday 9 AM"} onClick={onMondayNineAmSchedule} />
          <NotificationItem key={'custom'} tabIndex={3} onClick={onSheduleClickHandlder} title={"Custom"} />
        </Popover>
      </Stack>
      {scheduleModal && (<ScheduleMessage onDone={onDoneEvent} onClose={() => { setScheduledModal(false); }} />)}
    </FlexContainer>
  );
});
export default SendMessage;
