// useEvent.js
import { useDispatch, useSelector } from 'react-redux';
import { WEBSOCKET_EVENT_TYPE } from 'constants/ChatConstants';
import { userDetailAction } from 'store/user-details-slice';
import { chatAction } from 'store/chat-slice';
import { useCallback } from 'react';
import { notify } from 'util/AppNotification';
import { VIEWS } from 'constants/ChatConstants';
import { MESSAGE_EVENTS } from 'constants/ChatConstants';
import { appAction } from 'store/app-slice';
import { playSound } from 'util/MediaUtil';
import AppConstants from 'constants/AppConstants';
import { groupAction } from 'store/group-slice';
import { messageAction } from 'store/message-slice';

const useEvent = () => {
  
  const dispatch = useDispatch();
  const teleUserSettings = useSelector(state => state?.userDetails?.details?.userSettings?.teleUserSettings);
  const recipients = useSelector(state => state.chat[VIEWS.NORMAL_VIEW].recipients);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const currentActiveView = useSelector(state => state.chat.current_active_view);
  const userId = useSelector(state => state.user.id);
  const iChat = useSelector(state => state.chatVariables.iChat);
  
  const notifyOnMessageReceived = useCallback((message) => {
    if(teleUserSettings?.notification_enabled){
      try{
        const sound = teleUserSettings.notification_sounds?.filter(itm => itm.enabled);
        const notificationPayload = {
          from : message.sendTo?.phone,  
          to : message.sendByPhone?.phone,
          body : Boolean(message?.message) ? message?.message : '',
          muted : Boolean(teleUserSettings?.notification_enabled),
          sound : sound?.sourceUrl
        }
        notify(notificationPayload);
      }
      catch(e){
        console.log(e);
      }
    }
  },[teleUserSettings]);
    
  const notifyOnEvent = () => {
    try{
      playSound(AppConstants.RECIPIENT_ASSIGNED_SOUND);
    }
    catch(e){}
  }

  const onEventReceive = useCallback((type,payload) => {
    if(type === WEBSOCKET_EVENT_TYPE.SMS_MESSAGE) {
      if(payload.inbound){
        notifyOnMessageReceived(payload);
        try{
          // update message count in phone dropdown if it is not the active phone number.
          //FIXME::update this logic
          dispatch(userDetailAction.updatePhones({count : 1,phoneId : payload.sendByPhone.phoneId}));
        }
        catch(e){console.log(e)};
        if(currentActivePhone.phoneId === payload.sendByPhone.phoneId){
          dispatch(chatAction.updateWhenInboundReceived(payload));
          dispatch(messageAction.handleInbound(payload));
        }
        else if(currentActiveView === VIEWS.MERGED_VIEW){
          dispatch(chatAction.updateWhenInboundReceived(payload));
          dispatch(messageAction.handleInbound(payload));
        }
      }
      else if (payload.scheduledAt){
        // It was scheduled message
        if(payload.groupId !== 0){
          dispatch(groupAction.scheduledMsgReceived(payload));
        }
        else{
          dispatch(messageAction.scheduledMsgReceived(payload));
        }
      }
      // maybe created by other user...
      else{
        try{
          if(userId !== payload.sendByUser.userId){
            dispatch(chatAction.updateLatestMessage({recipientId : payload.sendTo.id, message : payload}));
            dispatch(messageAction.msgCreatedByOther(payload));
          } else {
            dispatch(chatAction.moveRecipientToTop({phone:payload.sendTo.phone}))
          }
        }
        catch(e){
          console.log(e);
        }
      }
      console.log(payload);
    }
    else if(type === MESSAGE_EVENTS.CONVERSATION_ASSIGNED) {
      let notificationStr = payload.message;
      if(payload?.assign_to_group){

      }
      else{
        if(payload?.assign_to?.userId === userId){
          notificationStr = `You’ve been assigned a new conversation.`;
          notifyOnEvent();
        }
      }
      dispatch(appAction.setSnackbarMessage(notificationStr));
      dispatch(appAction.openSnackbar());
      // update assigned details....
      if(!iChat) {
        try{
          const assignTo = payload?.assign_to_group ? payload?.assign_to_group : payload?.assign_to
          dispatch(chatAction.assigNewConversation({phoneId : payload.phone_id,contactId : payload.contact_id,assignTo}));
          if(payload.assigned_by_id !== userId){
            dispatch(messageAction.addConversation({message : payload.message_obj, recipientId : payload.contact_id}));
            dispatch(chatAction.updateLatestMessage({message : payload.message_obj, recipientId : payload.contact_id}));
          }
        }catch(e) {
          console.log(e)
        }
      }
    }
    else if (type === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED){
      let notificationStr = payload.message;
      if(payload?.assign_to?.userId === userId){
        notificationStr = `You’ve been unassigned from the conversation: ${payload.recipient_phone}`;
        notifyOnEvent();
      }
      dispatch(appAction.setSnackbarMessage(notificationStr));
      dispatch(appAction.openSnackbar());
      // update assigned details....
      if(!iChat) {
          try{
            dispatch(chatAction.unassignConversation({contactId : payload.contact_id}));
            if(payload.unassign_by !== userId){
              dispatch(messageAction.addConversation({message : payload.message_obj, recipientId : payload.contact_id}));
              dispatch(chatAction.updateLatestMessage({message : payload.message_obj, recipientId : payload.contact_id}));
            }
          }catch(e) {}
      }            
    }
  },[recipients,teleUserSettings,userId,currentActivePhone,currentActiveView]);

  return {onEventReceive};
};

export default useEvent;
