import { Typography } from "@mui/material";
import { getTransactionsByCampaignAndFundraiser } from "api/donations";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isDateTimeSmaller } from "util/UtilityFunctions";
import TransactionFilter from "./TransactionFilter";

let filter = {};

let columns = [
  {
    id: "1",
    key: "sno",
    label: "Sno",
  },
  {
    id : '2',
    key : 'fundraiser_name',
    label : 'Fundraiser'
  },
  {
    id: "3",
    key: "fundraiser_phone",
    label: "Phone",
  },
  {
    id: "4",
    key: "donor_phone",
    label: "Donor phone",
  },
  {
    id: "5",
    key: "donation_amount",
    label: "Amount",
  },
  {
    id: "6",
    key: "medium",
    label: "Payment medium",
  },
  {
    id: "7",
    key: "donation_type",
    label: "Donation Type	",
  },
  {
    id: "8",
    key: "donated_at",
    label: "Donated At",
  },    
];
/// Sample data structure for transactions...
const data = [
    {
        sno: 1,
        donation_amount: 200,
        donor_phone: "7458965874",
        fundraiser_name : "Test",
        fundraiser_phone: "8956471254",
        payment_processed : "Internal",
        donation_type : 'One time',
        donated_at : "2023-03-02"
    }
]
const FundraiserTransactionsDetails = ({fundraiser,campaignId}) => {
   
    const token = useSelector(state => state.user.token);
    const [rows, setRows] = useState([]);
    const [filtered, setFiltered] = useState(false); 

    const {sendRequest, status, data, error} = useHttp(getTransactionsByCampaignAndFundraiser,false);

    const onFilterClick = (event) => {
      setFiltered(true)
    } 

    const onFilterApply = (filterData) => {
      if(isDateTimeSmaller(filterData.from, filterData.to)){
        toast.error("To can't be smaller than From.");
        return;
      }
      // TODO : Filter

      filter = {
        from : filterData.from !== '' ? filterData.from : undefined,
        to : filterData.to !== '' ? filterData.to : undefined,
        amount : filterData.amount !== '' ? filterData.amount : undefined,
        timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      sendRequest({token,campaignId,fundraiserId : fundraiser.fundraiser_id,filterPayload : filter});
    } 

    useEffect(() => {
      if(fundraiser && campaignId)
        sendRequest({token,fundraiserId : fundraiser.fundraiser_id,campaignId,filterPayload : filter})
    },[campaignId,fundraiser])

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          const newRows = data?.map((item,index) => {
            return {...item, id : item.id, sno: index + 1, donated_at : <Moment format="lll">{item.donated_at}</Moment>, donation_amount : <p>{item.donation_amount}</p>}
          });
          setRows(newRows);
        }
      }
    },[status, data, error])
    
    return(
        <MDBox display="flex">
          {filtered && <TransactionFilter onFilterApply={onFilterApply} setFiltered={setFiltered}/>}
          <MDBox width="100%">
            <Typography sx={{margin:'12px',fontSize:'28px'}}>Transactions</Typography>
            <EnhancedTable  onFilterClick={onFilterClick} rows={rows} columns={columns} selectEnabled={false}/>
          </MDBox>
        </MDBox>
    )
}
export default FundraiserTransactionsDetails;