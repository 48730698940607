import { VIEWS } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import { useSelector } from "react-redux";
import GConversation from "./GChat/GConversation";
import NConversation from "./NChat/NConversation";
import NoRecipientSelected from "components/util/NoRecipientSelected";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import MediaQuery from "react-responsive";

const ConversationWrapper = ({setMessageState}) => {

    const [parent] = useAutoAnimate();
    const view = useSelector(state => state.chat.current_active_view);
    const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
    const currentActiveGroup = useSelector(state => state.group.current_active_group);
    //calc(100% - 68px)
    return(
        <>
            <MediaQuery minWidth={768}>
                <MDBox ref={parent} display="flex" sx={{  overflowY: "auto",overflowX:'hidden'}} flexDirection="row" width="100%" height={'100%'}>
                    {view === VIEWS.GROUP_VIEW ? currentActiveGroup ? <GConversation key={"Gconversation"} setMessageState={setMessageState}/> : <NoRecipientSelected/>
                    :currentActiveRecipient ? <NConversation key={"Nconversation"} setMessageState={setMessageState}/> : <NoRecipientSelected/>
                    }
                </MDBox>        
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <MDBox ref={parent} display="flex" sx={{  overflow: "auto",height: 'calc(100% - 68px)'}} flexDirection="row" width="100%" height={`${(currentActiveRecipient || currentActiveGroup) ? '100%': 'auto'}`}>
                    {view === VIEWS.GROUP_VIEW ? currentActiveGroup ? <GConversation key={"Gconversation"} setMessageState={setMessageState}/> : <NoRecipientSelected/>
                    :currentActiveRecipient ? <NConversation key={"Nconversation"} setMessageState={setMessageState}/> : <NoRecipientSelected/>
                    }
                </MDBox>        
            </MediaQuery>
        </>
    )
}
export default ConversationWrapper;