import { Button, Card, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthAPI } from "services/AuthAPI";
import FailureUI from "util/FailureUI";
import SuccessUI from "util/SuccessUI";

const UI = {
    INITIAL : "initial",
    SUCCESS : "success",
    ERROR : "error"
}
const ResetPasswordPage = () => {
    
    const {token} = useParams();
    const [resUI,setResUI] = useState(UI.INITIAL);

    const [password,setPassword] = useState({
        password : "",
        confirm_password : "",
    });

    const resetPassword = (e) => {
        e.preventDefault();
        AuthAPI.resetPassword({token,password}).then(res => {
            setResUI(UI.SUCCESS);
        }).catch(e => setResUI(UI.ERROR))
    }

    return(
        <MDBox display="flex" justifyContent="center" height="100vh" alignItems="center" flexDirection="column">
            <Card sx={{width:"600px",padding:"24px"}}>
                { resUI === UI.INITIAL && <MDBox component="form" role="form" onSubmit={resetPassword} display="flex" padding="12px" justifyContent="center" alignItems="center" flexDirection="column">
                    <MDBox display="flex" width="100%" justifyContent="center">
                        <Typography>{"Reset password"}</Typography>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" justifyContent="center" width="100%">
                        <MDBox mb={2} display="flex">
                            <MDInput
                                onChange={(e) =>
                                setPassword((prev) => {
                                    return {
                                    ...prev,
                                    password: e.target.value,
                                    };
                                })
                                }
                                type="text"
                                label="Password"
                                variant="standard"
                                fullWidth
                            />
                        </MDBox>

                        <MDBox mb={2} display="flex">
                            <MDInput
                                error={password.password !== password.confirm_password && password.confirm_password.trim().length > 0}
                                onChange={(e) =>
                                setPassword((prev) => {
                                    return {
                                        ...prev,
                                        confirm_password: e.target.value,
                                        };
                                    })
                                }
                                type="text"
                                label="Confirm Password"
                                variant="standard"
                                fullWidth
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox mt={2} display="flex" justifyContent="flex-end">
                        <Button type="submit" variant="contained" sx={{border:"0px!important",color:"white!important"}}>Submit</Button>
                    </MDBox>
                </MDBox>}
                {resUI === UI.SUCCESS && 
                    <>
                        <SuccessUI title={"Password reset successfully."} desc={"You can logged in now."}/>
                        <MDTypography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                            >
                           Log In
                        </MDTypography>
                    </>
                }
                {resUI === UI.ERROR && <FailureUI title={"Password reset failed."} desc={"This is not a valid link, please contact the administrator."}/>}
            </Card>
        </MDBox>
    )
}
export default ResetPasswordPage;