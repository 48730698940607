import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const BulkAPI = {
    processSheetUrl : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/sms/bulk/upload/sheet`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    }
}