import { MessageWrapper } from "pages/Chat/ChatStyles";
import { MessageChat } from "pages/Chat/ChatStyles";
import { MainChatPanel } from "pages/Chat/ChatStyles";
import { useSelector } from "react-redux";
import NoContent from "../NoContent";
import useScrollToBottom from "hooks/use-scroll-to-bottom";
import useGroup from "hooks/use-group";
import { useCallback, useState } from "react";
import { MESSAGE_EVENTS } from "constants/ChatConstants";
import MDBox from "lib/components/MDBox";
import { Typography } from "@mui/material";
import Moment from "react-moment";
import { VIEWS } from "constants/ChatConstants";
import SingleRightMessage from "../SingleRightMessage";
import SingleLeftMessage from "../SingleLeftMessage";
import { memo } from "react";
import AppConstants from "constants/AppConstants";

const GConversation = ({setMessageState}) => {
    
    useGroup();
    const timezone = useSelector(state => state.userDetail?.details?.userSettings?.timeZone);
    const view = useSelector(state => state.chat.current_active_view);
    const currentActiveGroup = useSelector(state => state.group.current_active_group);
    const messages = useSelector(state => state.group.conversation[currentActiveGroup.groupId]);
    const activeCheckbox = useSelector(state => state.chatVariables.activeCheckbox);
    const isCheckboxSelected = useSelector(state => state.chatVariables.isCheckboxSelected);
    const rightNavChecked = useSelector(state => state.chatVariables.rightNavChecked);
    const [inlineSearch, setInlineSearch] = useState({
        search: false,
        pattern: "",
        criterion: "NORMAL_SEARCH",
    });

    const {scrollRef,scrollToBottom} = useScrollToBottom(messages);

    const onEventPerFormInMessageComponent = () => {

    }

    const formatMessages = useCallback((message,index) => {
        const showReadBy = messages?.length - 1 === index && messages[index].inbound
        if(message.messageEvent === MESSAGE_EVENTS.CONVERSATION_ASSIGNED || message.messageEvent === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED) {
          return <MDBox id={"message_" + message?.id} key={message.id} display="flex" width="100%" sx={{margin:'18px',justifyContent:'center'}}>
            <Typography fontWeight={"medium"} fontSize={"14px"}>{message.message} at (<Moment tz={timezone} format="LT">{message.createdAt}</Moment>, <Moment tz={timezone} format="ll">{message.createdAt}</Moment>)</Typography>
          </MDBox>
        }
        if(view === VIEWS.SCHEDULED_VIEW){
          if (message?.inbound === false && message?.status === 'scheduled') {
            return (
              <SingleRightMessage
                user={message?.sendByUser}
                setMessageState={setMessageState}
                check={activeCheckbox[currentActiveGroup?.groupId]?.selected}
                onEventPerform={onEventPerFormInMessageComponent}
                key={message.id + index}
                message={message}
                rightChatPanelNavBarChecked={rightNavChecked}
                searchActive={inlineSearch}
                CheckboxSelected={isCheckboxSelected}
              />
            );
          }
          else return <></>
        }
        else{
          if (message?.inbound === true) {
            return (
              <SingleLeftMessage
                user={message?.sendByUser}
                check={activeCheckbox[currentActiveGroup?.groupId]?.selected}
                onEventPerform={onEventPerFormInMessageComponent}
                key={message.id + index}
                message={message}
                showReadBy= {showReadBy}
                rightChatPanelNavBarChecked={rightNavChecked}
                searchActive={inlineSearch}
                CheckboxSelected={isCheckboxSelected}
              />
            );
          } else {
            return (
              <SingleRightMessage
                user={message?.sendByUser}
                setMessageState={setMessageState}
                check={activeCheckbox[currentActiveGroup?.groupId]?.selected}
                key={message.id + index}
                message={message}
                searchActive={inlineSearch}
                onEventPerform={onEventPerFormInMessageComponent}
                rightChatPanelNavBarChecked={rightNavChecked}
                CheckboxSelected={isCheckboxSelected}
              />
            );
          }
    
        }
      },[messages,activeCheckbox,currentActiveGroup?.groupId,inlineSearch,isCheckboxSelected,rightNavChecked,setMessageState,timezone,view]);
    

    return(
        <>
          <MainChatPanel id="scrollableDiv" ref={scrollRef}>
              <MessageWrapper>
                  {messages?.length > 0 ? (
                      <MessageChat>
                      {messages?.map((msg,index) => formatMessages(msg,index))}
                      </MessageChat>
                  ) : (
                      <NoContent style={{height:'100%'}} heading={"h5"} message={AppConstants.BROADCAST_NO_MESSAGE} />
                      )}
              </MessageWrapper>
          </MainChatPanel>        
        </>
    )
}
export default memo(GConversation);