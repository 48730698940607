import { Add } from "@mui/icons-material";
import { Button, Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { assignConversation } from "api/conversations";
import { getUserListOfAssignedPhone } from "api/phoneCredentials";
import CreateCompanyGroup from "components/Chat/CompanyGroup/CreateCompanyGroup";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import { memo } from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CompanyGroupAPI } from "services/Chat/CompanyGroupAPI";
import { chatAction } from "store/chat-slice";
import { messageAction } from "store/message-slice";
import AppModal from "ui/AppModal";
import CustomSelect from "ui/Select";

const AssignRecipient = ({currentActivePhone,recipient,onAssignmentDone}) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const companyId = useSelector(state => state.user.company.companyId);
    const {sendRequest : getUserList , data , status , error} = useHttp(getUserListOfAssignedPhone,false,false);
    const [usrList , setUsrList] = useState([]);
    const [createNewGp, setCreateNewGp] = useState(undefined);
    const [teleGroups,setTeleGroups] = useState([]);

    const {sendRequest : assignConversationFn, status: conversationAssignStatus, data : conversationAssignData, error : conversationAssignErr} = useHttp(assignConversation,false);
    const [assignUser, setAssignUser] = useState(undefined);
    // const {sendRequest : getUserNotAssignedPhoneFn, data, status , error} = useHttp(getUserNotAssignedPhone,false);
    
    const handleChange = (e) => {
        const value = e.target.value;
        setAssignUser(value);
    }

    const onGroupCreated = (g) => {
        setTeleGroups(prev => [g,...prev])
        setCreateNewGp(undefined);
    }

    const onFormSubmitHandler = useCallback((event) => {
        event.preventDefault();
        if(assignUser === '' || assignUser === undefined){
            return;
        }
        const payload = {
            // user_id : assignUser,
            phone_id : currentActivePhone?.phoneId,
            recipient_id : recipient?.id,
            token : user.token,
            assigned_by : user.id
        }
        if(assignUser.userId){
            payload.user_id = assignUser.userId
        }
        else{
            payload.group_id = assignUser.id
        }   
        assignConversationFn(payload);
    },[user,assignUser,currentActivePhone])

    useEffect(() => {
        getUserList({phoneId: currentActivePhone.phoneId,token : user.token});
    },[])

    useEffect(() => {
        if(status === 'completed'){
            if(data){
               setUsrList(data);
            }
            else if(error){
                toast.error(error);
            }
        }
    },[status,data,error]);

    useEffect(() => {
        if(conversationAssignStatus === 'completed'){
            if(conversationAssignData){
                toast.success(conversationAssignData.message);
                onAssignmentDone(conversationAssignData);
                dispatch(messageAction.addConversation({message : conversationAssignData, recipientId : recipient.id}));
                dispatch(chatAction.updateLatestMessage({message : conversationAssignData, recipientId : recipient.id}));
                dispatch(chatAction.updateAssignTo({user:  conversationAssignData.sendByUser,recipientId : conversationAssignData.sendTo.id}));
            }
            else if(conversationAssignErr){
                toast.error(conversationAssignErr);
            }
        }
    },[conversationAssignStatus,conversationAssignData,conversationAssignErr]) 
    
    useEffect(() => {
        if(companyId && user.token) {
            CompanyGroupAPI.getCompanyGroups({token : user.token,companyId}).then(res => {
                setTeleGroups(res.data);
            }).catch(e => {

            })
        }
    },[companyId,user.token]);

    return(
        <>
            <Card>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox
                        component="form"
                        role="form"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <FormControl fullWidth>
                            <InputLabel id="assignment-label">{"Choose user or group"}</InputLabel>
                            <CustomSelect
                                labelId = "assignment-label"
                                id = "choose-assignment"
                                label = "Choose user or group"
                                style={{ width: "300px", height: "40px" }}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {usrList?.map((item) => {
                                    return(
                                        <MenuItem key={item.userId} value={item}>
                                            {item.email}
                                            {item.userId === user.id &&<p>(me)</p>}
                                        </MenuItem>  
                                    )
                                })}
                                <MenuItem value="">
                                    <MDBox display="flex" justifyContent="flex-end">
                                        <Button onClick={() => setCreateNewGp(usrList)}><Add/> Create new group</Button>
                                    </MDBox>                             
                                </MenuItem>
                                {teleGroups?.map(item => {
                                    return(
                                        <MenuItem key={item.id} value={item}>
                                            {`Group - `}{item.name}
                                        </MenuItem>  
                                    )
                                })}                  
                            </CustomSelect>
                        </FormControl>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                        <MDButton  onClick={onFormSubmitHandler} variant="gradient" color="info" fullWidth>
                            assign
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Card>
            {
                Boolean(createNewGp) && 
                <AppModal
                    heading = {'Create new group'}
                    onModalClose={() => setCreateNewGp(undefined)}
                >
                    <CreateCompanyGroup givenUsers={createNewGp} onGroupCreated={onGroupCreated}/>
                </AppModal>
            }
        </>
    )
}
export default memo(AssignRecipient);