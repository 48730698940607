import CreateUserForm from "components/User/CreateUserForm";
import { useState } from "react";
import AssignPhoneV2 from "./AssignPhoneV2";

const UserPhoneAssignFlowWrapper = ({onCreateUserHandler,setIsRegister}) => {

    const [showPhoneMapping,setShowPhoneMapping] = useState(false);

    return(
        <>
          {!showPhoneMapping && <CreateUserForm onCreate={(res) => {
            onCreateUserHandler(res);
            setShowPhoneMapping(true);
          }} />}    
          {showPhoneMapping && <AssignPhoneV2 onClose={ () => setIsRegister(false)}/>}    
        </>
    )
}
export default UserPhoneAssignFlowWrapper;