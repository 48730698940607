import { Card, Checkbox, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import { processPayment } from "api/payment";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {ReactComponent as SuccessSvg} from "assets/svg/success.svg";
import {ReactComponent as ErrorSvg} from "assets/svg/error.svg";
import { useParams } from "react-router-dom";
import brandLogo from "assets/images/logos/e.png";
import { getFundraiserById } from "api/fundraiser";
import ReCAPTCHA from "react-google-recaptcha";
import GoalCompletionUI from "components/CommonUI/GoalCompletionUI";
import { getPercentage } from "util/UtilityFunctions";
import { toast } from "react-toastify";
const CardKnoxProcessor = () => {
    
    const{campaignId, fundraiserId} = useParams();

    const [recurringPayment, setRecurringPayment] = useState({
      recurring : false,
      end : 'never',
      number_of_payments : 12,
      frequency : 1
    });
    
    const [campaign, setCampaign] = useState();
    const [fundraiser,setFundraiser] = useState({});
    const [paymentInput, setPaymentInput] = useState({
        xName : '',
        xCardNum: '',
        xExp: '',
        xAmount : '',
        donor_phone : '',
        xEmail : '',
        xCVV : '',
    });
    const [isVerified, setIsVerified] = useState(false);
    
    const  handleRecaptcha = () => {
      setIsVerified(true);
    }
    const {sendRequest, data, status, error} = useHttp(processPayment,false);
    const {sendRequest : getFundraiserFn, data : fundraiserData, status : fundraiserStatus} = useHttp(getFundraiserById,false)
    const token = useSelector(state => state.user.token);
    
    const onPaymentSubmit = useCallback(() => {
      if(paymentInput.xAmount === '' || paymentInput.xCardNum === '' || paymentInput.xCVV === ''){
        toast.error("Please fill the form");
        return;
      }
      const newInput = {
        ...paymentInput,
        recurring_payment : {...recurringPayment},
        fundraiser : {...fundraiser,phones : {phone : fundraiser?.assigned_phone?.phone}},
        campaign  : {...campaign,company : {
          companyId : campaign.company.company_id
        }},
        fundraiser_name : fundraiser?.fundraiser_name? fundraiser?.fundraiser_name : 'Campaign'
      }
      sendRequest({
        payload : newInput,
        token
      })
    },[campaign,fundraiser,paymentInput,recurringPayment])

    useEffect(() => {
      const decoded = atob(fundraiserId);
      getFundraiserFn(decoded);
    },[])

    useEffect(() => {
      document.title = 'WayMore Payments';
    }, []);

    useEffect(() => {
      if(fundraiserStatus === 'completed'){
        if(fundraiserData){
          setFundraiser(fundraiserData);
          setCampaign(fundraiserData?.campaign);
        }
      }
    },[fundraiserStatus,fundraiserData])
    return (
      <>
        <MDBox
          display="flex"
          flexDirection="column"
          sx={{ height: "100vh", overflowY: "hidden" }}
        >
          <MDBox height="80px" width="100%" sx={{ backgroundColor: "#f5f5f5" }} display="flex" justifyContent="space-between">
            <MDBox display="flex" height="100%" flexDirection="row">
              <MDBox
                display="flex"
                ml={4}
                p={4}
                flexDirection="row"
                alignItems="center"
              >
                <img
                  src={brandLogo}
                  style={{ width: "40px", height: "40px" }}
                />
                <Typography variant="h4">
                  Crowdfunding Platform
                  <Typography
                    sx={{
                      fontSize: "0.65rem",
                      height: "0px",
                      lineHeight: "unset!important",
                    }}
                  >
                    Powered by BLIQ
                  </Typography>
                </Typography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
                <Typography sx={{padding:'12px'}}>{campaign?.company?.company_name}</Typography>
              </MDBox>
          </MDBox>
          <MDBox sx={{backgroundColor:'#f9f9f9'}}>
            <MDBox display="flex" flexDirection="row" p={6} sx={{}}>
              <MDBox
                width="50%"
                display="flex"
                justifyContent="center"
                // alignItems="center"
              >
                <MDBox display="flex" alignItems="start" flexDirection="column">
                  <Card sx={{padding:'22px'}}>
                    <Typography sx={{margin:'16px'}} variant="h4">
                      {campaign?.campaign_name}
                    </Typography>
                    {campaign && <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                      <Typography sx={{padding:'8px'}} fontSize={'14px'}>Campaign goal raised</Typography>
                      <GoalCompletionUI  value={getPercentage(campaign.raised_so_far, campaign.goal_amount)} color={"info"}/>
                      </MDBox>}
                    <MDBox display="flex" flexDirection="column">
                      <MDBox sx={{margin:'16px'}}>
                        <Typography variant="h5">
                          Welcome to {fundraiser?.fundraiser_name}
                          {"'s"} donation page
                        </Typography>
                      </MDBox>
                    </MDBox>
                    {fundraiserData && <MDBox display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                      <Typography  sx={{padding:'8px'}} fontSize={'14px'}>Fundraiser goal raised</Typography>
                      <GoalCompletionUI  value={getPercentage(fundraiserData?.raised_so_far, fundraiserData?.goal_amount)} color={"success"}/>
                    </MDBox>}
                  </Card>

                  {status === "completed" && data && (data?.xresult === "E" || data?.Result === "E") && (
                    <MDBox
                      sx={{ padding: "18px" }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Typography>{data?.xerror}</Typography>
                      <MDBox sx={{ marginTop: "12px" }}>
                        <ErrorSvg />
                      </MDBox>
                    </MDBox>
                  )}
                  {status === "completed" && data && (data?.xresult === "A" || data?.result === 'S' || data?.result === "s") && (
                    <MDBox
                      sx={{ padding: "18px" }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Typography>
                        Payment successful. Thank you for your donation.
                      </Typography>
                      <MDBox sx={{ marginTop: "12px" }}>
                        <SuccessSvg />
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
              <MDBox sx={{ marginRight: "12px" }}>
                <Divider
                  orientation="vertical"
                  component="div"
                  sx={{
                    "&.MuiDivider-root": {
                      backgroundColor: "#e9d5e9",
                      height: "100%",
                    },
                  }}
                />
              </MDBox>
              <MDBox
                width="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MDBox
                  sx={{
                    overflowY: "auto",
                    height: "calc(100vh - 115px)",
                    padding: "12px",
                    "&::-webkit-scrollbar": {
                      width: "0.3em",
                      height: "0.4em",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "4px",
                      outline: "1px solid slategrey",
                      backgroundColor: "#8b8b8b",
                    },
                  }}
                >
                  <Card sx={{ padding: "22px", marginBottom: "16px" }}>
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        marginBottom: "12px",
                      }}
                    >
                      Secure Payment Form{" "}
                    </Typography>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Name"
                        fullWidth
                        onChange={(e) => {
                          setPaymentInput((prev) => {
                            return {
                              ...prev,
                              xName: e.target.value,
                            };
                          });
                        }}
                      />
                      <MDTypography
                        variant="overline"
                        fontWeight="medium"
                        color="error"
                        mt={1}
                      >
                        {}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Phone Number"
                        fullWidth
                        onChange={(e) => {
                          setPaymentInput((prev) => {
                            return {
                              ...prev,
                              donor_phone: e.target.value,
                            };
                          });
                        }}
                      />
                      <MDTypography
                        variant="overline"
                        fontWeight="medium"
                        color="error"
                        mt={1}
                      >
                        {}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        label="Email"
                        fullWidth
                        onChange={(e) => {
                          setPaymentInput((prev) => {
                            return {
                              ...prev,
                              xEmail: e.target.value,
                            };
                          });
                        }}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={{ width: "100%" }}
                        placeholder="Amount"
                        onChange={(e) => {
                          setPaymentInput((prev) => {
                            return {
                              ...prev,
                              xAmount: e.target.value,
                            };
                          });
                        }}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPaymentInput((prev) => {
                            return {
                              ...prev,
                              xCardNum: e.target.value,
                            };
                          });
                        }}
                        placeholder={"Card Number"}
                      />
                      <MDTypography
                        variant="overline"
                        fontWeight="medium"
                        color="error"
                        mt={1}
                      >
                        {}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2} display="flex">
                      <MDBox>
                        <MDInput
                          placeholder="MM/YY"
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xExp: e.target.value,
                              };
                            });
                          }}
                        />
                        <MDTypography
                          variant="overline"
                          fontWeight="medium"
                          color="error"
                          mt={1}
                        >
                          {}
                        </MDTypography>
                      </MDBox>
                      <MDBox ml={2}>
                        <MDInput 
                          placeholder="Cvv"
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xCVV: e.target.value,
                              };
                            });
                          }}
                        />
                        {/* <MDInput
                          type="Number"
                          label="Cvv"
                          fullWidth
                          onChange={(e) => {
                            setPaymentInput((prev) => {
                              return {
                                ...prev,
                                xCVV: e.target.value,
                              };
                            });
                          }}
                        /> */}
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <Checkbox
                        value={recurringPayment.recurring}
                        onChange={(e) => {
                          setRecurringPayment((prev) => {
                            return {
                              ...prev,
                              recurring: e.target.checked,
                            };
                          });
                        }}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Recurring donation&nbsp;
                      </MDTypography>
                    </MDBox>
                    {recurringPayment.recurring && (
                      <MDBox display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          flexDirection="row"
                          mt={2}
                          alignItems="center"
                        >
                          <Typography
                            variant="button"
                            fontWeight={"400"}
                            color="text"
                          >
                            Every
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={recurringPayment.frequency}
                            onChange={(e) => {
                              setRecurringPayment(prev => {
                                return {
                                  ...prev,
                                  frequency : e.target.value
                                }
                              })
                            }}
                            sx={{
                              height: "24px",
                              marginLeft: "12px",
                              width: "48px",
                              "& .MuiOutlinedInput-root": {
                                height: "32px!important",
                              },
                            }}
                          />
                          <Select
                            sx={{
                              width: "150px",
                              marginTop: "8px",
                              height: "32px!important",
                              marginLeft: "12px",
                            }}
                            value={"month"}
                          >
                            <MenuItem value="month">Month(s)</MenuItem>
                          </Select>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" mt={2}>
                          <MDBox display="flex" flexDirection="column">
                            <Typography
                              variant="button"
                              fontWeight={"400"}
                              color="text"
                            >
                              End
                            </Typography>
                            <Select
                              value={recurringPayment.end}
                              onChange={(e) => {
                                setRecurringPayment((prev) => {
                                  return {
                                    ...prev,
                                    end: e.target.value,
                                  };
                                });
                              }}
                              sx={{
                                height: "42px",
                                "& .MuiSvgIcon-root": {
                                  display: "inline-block",
                                  fontSize: "24px!important",
                                },
                              }}
                            >
                              <MenuItem value="never">Never</MenuItem>
                              <MenuItem value="number_of_payments">
                                Number of Payments{" "}
                              </MenuItem>
                            </Select>
                            {recurringPayment.end === "number_of_payments" && (
                              <TextField
                                sx={{
                                  marginTop: "12px",
                                }}
                                value={recurringPayment.number_of_payments}
                                onChange={(e) => {
                                  setRecurringPayment((prev) => {
                                    return {
                                      ...prev,
                                      number_of_payments: e.target.value,
                                    };
                                  });
                                }}
                                type="number"
                              />
                            )}
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    )}
                    <MDBox mt={2} display="flex" justifyContent="center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={handleRecaptcha}
                      />
                    </MDBox>
                    <MDBox mt={2} mb={1} onClick={onPaymentSubmit}>
                      <MDButton
                        type="button"
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        Donate
                      </MDButton>
                    </MDBox>
                  </Card>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </>
    );
}
export default CardKnoxProcessor;