import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCampaign } from "api/campaign";
import { memo } from "react";
import { useCallback } from "react";
import { validateDate } from "util/UtilityFunctions";

const CreateCampaignForm = ({ onCreate }) => {
  const {
    sendRequest: createCampaignFn,
    status,
    data,
    error,
  } = useHttp(createCampaign, false);

  const currUser = useSelector((state) => state.user);
  const [campaignFormValidation, setCampaignFormValidation] = useState({
    nameValidation : '',
    goalAmtValidation : '',
    dateValidation : ''
  })
  const nameRef = useRef();
  const goalAmountRef = useRef();
  const lastDateRef = useRef();
  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    const fullName = nameRef.current.value;
    const goalAmount = goalAmountRef.current.value;
    const lastDate = lastDateRef.current.value;

    if(fullName === "") {
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          nameValidation : 'Title can not be empty.'
        }
      });
      return;
    }
    if (!goalAmount || goalAmount.length === 0 || !(/^[0-9]*$/).test(goalAmount) || goalAmount > 10000000 || goalAmount <= 0) {
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          goalAmtValidation : 'Set a valid goal amount.'
        }
      })
      return;
    }
    if(!lastDate || lastDate.length === 0 || !validateDate(lastDate)){
      setCampaignFormValidation(prev => {
        return{
          ...prev,
          dateValidation : 'Completion date is required.'
        }
      })
      return;
    }
    let campaign = {
      payload: {
        campaign_name: fullName,
        goal_amount: goalAmount,
        completion_date: lastDate,
        company_id: currUser.company.companyId,
      },
      token: currUser.token,
    };
    createCampaignFn(campaign);
  },[currUser]);

  useEffect(() => {
    if(status === 'completed'){
      if(data)
        onCreate(data);
      else
        toast.error("Unable to create, Please try after sometime.")  
    }
  },[status,data,error])
  return (
    <MDBox width="100%" display="flex" flexDirection="column">
      <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Title"
              variant="standard"
              fullWidth
              inputRef={nameRef}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
            mt={1}
          >
            {campaignFormValidation.nameValidation}
          </MDTypography>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Goal amount"
              variant="standard"
              fullWidth
              inputRef={goalAmountRef}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
          >
            {campaignFormValidation.goalAmtValidation}
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">Select completion date: </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="date"
              label=""
              variant="standard"
              fullWidth
              inputRef={lastDateRef}
            />
          </MDBox>
          <MDTypography
            variant="overline"
            fontWeight="medium"
            color="error"
          >
            {campaignFormValidation.dateValidation}
          </MDTypography>          
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
              CREATE
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default memo(CreateCampaignForm);
