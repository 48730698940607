import MDButton from "lib/components/MDButton";
import { Link } from "react-router-dom";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import useHttp from "hooks/use-http";
import { resetCode } from "api/user";
import { toast } from "react-toastify";

const ForgotPasswordEmail = ({email,onEmailSuccess}) => {
  const emailRef = useRef();

  const [emailErr, setEmailErr] = useState("");
  const [uemail,setuemail] = useState("");

  const {sendRequest , data , status , error} = useHttp(resetCode,false);



  const onEmailSubmitHandler = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    setuemail(email);
    //.*@.*[.].*
    if(!(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(email)){
      setEmailErr("please enter a valid email.");
      return;
    }
    sendRequest({email});
  };
  useEffect(() =>{
    if(emailRef.current){
       emailRef.current.value = email
    }
  },[])
  useEffect(() => {
    if(status === 'completed'){
      if(error === null){
        onEmailSuccess(uemail);
        toast.success(data);
      }
      else{
        setEmailErr(error);
        return;
      }
    }
  },[status,error])

  return (
      <MDBox pt={4} pb={3} px={3} onSubmit={onEmailSubmitHandler}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput type="email" label="Email" fullWidth inputRef={emailRef} />
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDTypography
              variant="subtitle2"
              fontWeight="regular"
              color="error"
              mt={1}
            >
              <span>{emailErr}</span>
            </MDTypography>
          </MDBox>          
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
              submit
            </MDButton>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDTypography
              variant="subtitle2"
              fontWeight="regular"
              color="error"
              mt={2}
            >
              <Link to="/login">Back to login</Link>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
  );
};
export default ForgotPasswordEmail;
