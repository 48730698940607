import { Undo, Upload } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { ImportChatAPI } from "api/importChat";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import MDBox from "lib/components/MDBox";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appAction } from "store/app-slice";
import { chatAction } from "store/chat-slice";
import { loaderAction } from "store/loader-slice";
import { messageAction } from "store/message-slice";
import Swal from "sweetalert2";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";
import WhatsappMediaImport from "./WhatsappMediaImport";

const columns = [
    {id : 1, key:'sno',label:'Sno'},
    {id : 2, key : 'imported_at', label: 'Imported At'},
    {id : 3, key : 'type', label: 'Import Type'},
    {id : 4, key : 'action', label: 'Action'}
]
const ImportHistory = ({onUndo}) => {

    const dispatch = useDispatch();
    const timezone = useSelector(state => state.userDetails?.details?.userSettings?.timezone)
    const currUser = useSelector(state => state.user);
    const [histories,setHistories] = useState([]);
    const [rows,setRows] = useState([]);
    
    // const reimportChat = (importId) => {
    //     Swal.fire({
    //         title: 'Are you sure want to reimport?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes',
    //         customClass: {
    //           container : {
    //             zIndex : '10000 !important'
    //           }
    //         }
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //             // First undo the given import, then try reimport....
    //         }
    //     })
    // }

    const undoImport = useCallback((importId) => {
        Swal.fire({
            title: 'Are you sure want to undo this import?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
              container : {
                zIndex : '10000 !important'
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {                
                dispatch(loaderAction.show());
                ImportChatAPI.undoImport({token: currUser.token,importId}).then(res => {
                    const filterHistory = histories.filter(itm => itm.import_id != importId);
                    setHistories(filterHistory);
                    dispatch(loaderAction.hide());
                    dispatch(chatAction.resetChats());
                    dispatch(messageAction.resetChats());
                    toast.success("Import undone successfully");
                    onUndo();
                }).catch(e => {
                    dispatch(loaderAction.hide());
                    toast.error(e.response.data.message)
                })
            }
        })
    },[histories,dispatch]);

    
    useEffect(() => {   
        ImportChatAPI.getImportHistory({token : currUser.token}).then(res => {
            setHistories(res.data);
        }).catch(e => console.log(e));
    },[]);


    useEffect(() => {
        if(histories){
            const formattedValue = histories.map((itm,indx) => {
                return{
                    sno : indx + 1,
                    imported_at : <Moment tz={timezone} format="lll">{getDateConvertedFromUTC(itm?.createdAt)}</Moment>,
                    type: <Typography fontSize={'14px'}>{Boolean(itm.import_type) ? itm.import_type : 'sms'}</Typography>,
                    action : <MDBox display="flex" p={2}>
                        <Tooltip title={"Undo this import"}>
                            <Undo sx={{cursor:'pointer'}} onClick={() => undoImport(itm.import_id)}/>
                        </Tooltip>
                        {itm.import_type === 'whatsapp' && <>
                            <Tooltip title={"Upload media for this import"}>
                                <Upload sx={{cursor:'pointer',marginLeft:'12px'}} onClick={() => {
                                    const r = {
                                        report : false,
                                        import_id : itm.import_id
                                    }
                                    dispatch(appAction.setChatDynamicComponent(<WhatsappMediaImport result={r}/>))
                                    dispatch(appAction.setSnackbar(undefined));  
                                }}/>
                            </Tooltip>
                        </>}
                    </MDBox>
                }
            })
            setRows(formattedValue);
        }
    },[histories]);

    return(
        <>
            <EnhancedTable
                rows={rows}
                heading={"Imports"}
                columns={columns}
                showFilter={false}
                selectEnabled={false}
            />
        </>
    )
}
export default ImportHistory;