import { Divider, Tab, Tabs, Typography } from "@mui/material";
import { setTermsAndConditions } from "api/poolSettings";
import { setGeneralPrivacySettings } from "api/poolSettings";
import { getGeneralPrivacySettings } from "api/poolSettings";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AppTextEditor } from "ui/AppTextEditor";
import SecondFactorAuthSettings from "./2FA/SecondFactorAuthSettings";
import { SystemSettingsAPI } from "services/SystemSettingsAPI";
import AppConstants from "constants/AppConstants";

const SETTING_VIEW = {
  PRIVACY_POLICY : 'PRIVACY_POLICY',
  TERMS_CONDITIONS : 'TERMS_CONDITIONS',
  SECOND_FACTOR_AUTH : 'SECOND_FACTOR_AUTH'
}
const CompanyWidePolicy = () => {

    // This data is from mongoDB, we should completely move all the super admin settings to mongo
    // Right now not all are moved... only 2FA is getting saved....
    const [adminSettings,setAdminSettings] = useState();

    const [tab, setTab] = useState(0);
    const [activeView,setActiveView] = useState(SETTING_VIEW.PRIVACY_POLICY);
    const currUser = useSelector(state => state.user);
    const{sendRequest,status,data,error} = useHttp(getGeneralPrivacySettings,false);
    const{sendRequest : setPrivacyPolicyFn, status : updateStatus,data : updatedData,error : updateErr} = useHttp(setGeneralPrivacySettings,false);
    const{sendRequest : setTermsAndConditionsFn, status : termsUpdate,data : termsUpdateData,error : termsErr} = useHttp(setTermsAndConditions,false);
    
    const [currentData,setCurrentData] = useState();

    const handleSetTabValue = (event, newValue) => {
      setTab(newValue);
    }
  
    const onAuthUpdated = (res) => {
      setAdminSettings(res);
    }

    const onPolicySaved = (html) => {
        const payload = {
          privacyPolicy : html
        }
        try{
          setPrivacyPolicyFn({token : currUser.token,privacyPolicy : payload});
        }
        catch(e){}
        
        setCurrentData(prev => {
          return {
            ...prev,
            privacyPolicy : html
          }
        })
    }

    const onTermsAndConditionsSaved = (html) => {
        const payload = {
          appTermsConditions : html
        }
        setTermsAndConditionsFn({token : currUser.token,termsAndConditions : payload});
        setCurrentData(prev => {
          return{
            ...prev,
            appTermsConditions : html
          }
        })
    }

    useEffect(() => {
      if(tab === 0){
        setActiveView(SETTING_VIEW.PRIVACY_POLICY);
      }
      else if(tab === 1){
        setActiveView(SETTING_VIEW.TERMS_CONDITIONS);
      }
      else if(tab === 2){
        setActiveView(SETTING_VIEW.SECOND_FACTOR_AUTH);
      }
    },[tab]);

    useEffect(() => {
        sendRequest(currUser.token);
        SystemSettingsAPI.getAdminSettings({token : currUser.token}).then(res => {
          setAdminSettings(res.data);
        }).catch(e => console.log(e));
    },[]);

    useEffect(() => {
      if(status === 'completed'){
        if(data){
          setCurrentData(data);
        }
        else{
          toast.error(error);
        }
      }
    },[status,data,error]);

    useEffect(() => {
        if(updateStatus === 'completed'){
            if(updatedData){
                toast.success(AppConstants.UPDATED_SUCCESS);
            }
        }
    },[updateErr,updateStatus,updatedData]);

    useEffect(() => {
      if(termsUpdate === 'completed'){
          if(termsUpdateData){
              toast.success(AppConstants.UPDATED_SUCCESS);
          }
      }
  },[termsUpdateData,termsUpdate,termsErr]);

    return (
      <MDBox display="flex" flexDirection="column" width="100%">
        <MDBox mt={2}>
          <Tabs
            value={tab}
            onChange={handleSetTabValue}
          >
              <Tab label="Privacy policy"/>
              <Tab label="Terms and Conditions"/>
              <Tab label="2FA Settings"/>   
            </Tabs>
        </MDBox>
        {
          activeView === SETTING_VIEW.TERMS_CONDITIONS && 
          <>
            <MDBox width="100%" display="flex" justifyContent="center" mt={2}>
              <Typography variant="h5"> Set Terms and Conditions</Typography>
            </MDBox>
            <MDBox
              m={2}
              sx={{
                border: "2px dashed #c9c5c5",
                padding: "16px",
                height: "500px",
              }}
            >
              {currentData && <AppTextEditor onPolicySaved={onTermsAndConditionsSaved} initialContent={currentData.appTermsConditions}/>}
            </MDBox>          
          </>
        }
        {activeView === SETTING_VIEW.PRIVACY_POLICY && <>
          <MDBox width="100%" display="flex" justifyContent="center" mt={2}>
            <Typography variant="h5">Set Privacy Policy Default Template</Typography>
          </MDBox>
          {/* <MDBox width="100%" display="flex" justifyContent="center">
              <Typography fontSize={"12px"}>
                  visit{" "}
                  <a
                  href={ `${window.location.origin}/t&c/template`}
                  target="_blank"
                  >
                  {`${window.location.origin}/t&c/template`}
                  </a>
              </Typography>
          </MDBox> */}
          <MDBox
            m={2}
            sx={{
              border: "2px dashed #c9c5c5",
              padding: "16px",
              height: "500px",
            }}
          >
            {currentData && <AppTextEditor onPolicySaved={onPolicySaved} initialContent={currentData.privacyPolicy}/>}
          </MDBox>
        </>}
        {activeView === SETTING_VIEW.SECOND_FACTOR_AUTH &&
          <MDBox display="flex" flexDirection="row" width="100%">
            <SecondFactorAuthSettings onUpdated={onAuthUpdated} adminSettings={adminSettings}/>
            <Divider variant="vertical" component="div" sx={{height:'auto'}}/>
          </MDBox> 
        }
      </MDBox>
    );
}
export default CompanyWidePolicy;