import { List, ListItem, Popover } from "@mui/material";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { ConversationAPI } from "services/ConversationAPI";
import { toast } from "react-toastify";
import { messageAction } from "store/message-slice";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatAction } from "store/chat-slice";
import AppModal from "ui/AppModal";
import EditContact from "./EditContact";

const RECIPIENT_ACTIONS = {
    EDIT: 'edit'
}
const RecipientMenu = ({ targetEle, recipient, setAssignRecipient, onEventPerform }) => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);
    const currentUser = useSelector(state => state.userDetails.details);
    const currentActivePhone = useSelector(state => state.chat.current_active_phone);

    const [anchorEl, setAnchorEl] = useState(targetEle);
    const [action, setAction] = useState(undefined);

    const handleClose = () => {
        setAnchorEl(null);
        onEventPerform('POPOVER_CLOSED');
    };

    const blockRecipient = useCallback((id) => {
        //call endpoint to, Block the given recipient
        handleClose();
        RecipientAPI.blockRecipient({ recipientId: id, token, companyId: currentUser?.company?.companyId }).then(res => {
            toast.success(res.data);
            //dispatch block action
            dispatch(chatAction.blockContact(id));
        }).catch(e => console.log(e));
    }, [currentUser])

    const unblockRecipient = useCallback((id) => {
        // Inblock the recipient
        handleClose();
        RecipientAPI.unblockRecipient({ recipientId: id, token, companyId: currentUser?.company?.companyId }).then(res => {
            toast.success("Unblocked successfully.");
            // dispatch to mark as unblocked:
            dispatch(chatAction.unblockRecipients(res.data));
        }).catch(e => console.log(e));
    }, [currentUser])

    const toggleMute = (recipientId, muted) => {
        handleClose();
        RecipientAPI.toggleMute({ token, muted, recipientId });
        dispatch(chatAction.toggleMute({ muted, recipientId }));
    }

    const unassignRecipient = useCallback((recipient) => {
        console.log(recipient)
        const payload = {
            recipient_id: recipient.id,
            phone_id: currentActivePhone?.phoneId,
            user_id: currentUser.userId,
            group_id : recipient?.currentAssignedGroup ? recipient?.currentAssignedGroup.id : undefined
        }
        ConversationAPI.unassignConversation({ payload, token }).then(res => {
            toast.success("Conversation unassigned.");
            handleClose();
            dispatch(messageAction.addConversation({ message: res.data, recipientId: recipient.id }));
            dispatch(chatAction.updateLatestMessage({ message: res.data, recipientId: recipient.id }));
            dispatch(chatAction.updateAssignTo({ user: null, recipientId: res.data.sendTo.id }));
        }).catch(err => console.log(err));

    }, [currentActivePhone, currentUser, token]);

    const editRecipientHandler = (recipient) => {
        setAction(RECIPIENT_ACTIONS.EDIT);
    }

    useEffect(() => {
        setAnchorEl(targetEle);
    }, [targetEle])

    return (
        <>
            <Popover
                onClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose()
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        backgroundColor: "white!important",
                        display: 'flex',
                        width: 'auto',
                        justifyContent: 'center',
                        border: 'solid 1px #ddd',
                        padding: '12px',
                        boxShadow: '-4px 2px 6px 0px #979797',
                        userSelect:'none'
                    },
                }}
            >
                <List>
                    <ListItem
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            editRecipientHandler(recipient)
                        }}
                        sx={{
                            fontSize: '14px',
                            cursor: 'pointer'
                        }}>
                        <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Edit contact'}</span>
                    </ListItem>
                    {(!recipient?.currentAssignedTo && !recipient?.currentAssignedGroup) ?
                        <ListItem
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClose();
                                console.log('recipient', recipient)
                                setAssignRecipient(recipient);
                            }}
                            sx={{
                                fontSize: '14px',
                                cursor: 'pointer'
                            }}>
                            <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Assign conversation'}</span>
                        </ListItem>
                        :
                        <ListItem
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClose();
                                unassignRecipient(recipient);
                            }}
                            sx={{
                                fontSize: '14px',
                                cursor: 'pointer'
                            }}>
                            <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Unassign conversation'}</span>
                        </ListItem>
                    }
                    {
                        recipient?.muted ?
                            <ListItem onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleMute(recipient.id, false);
                            }} sx={{ fontSize: '14px', cursor: 'pointer' }}>
                                <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Unmute'}</span>
                            </ListItem> :
                            <ListItem onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleMute(recipient.id, true);
                            }} sx={{ fontSize: '14px', cursor: 'pointer' }}>
                                <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Mute'}</span>
                            </ListItem>
                    }
                    {!recipient?.contactBlocked ?
                        <ListItem sx={{
                            fontSize: '14px',
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            blockRecipient(recipient.id)
                        }}>
                            <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Block'}</span>
                        </ListItem>
                        :
                        <ListItem sx={{
                            fontSize: '14px',
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            unblockRecipient(recipient.id)
                        }}>
                            <span style={{ fontSize: '14px !important', fontWeight: '400' }}>{'Unblock'}</span>
                        </ListItem>
                    }
                </List>
            </Popover>
            {action === RECIPIENT_ACTIONS.EDIT && <AppModal
                onModalClose={() => { }}
                heading={"Edit contact"}
            >
                <EditContact recipient={recipient} onEdited={() => {
                    setAction(undefined);
                    handleClose();
                }} />
            </AppModal>}
        </>
    )
}
export default memo(RecipientMenu);