import {InputLabel, MenuItem, Select, Typography} from "@mui/material";
import { updateFundraisers } from "api/fundraiser";
import { getActivePhoneList } from "api/numberpool";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditFundraiserForm = ({fundraiser,onFundraiserEdited,campaignId}) => {

  const currUser = useSelector(state => state.user);
  const [activePhones, setActivePhones] = useState([]);

  const [editData, setEditData] = useState();
  const [fundRaiserFormValidation,setFundRaiserFormValidation] = useState({
    nameValidation: '',
    goalAmtValidation : '',
  });
  const [assignedPhone,setAssignedPhone] = useState();

  const{sendRequest : editFundraiserFn, data , status, error} = useHttp(updateFundraisers,false);
  const {sendRequest : getActivePhonesFn, data : activePhonesData, status : activePhonesDataStatus} = useHttp(getActivePhoneList,false)
  const onFormSubmit = (e) => {
    e.preventDefault();
    // const password = passwordRef.current.value;
    if(editData.fundraiser_name === "") {
      setFundRaiserFormValidation(prev => {
        return{
            ...prev,
            nameValidation : 'Title can not be empty.'
        }
      });
      return;
    }
    if (!(/^[0-9]*$/).test(editData.goal_amount)) {
      setFundRaiserFormValidation(prev => {
        return{
            ...prev,
            goalAmtValidation : 'Set a valid goal amount.'
        }
      });
      return;
    }
    /// Seems fine call the API to save the Fundraiser....
    const fundRaiser = {
      payload : {
        fundraiser_id : editData.fundraiser_id,
        fundraiser_email : editData.fundraiser_email,
        fundraiser_number : editData.fundraiser_number,
        goal_amount : editData.goal_amount,
        campaign_id : campaignId,
        fundraiser_name : editData.fundraiser_name, 
        phones : {
          ...assignedPhone
        }
      },
      token : currUser.token
    }
    editFundraiserFn(fundRaiser);      
  };

  useEffect(() => {
    if(fundraiser){
      setEditData(fundraiser);
      setAssignedPhone({
        phone : fundraiser?.phones ? fundraiser?.phones.phone : '',
        phoneId :  fundraiser?.phones ? fundraiser?.phones.phoneId : '',
      });
    }
  },[fundraiser]);

  useEffect(() => {
    const filterStr = `status=${"ACTIVE"}`;
    getActivePhonesFn({token : currUser.token,payload : filterStr});
  },[]);
  useEffect(() => {
    if(status === 'completed'){
      if(data){
        toast.success(AppConstants.UPDATED_SUCCESS);
        onFundraiserEdited(data);
      }
      else if(error){
        toast.error("something went wrong, We are not able to update this time. Please try again.");
      }
    }
  },[status, data, error])

  useEffect(() => {
    if(activePhonesDataStatus === 'completed'){
      if(activePhonesData){
        const newActivePhone = activePhonesData.map(item => {
          return {
            phone : item.phone,
            phoneId : item.phoneId
          }
        });
        setActivePhones(newActivePhone);
      }
    }
  },[activePhonesDataStatus,activePhonesData]);

  return (
        <>
          <MDBox pt={4} pb={3} px={3} onSubmit={onFormSubmit}>
            <MDBox component="form" role="form">
              <MDBox mb={1}>
                <MDInput
                  type="text"
                  label="Name"
                  variant="standard"
                  fullWidth
                  onChange = {(e) => {
                    setEditData(prev => {
                      return {
                        ...prev,
                        fundraiser_name : e.target.value
                      }
                    })
                  }}
                  value={editData?.fundraiser_name}
                />
              </MDBox>
              <MDTypography
                variant="overline"
                fontWeight="medium"
                color="error"
              >
                {fundRaiserFormValidation.nameValidation}
              </MDTypography>
              <MDBox mb={1}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange = {(e) => {
                    setEditData(prev => {
                      return {
                        ...prev,
                        fundraiser_email : e.target.value
                      }
                    })
                  }}                  
                  value = {editData?.fundraiser_email}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDInput
                  type="text"
                  label="Number"
                  variant="standard"
                  fullWidth
                  onChange = {(e) => {
                    setEditData(prev => {
                      return {
                        ...prev,
                        fundraiser_number : e.target.value
                      }
                    })
                  }}                  
                  value = {editData?.fundraiser_number}
                />
              </MDBox>              
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Goal amount"
                  variant="standard"
                  fullWidth
                  onChange = {(e) => {
                    setEditData(prev => {
                      return {
                        ...prev,
                        goal_amount : e.target.value
                      }
                    })
                  }}                  
                  value={editData?.goal_amount}
                />
              </MDBox>
              <MDTypography
                variant="overline"
                fontWeight="medium"
                color="error"
              >
                {fundRaiserFormValidation.goalAmtValidation}
              </MDTypography>
              {currUser.role === 'SUPER_ADMIN' && <>
                  <MDBox>
                    <InputLabel id="demo-select-small">Assigned phone</InputLabel>
                    {assignedPhone && <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label=""
                      sx={{
                        width: "300px",
                        marginTop: "12px",
                        height: "40px",
                        "& .MuiSvgIcon-root": {
                          display: "inline-block",
                          fontSize: "24px!important",
                        },
                        "& .MuiSelect-iconOpen": {
                          fontSize: "24px!important",
                        },
                      }}
                      value={assignedPhone}
                      onChange= { (e) => {
                        setAssignedPhone(e.target.value);
                      }}
                    >
                     {assignedPhone .phone !== '' && <MenuItem key={assignedPhone.phoneId} value={assignedPhone}><Typography sx={{display: 'block', color : 'red',marginRight:'8px'}} fontSize={'8px'}>Selected</Typography>{assignedPhone.phone}</MenuItem>}
                      {activePhonesData?.map(item => <MenuItem key={item.phoneId} value={item}>{item.phone}</MenuItem>)}
                    </Select>}
                  </MDBox>
              </>}    
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  Update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
      </>
    )
}
export default EditFundraiserForm;