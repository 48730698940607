import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useEffect, useState } from "react";

const CarrierForm = ({onSubmit,carrier}) => {

    const [carrierData, setCarrierData] = useState({
        carrier_name : '',
        http_method : 'POST',
        encoding_type : 'DEFAULT',
        content_type : 'application/json',
        max_length : 160,
        auth_val : '',
        send_url : '',
        request_body : 'text={{text}}&from={{from}}&to={{to}}',
        example_request_body : '',
        response_body : '',
        limit : 0
    }) 
    const onHttpMethodChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                http_method : e.target.value
            }
        })
    }
    const onEncodingTypeChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                encoding_type : e.target.value
            }
        })
    }
    const onContentTypeChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                content_type : e.target.value
            }
        })
    }
    const onMaxLengthChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                max_length : e.target.value
            }
        })
    }
    const onAuthChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                auth_val : e.target.value
            }
        })  
    }

    const onSendUrlChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                send_url : e.target.value
            }
        }) 
    }

    const onRequestBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                request_body : e.target.value
            }
        }) 
    }

    const onExampleRequestBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                example_request_body : e.target.value
            }
        }) 
    }

    const onResponseBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                response_body : e.target.value
            }
        }) 
    }

    const onCarrierNameChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                carrier_name : e.target.value
            }
        })
    }

    const onLimitChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                limit : e.target.value
            }
        })
    }

    const submitFormData = () => {
        onSubmit(carrierData);
    }
    useEffect(() => {
        if(carrier){
            setCarrierData(carrier);
        }
    },[carrier]);
    return (
      <MDBox margin={"12px"} display="flex" flexDirection="column" width="100%">
        <MDBox margin={'8px'}>
            <MDInput sx={{ marginRight:'8px'}}  fullWidth onChange={onCarrierNameChange} type="text" label="Carrier Name" value={carrierData.carrier_name} />
            <Typography margin={'8px'} fontSize={'12px'}>A unique name for the carrier</Typography>
        </MDBox>    
        <MDBox margin={'8px'}>
            <MDInput type="number" fullWidth label="Messages allowed per minute" value={carrierData.limit} onChange={onLimitChange} />
            <Typography margin={'8px'} fontSize={'12px'}>{"If the carrier does not limit the throughput on this number, then leave this field blank. If you're unsure, you can enter 25. This amount can be updated later."}</Typography>
            </MDBox>        
        <MDBox margin={'8px'}>
            <FormControl fullWidth sx={{marginRight:'8px'}}>
            <InputLabel id="http-method">Http Methods</InputLabel>
            <Select
                labelId="http-method"
                id="http-method-select"
                value={carrierData.http_method}
                sx={{
                    height:'40px',
                    marginRight:'8px'
                }}
                label="HTTP Methods"
                onChange={onHttpMethodChange}
            >
                <MenuItem value={"POST"}>HTTP POST</MenuItem>
                <MenuItem value={"PUT"}>HTTP PUT</MenuItem>
                <MenuItem value={"GET"}>HTTP GET</MenuItem>
            </Select>
            <Typography margin={'8px'} fontSize={'12px'}>What HTTP method to use when calling the URL</Typography>
            </FormControl>
        </MDBox>
        <MDBox margin={'8px'}>
            <FormControl fullWidth sx={{marginRight:'8px'}}>
            <InputLabel id="http-method">Encoding Type</InputLabel>
            <Select
                labelId="encoding-type"
                id="encoding-type-select"
                value={carrierData.encoding_type}
                sx={{
                    height:'40px',
                    marginRight:'8px'
                }}
                label="Encoding Types"
                onChange={onEncodingTypeChange}
            >
                <MenuItem value={"DEFAULT"}>Default Encoding</MenuItem>
                <MenuItem value={"UNICODE"}>Unicode Encoding</MenuItem>
                <MenuItem value={"SMART"}>Smart Encoding</MenuItem>
            </Select>
            <Typography margin={'8px'} fontSize={'12px'}>What encoding to use for outgoing messages</Typography>
            </FormControl>
        </MDBox>
        <MDBox margin={'8px'}>
            <FormControl fullWidth sx={{marginRight:'8px'}}>
            <InputLabel id="http-method">Content Type</InputLabel>
            <Select
                labelId="content-type"
                id="content-type-select"
                value={carrierData.content_type}
                sx={{
                    height:'40px',
                    marginRight:'8px'
                }}
                label="Content Types"
                onChange={onContentTypeChange}
            >
                <MenuItem value={"application/x-www-form-urlencoded"}>URL Encoded - application/x-www-form-urlencoded</MenuItem>
                <MenuItem value={"application/json"}>JSON - application/json</MenuItem>
                <MenuItem value={"text/xml; charset=utf-8"}>XML - text/xml; charset=utf-8</MenuItem>
            </Select>
            <Typography margin={'8px'} fontSize={'12px'}>The content type used when sending the request</Typography>
            </FormControl>
        </MDBox>  
        <MDBox margin={'8px'}>
            <MDInput sx={{ marginRight:'8px'}}  fullWidth onChange={onMaxLengthChange} type="number" label="Max Length" value={carrierData.max_length} />
            <Typography margin={'8px'} fontSize={'12px'}>The maximum length of any single message on this channel. (longer messages will be split)</Typography>
        </MDBox> 
        <MDBox margin={'8px'}>
            <MDInput sx={{ marginRight:'8px'}}  fullWidth onChange={onAuthChange} type="text" label="Authorization Header Value" value={carrierData.auth_val} />
            <Typography margin={'8px'} fontSize={'12px'}>The Authorization header value added when calling the URL (if any)</Typography>
        </MDBox>   
        <MDBox margin={'8px'}>
            <MDInput sx={{ marginRight:'8px'}}  fullWidth onChange={onSendUrlChange} type="text" label="Send URL" value={carrierData.send_url} />
            <Typography margin={'8px'} fontSize={'12px'}>The URL we will call when sending messages, with variable substitutions</Typography>
        </MDBox>     
        <MDBox margin={'8px'}>
            <MDInput multiline minRows={2} sx={{ marginRight:'8px'}}  fullWidth onChange={onRequestBodyChange} type="text" label="Request Body" value={carrierData.request_body} />
            <Typography margin={'8px'} fontSize={'12px'}>The request body if any, with variable substitutions (only used for PUT or POST)</Typography>
        </MDBox> 

        <MDBox margin={'8px'}>
            <MDInput multiline minRows={2} sx={{ marginRight:'8px'}}  fullWidth onChange={onExampleRequestBodyChange} type="text" label="Example" value={carrierData.example_request_body} />
            <Typography margin={'8px'} fontSize={'12px'}>The content that must be in the request body.</Typography>
        </MDBox> 

        <MDBox margin={'8px'}>
            <MDInput multiline minRows={2} sx={{ marginRight:'8px'}}  fullWidth onChange={onResponseBodyChange} type="text" label="MT Response check" value={carrierData.response_body} />
            <Typography margin={'8px'} fontSize={'12px'}>The content that must be in the response to consider the request successful</Typography>
        </MDBox> 
        <MDBox margin={'12px'}>
            <MDButton onClick={submitFormData} variant="gradient" color="info">Submit</MDButton>
        </MDBox>                                          
      </MDBox>
    );
}
export default CarrierForm;