const AppConstants = {
    HOME_PAGE_TITLE: "Welcome to the world of BLIQ!",
    HOME_PAGE_TEXT1: `Our creative team of innovators has been hard at work creating realworld solutions to simplify Business-to-Consumer communication.`,
    HOME_PAGE_TEXT2: `We have been listening – intently – to our customers’ needs and have created an exclusive suite of features that will take messaging to a whole new realm.`,
    HOME_PAGE_TEXT3: `BLIQ will allow your entire team to utilize  messaging capabilities – with text and  WhatsApp integrated into the same interface – `,
    HOME_PAGE_TEXT31 : `using your company phone number`,
    HOME_PAGE_TEXT32 : `, to the larger success of your business marketing and communication needs.`,    
    HOME_PAGE_TEXT4: `Give BLIQ a shot, and you will soon be wondering how you ever managed without it!`,
    GOOGLE_CONTACTS_SCOPE : "https://www.googleapis.com/auth/contacts",
    REQUEST_FAIL : "Your request can not be fulfilled. Please try later.",
    TWO_DIGIT_REGEX: /^\d{1}|[1-9]\d{1}$/,
    UPDATED_SUCCESS : 'Updated successfully.',
    SOMETHING_WENT_WRONG: "Something went wrong. Please try later.",
    VALID_CREDIT_AMOUNT_WARNING: 'Please enter a valid amount. Upto 1000 credit is allowed.',
    CREDIT_AMOUNT_THRESHHOLD : 10000000,
    UNSUPPORTED_MEDIA_MSG : 'Unsupported media format. Unable to preview.',
    ADD_GROUP_RECIPIENTS_TEMPLATE_URL : 'https://app--media.s3.amazonaws.com/system_default_sounds/add_recipients_group_template.xlsx',
    FUNDRAISER_BULK_TEMPLATE: 'https://app--media.s3.amazonaws.com/system_default_sounds/fundraisers_bulk_template.xlsx',
    ACTION_NOT_ALLOWED : "You are not allow to perform this action",
    LOGO_PURPOSE_TEXT : 'Logo will be used in Crowdfunding donation page and receipts.',
    HARD_REFRESH_KEY : 'hard_refreshed',
    DLC_COMPLIANCE_MESSAGE : "For 10DLC regulations compliance, you must specify the group’s category.",
    START_CHAT : "Start a chat now...",
    ADD_MULTIPLE_NUMBER_WITH_COMMA_SEPATED : "You can enter multiple numbers at once by separating them with a comma.",
    UPDATE_SUCCESSFUL : "Updated successfully.",
    MESSAGE_DELAY: "Amount of seconds to delay outbound messages by",
    DELAY_EXPLAIN : "During this time, you'll be able to retract and edit your outbound messages.",
    // WELCOME_BLIQ : "Welcome to BLIQ!",
    BROADCAST_NO_MESSAGE : "You have no existing conversations in this broadcast.",
    BROWSER_NOTIFICATION_PERMISSION : 'https://app--media.s3.amazonaws.com/system_default_sounds/allow_browser_notification_permission.png',
    RECIPIENT_ASSIGNED_SOUND : 'https://app--media.s3.amazonaws.com/system_default_sounds/assigned_unassigned.mp3',
    RECIPIENT_UNASSIGNED : 'https://app--media.s3.amazonaws.com/system_default_sounds/assigned_unassigned.mp3',
    SHEET_PERMISSION_ERR : "We're having trouble retrieving the messages from your Google Sheet, please make sure that access is allowed for anyone with the link.",
    BROADCAST_INSTRUCTION : "File should contain contact numbers and the corresponding messages. If you’d like to send the same message to the entire group, first create a group. From there you’ll be able to add and/or upload contact numbers.",
    IMPORT_STATUS_FIELD_MAP_COLUMN : "Please select which value within this column indicates the message status. The selected value will be marked as Successful. All other values will be marked as Failed.",
    URL_REGEX: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
    WA_TEMPLATE_SYNCED : "Whatsapp template synced successfully.",
    API_SETTING_TEXT : "Allow API triggered messages to bring the conversation to the Recent Conversations panel.",
    RECIPIENT_OPT_OUT : "This recipient has opted out of further communications. You can't send messages to this recipient."
}
export default AppConstants;

export const NOTIFICATION_MEDIUM = {
    NONE : 'NONE',
    SMS : 'SMS',
    EMAIL : 'EMAIL',
    ALL : 'ALL'
}

export const WHATSAPP_INS = {
    TIP : "You can include dynamic place-holders using {{ }} in the template, that you'll later personalize while messaging the customer. The following is an example where {{1}}, {{2}} and {{3}} are the dynamic place-holders as per the need.",
    TIP_EXAMPLE  : "Hello {{1}}! We successfully processed your payment for invoice {{2}} using the credit card on file ending in {{3}}. Thank you!"

}

export const AUTO_REPLY = {
    AUTO_REPLY_INACTIVE_INS : "Auto-replies are not sent during active conversations. After how many minutes of inactivity should a conversation be considered dormant?"
} 

export const ROLE = {
    SUPER_ADMIN : 'SUPER_ADMIN',
    ADMIN : 'ADMIN',
    USER : 'USER'
}

export const ERRORS = {
    TELE_FILE_SIZE_ERR : "Media upto 5MB is allowed."
}