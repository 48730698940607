import { CAMPAIGN_LIST } from "constants/Endpoints";
import { GET_CAMPAIGN } from "constants/Endpoints";
import { CROWDFUNDING_ENDPOINT } from "constants/Endpoints";
import { CAMPAIGN } from "constants/Endpoints";
import { BASE_URL } from "constants/Endpoints";
import { CREATE_FUNDRAISER } from "constants/Endpoints";
import { MESSAGE } from "constants/Endpoints";
import { CREATE } from "constants/Endpoints";
import { DELETE } from "constants/Endpoints";
import fetch from "core/fetch";

export const getPhoneDetails = async(payload) => {
    const response = await fetch(`${BASE_URL}/company/${payload.companyId}/phone`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + payload.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const getCampaignList = async(payload) => {
    const response = await fetch(`${CAMPAIGN_LIST(payload.companyId)}`,{
        method: 'GET',
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + payload.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const getCampaign = async(payload) => {
    const response = await fetch(`${GET_CAMPAIGN(payload.campaignId)}`,{
        method : 'GET',
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + payload.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const createCampaign = async (newCampaign) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}`,{
        method: 'POST',
        body : JSON.stringify(newCampaign.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + newCampaign.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const editCampaign = async (editCampaign) => {
    const payload = {
        ...editCampaign,
        token : undefined
    }
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}`,{
        method: 'PUT',
        body : JSON.stringify(payload.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + editCampaign.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const createFundRaiser = async (fundRaiser) => {
    const response = await fetch(`${CREATE_FUNDRAISER}`,{
        method: 'POST',
        body : JSON.stringify(fundRaiser.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + fundRaiser.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const updateCampaignMsg = async (message) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${MESSAGE}/${message.campaign_id}`,{
        method: 'POST',
        body : JSON.stringify(message.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + message.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const createCampaignMsg = async (message) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${MESSAGE}${CREATE}/${message.campaign_id}`,{
        method: 'POST',
        body : JSON.stringify(message.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + message.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}
export const deleteCampaignMsg = async (message) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${MESSAGE}${DELETE}`,{
        method: 'DELETE',
        body : JSON.stringify(message.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + message.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;
}

export const deleteCampaign = async (campaign) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}`,{
        method: 'DELETE',
        body : JSON.stringify(campaign.payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + campaign.token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;  
} 

export const saveCampaignDonationLink = async ({payload,token}) => {
    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/donation/link`,{
        method: 'POST',
        body : JSON.stringify(payload),
        headers :{
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token ,
        }
    });
    const data = await response.json();
    if(!response.ok){
        throw new Error(data.message || 'Something went wrong.');
    }
    return data;  
} 
export const giveUIAccessBulk = async({token,uiAccess,campaign_id}) => {

    const response = await fetch(`${CROWDFUNDING_ENDPOINT}${CAMPAIGN}/uiaccess/${campaign_id}`,{
        method : 'POST',
        body : JSON.stringify(uiAccess),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}