import { Delete, Edit } from "@mui/icons-material";
import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { deleteShortcut } from "api/shortcut";
import { editShortcut } from "api/shortcut";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AppDialog from "ui/AppDialog";

const EditShorcut = ({ shortcut, onEdited , currentUser, onDeleted}) => {

    const [shortcutData, setShortcutData] = useState(shortcut);
    const [showDialog, setShowDialog] = useState(false);
    const {sendRequest : editShortcutFn, status : editShortcutStatus , data : shortcutEdited} = useHttp(editShortcut,false);
    const {sendRequest : deleteShortcutFn, status : deleteStatus, error : deleteErr} = useHttp(deleteShortcut,false);
    const onShorcutEdit = () => {
        const newShortcut = {
            ...shortcutData,
            user : {
                userId : currentUser.id,
                email : currentUser.email
            },
            company : {
                companyId : currentUser.company.companyId
            },
            token : currentUser.token
        }
        editShortcutFn(newShortcut);
    }

    const deleteMyShortcut = () => {

        Swal.fire({
            title: 'Delete this?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
              container : {
                zIndex : '10000 !important'
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {
                deleteShortcutFn({
                    ...shortcutData,
                    token : currentUser.token
                })
            }
          })

    }

    const handleChange = (e) => {
        setShortcutData(prev => {
            return {
                ...prev,
                privateShortcut : e.target.value === 'public'? false : true
            }
        })
    }
    useEffect(() => {

        if(editShortcutStatus === 'completed'){
            if(shortcutEdited){
                onEdited(shortcutEdited);
                setShowDialog(false);
            }
            else{
                toast.error("something went wrong, please try again");
            }
        }
    },[editShortcutStatus,shortcutEdited]);

    useEffect(() => {

        if(deleteStatus === 'completed'){
            if(deleteErr === null ){
                onDeleted(shortcutData)
            }
            else{
                toast.error("Something went wrong. Please try later.")
            }
        }
    },[deleteStatus,deleteErr]);

  return (
    <>
        <Tooltip title="Edit">
            <Edit sx={{cursor:'pointer',height:'22px!important',width:'22px!important'}} onClick={() => {
            setShowDialog(true);
        }}/>
        </Tooltip>
         <Tooltip title="Delete">
            <Delete sx={{
                marginLeft:'8px',
                cursor:'pointer',
                height:'22px!important',width:'22px!important'
            }}
            onClick={deleteMyShortcut}
            />
        </Tooltip>
        <AppDialog
        show={showDialog}
        title="Edit shortcut"
        onClose={() => {
            setShowDialog(false);
        }}
        content={
            <MDBox display="flex" flexDirection="column">
                <TextField
                    autoFocus
                    id="name"
                    onChange={(event) => {
                    setShortcutData((prev) => {
                        return {
                        ...prev,
                        key: event.target.value,
                        };
                    });
                    }}
                    label="Title"
                    type="text"
                    value={shortcutData.key}
                    variant="standard"
                    sx={{
                        margin:'8px'
                    }}
                />
                <TextField
                    sx={{
                        margin:'8px'
                    }}
                    multiline
                    id="value"
                    onChange={(event) => {
                    setShortcutData((prev) => {
                        return {
                        ...prev,
                        value: event.target.value,
                        };
                    });
                    }}
                    label="Text"
                    value={shortcutData.value}
                    type="text"
                    variant="standard"
                />
                {(currentUser.role === 'SUPER_ADMIN' || currentUser.role === 'ADMIN') && <MDBox sx={{
                    padding:'8px',
                    margin:'6px'
                }}>
                    <FormLabel sx={{
                        fontSize:'18px'
                    }} id="demo-row-radio-buttons-group-label">Shortcut for :</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleChange}
                        defaultValue={shortcutData.privateShortcut ? 'private' : 'public'}
                    >
                        <FormControlLabel value="private" control={<Radio />} label="Just me" />
                        <FormControlLabel value="public" control={<Radio />} label="Whole company" />
                    </RadioGroup>  
                    <Typography sx={{color:'blue'}} margin={'8px'} fontSize={'14px'}>Only you or Admins can delete shortcuts which you have created.</Typography>                  
                </MDBox>}                
            </MDBox>
        }
        footer={
            <MDBox alignItems="center" textAlign="center">
            <Button
                onClick={onShorcutEdit}
                size="small"
                variant="contained"
                style={{
                color: "white",
                }}
            >
                Save
            </Button>
            </MDBox>
        }
        ></AppDialog>
    </>
  );
};
export default EditShorcut;
