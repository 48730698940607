import { Box, Collapse, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import GoalCompletionUI from "components/CommonUI/GoalCompletionUI";
import MDBox from "lib/components/MDBox";
import EditFundRaiser from "./EditFundRaiser";
import FundraiserTransactions from "components/Donation/FundraiserTransactions";
import FundraiserSettings from "./FundraiserSettings";
import Moment from "react-moment";
import AddFundRaiser from "./AddFundRaiser";
import EditCampaign from "./EditCampaign";
import TransactionInfo from "components/Donation/TransactionInfo";
import AddBonus from "./AddBonus";
import CampaignSetting from "./CampaignSetting";
import BulkFundraiserSettings from "components/Fundraiser/BulkFundraiserSettings";
import EnhancedTable from "components/NumberPool/EnhancedTable";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import style from "./CampaignList.module.css";
import { useReactToPrint } from "react-to-print";
import { getPercentage } from "util/UtilityFunctions";
import { columns } from "./CampaignConstants";
import { Print } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";

const CampaignRow = ({onCampaignEdited,isPrinting,row,onFundraiserCreated,onFundraiserCreatedBulk,onCampaignDeleted}) => {

    const fundraiserListRef = useRef();
    const [open, setOpen] = useState(false);
    const [currRow,setCurrow] = useState([]);
    const [frows, setFRows] = useState([]);
    const [showSettings, setShowSettings] = useState(undefined);
    
    const onFundraiserEdited = (res) => {
      const newRow = currRow?.fundraisers?.map(fundraiser => {
        if(fundraiser.fundraiser_id === res.fundraiser_id){
          return res;
        }
        else{
          return fundraiser;
        }
      })
      setCurrow(prev => {
        const n = 
        {
          ...prev,
          fundraisers : newRow
        }
        console.log(n);
        return n;
      })
    }
    
    const printFundraiserList = useReactToPrint({
      content: () => fundraiserListRef.current,
    });
  
    const onBulkSettingsUpdated = (res) => {
      setShowSettings(false);
    }
    const onLinkGenerated = useCallback((res) => {
      const newRow = currRow?.fundraisers?.map(fundraiser => {
        if(fundraiser?.fundraiser_id === res?.fundraiser?.fundraiser_id){
          return {
            ...fundraiser,
            donation_link : res.donation_link
          }
        }
        else{
          return fundraiser;
        }
      })
      setCurrow(prev => {
        return {
          ...prev,
          fundraisers : newRow
        }
      })
    },[currRow])
  
    const onSelected = (res) => {
      if(res && res.length > 0){
        setShowSettings(res);
      }
      else{
        setShowSettings(false);
      }
    }
  
    useEffect(() => {
      setCurrow(row);
    },[row]);

    useEffect(() => {
        const newFRows = currRow?.fundraisers?.map(c => {
            return{
              id : c.fundraiser_id,
              fundraiser_name : c.fundraiser_name ? c.fundraiser_name : "Not set",
              fundraiser_email : c?.fundraiser_email ? c?.fundraiser_email : "Not set",
              fundraiser_number : c?.fundraiser_number ? c?.fundraiser_number : "Not set",
              goal_amount : <p>{c?.goal_amount ? c?.goal_amount : 0}</p>,
              raised_so_far : <p>{c?.raised_so_far ? c?.raised_so_far : 0}</p>,
              assigned_phone : c?.phones ? c.phones?.phone : "Not set",
              status :  <Box className={style["print"]} key={"goal_"+c.fundraiser_id} sx={{ width: "100%" }} mt={1}>
                          <GoalCompletionUI value={getPercentage(c.raised_so_far, c.goal_amount)} color={"info"}/>
                        </Box>,
              actions : <MDBox className={style["print-only"]} key={"action_" + c.fundraiser_id} style={{ display: "flex", width:'75px', justifyContent : "space-between"}}>
                      <EditFundRaiser key={"edit_" + c.fundraiser_id} fundraiser={c} campaignId={row.campaign_id} onFundraiserEdited={onFundraiserEdited}/>
                      <FundraiserTransactions key={ "transaction" + c.fundraiser_id} fundraiser={c} campaignId={row.campaign_id}/>
                      <FundraiserSettings key={"setting_"+c.fundraiser_id} onLinkGenerated={onLinkGenerated} fundraiser={c} campaign={row}/>
                </MDBox> 
            }
          });
          setFRows(newFRows);
    },[currRow])
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" },width:"100%" }}>
          <TableCell className={style["print-only"]}  sx={{maxWidth:'32px'}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align="center">
            {currRow?.campaign_name}
          </TableCell>
          <TableCell align="center"><p>{currRow?.goal_amount ? currRow?.goal_amount : 0}</p></TableCell>
          <TableCell align="center"><p>{currRow?.raised_so_far ? currRow?.raised_so_far : 0}</p></TableCell>
          <TableCell align="center">
            <Moment format="L" time>{getDateConvertedFromUTC(currRow?.createdAt)}</Moment>
          </TableCell>
          <TableCell align="center">
            <Moment format="L">{getDateConvertedFromUTC(currRow?.completion_date)}</Moment>
          </TableCell>
          <TableCell align="center">
              <GoalCompletionUI value={getPercentage(currRow.raised_so_far, currRow.goal_amount)} color={"info"}/>
          </TableCell>
          <TableCell className={style["print-only"]} align="center" sx={{display:'flex', justifyContent:'space-around'}}>
            <MDBox style={{ display: "flex", width:'100px', justifyContent : "space-between"}}>
              <AddFundRaiser
                onFundraiserCreated={onFundraiserCreated}
                campaign={currRow}
                onFundraiserCreatedBulk={onFundraiserCreatedBulk}
              />
              <EditCampaign onCampaignEdited={onCampaignEdited} campaign={currRow}/>
              <TransactionInfo campaignId={currRow?.campaign_id}/>
              <AddBonus campaign={currRow}/>
              <CampaignSetting campaign={currRow} onCampaignDeleted={onCampaignDeleted}/>
            </MDBox>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <MDBox display="flex" justifyContent="space-between" padding="12px">
                  <Typography variant="h6" gutterBottom component="div">
                    {"Fundraisers"}
                  </Typography>
                  {!showSettings  && <Tooltip title="Print the progress">
                    <Print sx={{cursor:'pointer',width: "1.25rem", height: "1.25rem"}} onClick={printFundraiserList}/>
                  </Tooltip>}
                  {showSettings && <BulkFundraiserSettings onBulkSettingsUpdated={onBulkSettingsUpdated} fundraisers={showSettings} campaignId={currRow.campaign_id}/>}
                </MDBox>
                <MDBox ref={fundraiserListRef} className={style["print"]}>
                  <EnhancedTable onSelected={onSelected} showPagination={false} showFilter={false} columns={columns} selectEnabled={true} rows={frows}/>
                </MDBox>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );    
}
export default memo(CampaignRow);