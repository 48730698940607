import MDBox from "lib/components/MDBox";
import { Switch, Typography } from "@mui/material";
import MDInput from "lib/components/MDInput";
import { useEffect, useRef, useState } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { UserAPI } from "services/UserAPI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const UserCellCallConfig = (
    {
        phoneId,
        userId,
        config = {
            enabled : true,
            disa_enabled : true,
            api_triggered : '',
            disa_pattern : ''
        }, 
        onDisaUpdated = () => {}
    }) => {
    
    const [currConfig,setConfig] = useState(config);
    const firstRender = useRef(true);
    const token = useSelector(state => state.user.token);

    const updateSettings = () => {
        const payload = {
            api_triggered : Boolean(currConfig?.api_triggered) ? currConfig.api_triggered : '',
            disa_enabled : Boolean(currConfig?.disa_enabled) ? currConfig.disa_enabled : false,
            disa_pattern : Boolean(currConfig?.disa_pattern) ? currConfig.disa_pattern : '',
            enabled : currConfig.enabled
        }
        if(payload.api_triggered?.trim().length === 0 && payload.disa_pattern?.trim().length === 0){
            return;
        }
        UserAPI.updateUserCellConfig({payload,phoneId,userId : userId, token}).then(res => {
            toast.success("Updated successfully");
            onDisaUpdated({phoneId,res: res.data,userId})
        }).catch(e => {
            toast.error(e?.response?.data?.message);
        })
    }

    // useEffect(() => {
    //     setConfig(config);
    // },[config])

    useDebouncedWatch(() => {
        // may be it is for phone only
        if(firstRender.current){
            firstRender.current = false;
            return;
        }
        if(userId){
            updateSettings();
        }
    },[currConfig,setConfig,userId,phoneId]);

    return(
        <>
            <MDBox display="flex">
                <Switch checked={currConfig.enabled} color="info" onChange={(e) => {
                    setConfig(prev => {
                        return {
                            ...prev,
                            enabled : e.target.checked
                        }
                    })
                }}/>
                <Typography>{"Enable cell call configurations"}</Typography>
            </MDBox>
            {
                currConfig.enabled && <>    
                    <MDBox display="flex" flexDirection="column" justifyContent="center" p={2} width="100%!important">
                        <MDBox display="flex">
                            <Switch checked={currConfig.disa_enabled} color="info" onChange={(e) => {
                                setConfig(prev => {
                                    return{
                                        ...prev,
                                        disa_enabled : e.target.checked
                                    }
                                })
                            }}/>
                            <Typography>{"DISA confguration"}</Typography>
                        </MDBox>
                        <MDBox display="flex" width="100%!important">
                            <MDBox width="100%!important">
                                <MDInput
                                    type="text"
                                    label="DISA Pattern"
                                    variant="standard"
                                    value={currConfig.disa_pattern}
                                    onChange={(e) => {  
                                        setConfig(prev => {
                                            return{
                                                ...prev,
                                                disa_pattern : e.target.value
                                            }
                                        })
                                    }}
                                    fullWidth
                                />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox p={2}>
                        <MDBox display="flex">
                            <Switch checked={!currConfig.disa_enabled} color="info" onChange={(e) => {
                                setConfig(prev => {
                                    return{
                                        ...prev,
                                        disa_enabled : !e.target.checked
                                    }
                                })                                
                            }}/>
                            <Typography>{"API triggered call"}</Typography>
                        </MDBox>
                        <MDBox display="flex" width="100%!important">
                            <MDBox width="100%!important">
                                <MDInput
                                    type="text"
                                    label="API"
                                    variant="standard"
                                    value={''}
                                    onChange={(e) => {
                                        setConfig(prev => {
                                            return{
                                                ...prev,
                                                api_triggered : e.target.value
                                            }
                                        })
                                    }}
                                    fullWidth
                                />
                            </MDBox>
                        </MDBox>                
                    </MDBox>
                </>
            }
        </>
    )
}
export default UserCellCallConfig;