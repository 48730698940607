import { Button, FormControlLabel, FormLabel, Icon, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { createShortcut } from "api/shortcut";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import AppDialog from "ui/AppDialog";

const CreateShortcut = ({onCreated,currentUser,create,onCancel}) => {

    const [shortcutData, setShortcutData] = useState({
        key:'',
        value:'',
        privateShortcut : false
    });
    const [showDialog, setShowDialog] = useState(false);
    const {sendRequest : createShortcutFn, status : createShortcutStatus , data : shortcutCreated} = useHttp(createShortcut,false);

    const handleChange = (e) => {
        setShortcutData(prev => {
            return {
                ...prev,
                privateShortcut : e.target.value === 'public'? false : true
            }
        })
    }
    const onShorcutCreate = () => {
        const newShortcut = {
            ...shortcutData,
            user : {
                userId : currentUser.id,
                email : currentUser.email
            },
            company : {
                companyId : currentUser.company.companyId
            },
            token : currentUser.token
        }
        createShortcutFn(newShortcut);
        setShortcutData({
            key:'',
            value:'',
            privateShortcut : false
        });
    }

    useEffect(() => {

        if(createShortcutStatus === 'completed'){
            if(shortcutCreated){
                onCreated(shortcutCreated);
            }
        }
    },[createShortcutStatus,shortcutCreated]);
    useEffect(() => {
        setShowDialog(create);
    },[create])
    return(
        <AppDialog 
            show={showDialog} 
            title="Create shortcut" 
            onClose={() => {
                setShowDialog(false);
                onCancel();
            }}
            content = {
                <MDBox display="flex" flexDirection="column">
                    <TextField
                    autoFocus
                    id="name"
                    onChange={(event) =>{
                        setShortcutData(prev => {
                            return {
                                ...prev,
                                key : event.target.value
                            }
                        })
                    }}
                    label="Title"
                    type="text"
                    variant="standard"
                    />
                    <TextField
                    multiline
                    id="value"
                    onChange={(event) =>{
                        setShortcutData(prev => {
                            return {
                                ...prev,
                                value : event.target.value
                            }
                        })
                    }}
                    label="Text"
                    type="text"
                    variant="standard"
                    />  
                    {(currentUser.role === 'SUPER_ADMIN' || currentUser.role === 'ADMIN') && <MDBox sx={{
                        padding:'8px',
                        margin:'6px'
                    }}>
                        <FormLabel sx={{
                            fontSize:'18px'
                        }} id="demo-row-radio-buttons-group-label">Shortcut for :</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChange}
                            defaultValue={'public'}
                        >
                            <FormControlLabel value="private" control={<Radio />} label="Just me" />
                            <FormControlLabel value="public" control={<Radio />} label="Whole company" />
                        </RadioGroup>                    
                    </MDBox>}                          
                </MDBox>
            }
            footer = {
                <MDBox alignItems="center" textAlign="center">
                    <Button onClick={onShorcutCreate} size="small" variant="contained" style={{
                        color:'white'
                    }}>Create</Button>
                </MDBox>
            }
        />
    )
}
export default CreateShortcut;