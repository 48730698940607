import { Add } from "@mui/icons-material";
import { Avatar, Button, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import IUser from "components/IChat/IUser";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loaderAction } from "store/loader-slice";
import { filterUser } from "util/IChat/IChatUtil";
import SearchIcon from '@mui/icons-material/Search';
import { CompanyGroupAPI } from "services/Chat/CompanyGroupAPI";
import AppConstants from "constants/AppConstants";

const CreateCompanyGroup = ({givenUsers,onGroupCreated}) => {

    const token = useSelector(state => state.user.token);

    const dispatch = useDispatch();

    const groupNameRef = useRef();
    const groupDescRef = useRef();

    const [allUsers,setAllUsers] = useState([]);

    const [users,setUsers] = useState([]);
    const currUser = useSelector(state => state.user);
    const [selectedUsers,setSelectedUsers] = useState([]);

    const removeUser = (res) => {
        const newSelection = selectedUsers.filter(item => item?.userId !== res.userId);
        setSelectedUsers(newSelection);
    }

    const createGroup = () => {
        if(groupNameRef?.current?.value?.trim().length === 0){
            toast.error("Group name is required.");
            return;
        }
        
        dispatch(loaderAction.show());
        
        const payload  = {
            users : selectedUsers,
            name : groupNameRef?.current?.value,
            company : {
                companyId : currUser.company.companyId
            },
            description : groupDescRef?.current?.value,
            adminId : currUser.id
        }       
        CompanyGroupAPI.createGroup({payload,token}).then(res => {
            dispatch(loaderAction.hide());
            onGroupCreated(res.data);
        }).catch(ex => {
            dispatch(loaderAction.hide());
            toast.error(AppConstants.SOMETHING_WENT_WRONG);
            console.log(ex);
        })
    }

    const handleSearch = useCallback((e) => {
        const filteredUsers = filterUser(allUsers,e.target.value);
        setUsers(filteredUsers);
    },[allUsers])

    const onSelected = (res) => {
        if(res.checked) {
            // if user is not then add
            const exists = selectedUsers.find(itm => itm.userId === res.user.userId);
            console.log(exists)
            if(!exists){
                setSelectedUsers(prev => [...prev,res.user]);
            }
        }
        else{
            const newSelection = selectedUsers.filter(item => item.userId !== res.user.userId);
            setSelectedUsers(newSelection);
        }
    }

    const onSelectAll = useCallback((e) => {
        if(e.target.checked) {
            setSelectedUsers(users);
        }
        else{
            setSelectedUsers([]);
        }
    },[users]);

    useEffect(() => {
        setAllUsers(givenUsers);
        setUsers(givenUsers);
    },[givenUsers]);
    
    return(
        <>
            <MDBox display="flex" flexDirection="column"> 
                <MDBox mb={1} width="100%" display="flex" flexDirection="column">
                    <TextField inputRef={groupNameRef} variant="standard" placeholder="Group name" sx={{width:'100%',padding:'8px',marginBottom:'8px'}}/>
                    <TextField inputRef={groupDescRef} variant="standard" placeholder="Description (Optional)" sx={{width:'100%',padding:'8px'}}/>
                </MDBox>
                <MDBox width="100%">
                    <TextField
                        placeholder="Search"
                        sx={{width:'100%',padding:'8px'}}
                        onChange={handleSearch}
                        variant="standard"
                        InputProps={{
                            endAdornment: 
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search"
                                    edge="end"
                                >
                                    {<SearchIcon/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                        
                    />
                </MDBox>                 
            </MDBox>  
            <MDBox display="flex" sx={{margin:'12px'}}>
                <FormControlLabel onChange={onSelectAll} control={<Checkbox />} label="Select All" />              
            </MDBox>      
            <MDBox maxHeight='328px' sx={{overflowY:'auto',margin:'12px'}}>
                {selectedUsers?.length > 0 && <Typography fontSize={"14px"} sx={{marginBottom:'4px'}} color={"blue"}>{"Members"}</Typography>}
                <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                    {selectedUsers && 
                        selectedUsers?.map(chip => {
                            return(
                                <Chip
                                    key={"chip_" + chip?.userId}
                                    avatar={<Avatar alt={chip.fullName} src={chip.profilePic} />}
                                    label={chip.fullName}
                                    variant="outlined"
                                    // onDelete={() => removeUser(chip)}
                                    sx={{margin:'4px'}}
                                />
                            )
                        })
                    }       
                </Stack>                
                {users?.map(u => <IUser key={u.userId} onSelected={onSelected} user={u} select={selectedUsers.length === users.length}/>)}  
            </MDBox>
            <MDBox display="flex" width="100%" alignItems="center" justifyContent="flex-end">
                <Button
                    onClick={createGroup}
                    disabled={selectedUsers.length === 0}
                    sx={{
                        color: "white!important",
                        border: "unset",
                        width: "96px",
                        padding: "10px",
                        height: "35px",
                        backgroundColor: "#1876F2!important",
                    }}
                    variant="contained"
                    size="medium"
                    endIcon={<Add/>}
                >
                    Create
                </Button>
            </MDBox>
        </>        
    )
}
export default memo(CreateCompanyGroup);