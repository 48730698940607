import EditIcon from "@mui/icons-material/Edit";
import { Icon,Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import EditCompanyForm from "./EditCompanyForm";
import AppDrawer from "ui/AppDrawer";
import { Settings} from "@mui/icons-material";
import CompanyDetails from "./CompanyDetails";


const CompanyActions = ({company,onProductUnsubscribed,onProductSubscribed,onCompanyEdited,onHipaaSubscribed}) => {

  const [action, setAction] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const role = useSelector(state => state.user.role);

  const onCompanyEditedHandler = (res) => {
    if(res){
        setAction(-1);
        onCompanyEdited(res);
    }
  }
  const showCompanyDetails = (tcompany) => {
    setOpenDrawer(true);
  }
    return (
      <>
        <MDBox display="flex" flexDirection="row">
          {
            <Tooltip placement="bottom" title="Settings">
              <MDBox sx={{ marginLeft: "8px" }} onClick={showCompanyDetails.bind(null,company)}>
                <Icon
                  sx={{
                    height: "20px",
                    width: "40px",
                    fontSize: "20px!important",
                    cursor: "pointer",
                  }}
                >
                  <Settings />
                </Icon>
              </MDBox>
            </Tooltip>
          }
          {role === "SUPER_ADMIN" && (
            <Tooltip title="Edit.">
              <EditIcon
                onClick={() => setAction(0)}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Tooltip>
          )}
        </MDBox>
        {action === 0 && role === "SUPER_ADMIN" && (
          <AppModal
              rootStyle = {
                {
                  "&.MuiModal-root":{
                    width:'40%',
                    left: '60%'
                  }
                }
              }
            customStyle={{ 
                width: "100%",
                height: "100%",
            }}
            onModalClose={() => {
              setAction(-1);
            }}
              heading={"Edit Company"}
          >
            <EditCompanyForm
              company = {company}
              onCompanyEdited = {onCompanyEditedHandler}
              onHipaaSubscribed = {onHipaaSubscribed}
              onProductSubscribed = {onProductSubscribed}
              onProductUnsubscribed = {onProductUnsubscribed}
            />
          </AppModal>
        )}
        <AppDrawer openDrawer={openDrawer} style={{
                margin:'unset !important',
                height:'100% !important',
                borderRadius:'unset!important',
                width:'35%'
            }}
            onClose = {() => setOpenDrawer(null)}>
              {company && <CompanyDetails company={company} onEdited={(res) =>{onCompanyEdited(res)}}/>}
        </AppDrawer>   
      </>
    );
}
export default memo(CompanyActions);