import { Add, Close, CloseRounded, Done } from "@mui/icons-material";
import { Autocomplete, Chip, createFilterOptions, Paper, Stack, TextField, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import useHttp from "hooks/use-http";
import { createCompanyTag } from "api/company";
import { useDispatch, useSelector } from "react-redux";
import { memo } from "react";
import { ConversationAPI } from "services/ConversationAPI";
import { messageAction } from "store/message-slice";
import { appAction } from "store/app-slice";

const TAG_STATE = {
    INITIAL : 'initial',
    CREATING: 'creating',
    END : 'end',
    FINISH : 'finish'
}
const tagAlreadyUsed = (tags,name) => {
    const filtered = tags?.filter(tag => tag?.tag.name === name);
    return filtered.length > 0;
}
const Tags = ({onClose,onTagChange,showTagInput,message}) => {
    
    const dispatch = useDispatch();
   
    const currentUser = useSelector(state => state.user);
    const [currentTagState, setCurrentTagState] = useState(TAG_STATE.INITIAL);
    const availableTags = useSelector(state => state.app.companyTags);
    const recipientId = useSelector(state => state.chat.current_active_recipient?.id);
    const phoneId = useSelector(state => state.chat.current_active_phone.id);
    const [newTag,setNewTag] = useState();
    const filter = createFilterOptions();
    const [tagsGiven,setTagsGiven] = useState(message?.tags);
    const [companyTags,setCompanyTags] = useState([]);
    const {sendRequest : createTagFn, data:createdTag , status: createTagStatus} = useHttp(createCompanyTag,false,false);

    const handleDelete = useCallback((chipToDelete) => {
      //Call the endpoint and delete from DB as well
      ConversationAPI.removeTag({token : currentUser.token,tag : {
          ...chipToDelete,
          message:{
            id : message.id,
            sendTo:message.sendTo,
            sendByPhone: message.sendByPhone
          },
          createdBy : currentUser.id
        }
      }).then(res =>{}).catch(e =>{});
      const newChips = tagsGiven?.filter((chip) => chip?.tag?.name !== chipToDelete?.tag?.name);
      setTagsGiven(newChips);
      if(newChips.length == 0){
        setCurrentTagState(TAG_STATE.INITIAL);
      }
    },[currentUser,tagsGiven,message]);

    // const addTagToMessage = useCallback((newTag = undefined) => {
    //   if(newTag){
    //    const payload = {
    //     tag : newTag,
    //     message : {
    //       id : message.id,
    //       sendTo : message.sendTo,
    //       sendByPhone : message.sendByPhone
    //     }
    //    }
    //    ConversationAPI.createTag({token : currentUser.token,payload}).
    //     then(res => {
    //       setTagsGiven(prev => [...prev,res.data]);
    //     }).catch(e => console.log(e));
    //   }
    // },[message]);

    const saveTag =() => {
      createTagFn({
        ...newTag,
        token : currentUser.token,
        company : {
          companyId : currentUser.company.companyId
        },
      });
    }
    
    const handleChangeComplete = (color, event) => {
      setNewTag(prev => {
        return {
          ...prev,
          tag : {
            ...prev.tag,
            color : color.hex
          }
        }
      })
    }

    // useEffect(() => {
    //   if(tagsGiven){
    //     onTagChange(tagsGiven);
    //   }
    // },[tagsGiven]);

    const onTagChangeHandler =
      (newValue) => {
        // If it is new tag which is just getting created
        ///if it has tagId means it is getting selected from dropdown, else we are going to create new one
        let val = "";
        if (newValue.tagId) {
          if (typeof newValue === "string") {
            val = newValue;
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            val = { name: newValue.inputValue };
          } else {
            val = newValue;
          }

          if (val.name !== "" && !tagAlreadyUsed(tagsGiven, val.name)) {
            //Save to DB
            const payload = {
              tag: val,
              message : {
                id : message.id,
                sendTo : message.sendTo,
                sendByPhone : message.sendByPhone
              },
              createdBy : currentUser.id 
            };
            ConversationAPI.createTag({ token: currentUser.token, payload })
              .then((response) => {
                dispatch(messageAction.newTag({recipientId,phoneId,messageId:message.id,tag:response?.data?.tag}));
                onTagChange()
              })
              .catch((e) => console.log(e));
            }
          // setTagsGiven(tagsGiven.concat({tag:val}));
          setCurrentTagState(TAG_STATE.INITIAL);
        }
        else{
          const cTag = {
            tag :{
              name : newValue.inputValue
            }
          }
          setNewTag(cTag);
          setCurrentTagState(TAG_STATE.END);
        }
      }

    useEffect(() => {
        if(createTagStatus === 'completed'){
          if(createdTag){
            dispatch(appAction.addCompanyTag(createdTag));
            dispatch(messageAction.newTag({recipientId,phoneId,messageId:message.id,tag:createdTag}));
            onTagChange()
            setCompanyTags(prev => {
              if(prev) {
                return [createdTag,...prev]
              }
              else return [createdTag]
            });
          }
        }      
    },[createTagStatus,createdTag])

    useEffect(() => {
        if(availableTags){
            setCompanyTags(availableTags);
        }
    },[availableTags])

    useEffect(() => {
      if(message?.tags){
        setTagsGiven(message?.tags);
      }
    },[message?.tags]);

    return (
      <>
        {showTagInput && (
          <Stack id="tags-standard" spacing={3} sx={{ width: "100%",marginTop:'4px' }}>
            <MDBox display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              {Array.isArray(tagsGiven)? tagsGiven.map((item,index) => (
                <Chip
                  label={item?.tag?.name}
                  id={`tag_${item.tag?.name}`}
                  key={`tag_${item.tag?.name}_${index}`}
                  sx={{
                    overflow: "unset",
                    height: "22px",
                    margin: "4px",
                  }}
                  style={
                    item?.tag?.color && {
                      backgroundColor: item.tag?.color,
                      color: "white",
                      "& .MuiChipDeleteIcon": {
                        color: "white!important",
                      },
                    }
                  }
                  onDelete={handleDelete.bind(null,item)}
                />
              )):[]}
              {/**For new Tag which is going to be created*/}
              {newTag && <Chip
                label={newTag?.tag?.name}
                id={`tag_${newTag.tag?.name}`}
                key={`tag_${newTag.tag?.name}}`}
                sx={{
                  overflow: "unset",
                  height: "22px",
                  margin: "4px",
                }}
                style={
                  newTag?.tag?.color && {
                    backgroundColor: newTag.tag?.color,
                    color: "white",
                    "& .MuiChipDeleteIcon": {
                      color: "white!important",
                    },
                  }
                }
                onDelete={handleDelete.bind(null,newTag)}
              />}
              <MDBox
                style={{
                  marginLeft: "6px",
                  display: 'flex',
                }}
              >
                <MDBox display="flex" justifyContent="row" sx={{marginTop:'4px'}}>
                  {currentTagState === TAG_STATE.INITIAL && (
                    <Tooltip title="Add tag">
                      <Add
                        style={{
                          cursor: "pointer",
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCurrentTagState(TAG_STATE.CREATING);
                          setNewTag(null)
                        }}
                      />
                    </Tooltip>
                  )}
                  </MDBox>
                {currentTagState === TAG_STATE.CREATING &&
                    <Autocomplete
                      options={Array.isArray(companyTags) ? companyTags : []}
                      getOptionLabel={(option) => option.name}
                      autoHighlight={true}
                      style={{
                        minWidth: "150px",
                      }}
                      renderOption={(props, option) => {
                        const { name, color } = option;
                        return (
                          <span {...props} style={{ backgroundColor: color, margin:'4px' }}>
                            {name}
                          </span>
                        );
                      }}
                      onChange={(event,newValue) => onTagChangeHandler(newValue)}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `+ Create "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label=""
                          placeholder="tag"
                        />
                      )}
                  />
                }
                <MDBox display="flex" alignItems="center" justifyContent="center">
                  <Tooltip title="close">
                    <Close
                      style={{
                        cursor: "pointer",
                        height: "24px",
                        width: "24px",
                        margin: "4px",
                      }}
                      onClick={() => {
                        setCurrentTagState(TAG_STATE.INITIAL);
                        setNewTag(null);
                        onClose();
                      }} 
                    />
                  </Tooltip>
                </MDBox>
              </MDBox>
            </MDBox>
          </Stack>
        )}
        {newTag && (
          <div style={{position: "relative"}}>
            <MDBox
              display="flex"
              flexDirection="row-reverse"
              style={{position: "relative"}}
            >
              <Paper elevation={3}>
                <MDBox display="flex" justifyContent="end">
                  <Button varient="outlined" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setNewTag(null);
                    saveTag();
                    setCurrentTagState(TAG_STATE.INITIAL);
                  }}>
                    Save
                  </Button>
                </MDBox>
                <TwitterPicker
                  styles={{position: "unset"}}
                  onChangeComplete={handleChangeComplete}
                />
              </Paper>
            </MDBox>
          </div>
        )}
      </>
    );
}
export default memo(Tags);
