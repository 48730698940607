import Dashboard from "pages/Dashboard";
// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "lib/context";
import Sidenav from "lib/layouts/Sidenav";

// Images
import brandWhite from "assets/images/logos/BLIQ/notaglines-01.png";
import brandDark from "assets/images/logos/BLIQ/notaglines-01.png";
// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

import { useState, useEffect, memo } from "react";
// @mui icons
import Icon from "@mui/material/Icon";
import Users from "pages/Users";
import Credentials from "pages/Credentials";
import AdminChat from "pages/AdminChat";
import Company from "pages/Company";
import LockedAccounts from "pages/LockedAccounts";
import Shortcuts from "pages/Shortcuts";
import Logs from "pages/Logs";
import Campaign from 'pages/Campaign';
import { useSelector } from "react-redux";
import Pool from "pages/Pool";
import Carrier from "pages/Carrier";
import Customer from "pages/Customer";
import AuthenticatedPath from "components/AuthenticatedPath";
import AppModal from "ui/AppModal";
import AcceptTermsAndConditions from "components/AcceptTermsAndConditions";
import AutoReply from "pages/AutoReply";

const FEATURE_ROUTES = {
  CROWD_FUNDING: 'CROWDFUNDING'
}
const Admin = () => {

  const [protectedRoutes, setProtectedRoutes] = useState([]);
  const currUser = useSelector(state => state.user);
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      views: [{
        type: "collapse",
        name: "Company",
        key: "company",
        icon: <Icon fontSize="small">work_icon</Icon>,
        route: "companies/company",
        component: <Company />,
      }],
      name: "Companies",
      key: "companies",
      route: "companies",
      icon: <Icon fontSize="small">domain</Icon>,
    },
    // {
    //   type: "collapse",
    //   name: "My Numbers",
    //   key: "credentials",
    //   route : "credentials",
    //   component: <Credentials />,
    //   icon: <Icon fontSize="small">tag</Icon>,
    //   collapse: [
    //     {
    //       name : 'Numbers',
    //       type : 'collapse',
    //       icon: <Icon fontSize="small">tag</Icon>,
    //       key: "number",
    //       component: <Credentials />,
    //       route : "number"
    //     }
    //   ]
    // }, 
    {
      name: 'My Numbers',
      type: 'collapse',
      icon: <Icon fontSize="small">tag</Icon>,
      key: "numbers",
      component: <Credentials />,
      route: "numbers"
    },
    {
      type: "collapse",
      name: "Users",
      key: "users",
      icon: <Icon fontSize="small">person_add</Icon>,
      route: "users",
      component: <Users />,
    },
    {
      type: "collapse",
      name: "Chats",
      key: "schedule",
      icon: <Icon fontSize="small">chat</Icon>,
      route: "chats",
      component: <AdminChat />,
    },
    {
      type: "collapse",
      name: "Quick Replies",
      key: "shortcuts",
      icon: <Icon fontSize="small">shortcut</Icon>,
      route: "shortcuts",
      component: <Shortcuts />,
    },
    {
      type: "collapse",
      name: "Keyword Logs",
      key: "logs",
      icon: <Icon fontSize="small">info</Icon>,
      route: "logs",
      component: <Logs />,
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      icon: <Icon fontSize="small">settings</Icon>,
      route: "settings",
      component: <AutoReply />,
    },
    {
      type: "collapse",
      name: "Locked Accounts",
      key: "accounts",
      icon: <Icon fontSize="small">lock</Icon>,
      route: "accounts",
      component: <LockedAccounts />,
    },
  ];


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    if (!currUser.acceptedTermsAndConditions && !localStorage.getItem("terms_accepted")) {
      setShowTermsAndCondition(true);
    }
  }, [currUser]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {

    const fr = [];
    if (currUser.role === 'SUPER_ADMIN') {
      fr.push({
        type: "collapse",
        name: "Customers",
        key: "customers",
        icon: <Icon fontSize="small">account_circle</Icon>,
        route: "customers",
        component: <Customer />,
      });
      fr.push({
        type: "collapse",
        name: "External API",
        key: "carrier",
        icon: <Icon fontSize="small">extension</Icon>,
        route: "carrier",
        component: <Carrier />,
      });
    }
    if (currUser.company?.products_subscribed?.length > 0 || currUser.role === 'SUPER_ADMIN') {
      fr.push({ type: 'title', title: "Subscribed Features" },);
    }
    if (currUser.role === 'SUPER_ADMIN') {
      fr.push(
        {
          type: "collapse",
          name: "Crowdfunding",
          key: "campaigns",
          icon: <Icon fontSize="small">volunteer_activism_icon</Icon>,
          route: "campaigns",
          component: <Campaign />,
        },
      );
      fr.push(
        {
          type: "collapse",
          name: "Number Pool",
          key: "pool",
          icon: <Icon fontSize="small">upload</Icon>,
          route: "pool",
          component: <Pool />,
        },
      )
    }
    else {
      if (currUser.company?.products_subscribed?.length > 0) {
        for (const temp of currUser.company?.products_subscribed) {
          if (temp.product.name === FEATURE_ROUTES.CROWD_FUNDING) {
            fr.push(
              {
                type: "collapse",
                name: "Crowdfunding",
                key: "campaigns",
                icon: <Icon fontSize="small">volunteer_activism_icon</Icon>,
                route: "campaigns",
                component: <Campaign />,
              }
            )
          }
        }
      }
    }
    setProtectedRoutes(fr);
  }, [])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.views) {
        return getRoutes(route.views);
      }

      if (route.route) {
        return (
          <Route
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <>
      {showTermsAndCondition &&
        <AppModal
          rootStyle={
            {
              "&.MuiModal-root": {
                width: '100%',
              }
            }
          }
          customStyle={{
            width: "100%",
            height: "100%",
          }}
          closeOnClick={false}
          showCloseButton={false}
          onModalClose={() => { }}
        >
          <AcceptTermsAndConditions onAccepted={() => setShowTermsAndCondition(false)} />
        </AppModal>
      }
      {currUser.isLoggedIn ? <AuthenticatedPath /> : <></>}
      {pathname !== '/admin/chats' && <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName=""
        routes={routes.concat(protectedRoutes)}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />}
      <Routes>
        {getRoutes(routes)}
        {getRoutes(protectedRoutes)}
      </Routes>

    </>
  )
}
export default memo(Admin);